@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.dateRangeInput {
	@include small-regular($grey500);
	width: 100%;
	margin-top: 5px;
}

.fullLengthContainerDiv {
	width: 100% !important;
}

.dateInputValue {
	font-size: 12px;
	color: black;
}

.largeDatePicker {
	width: 586px;
}

.emptyDateInput {
	font-size: 13.5px;
}
