.avatar {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.actionButtons {
	gap: 16px;
	display: flex;
	margin-right: 16px;

	.right {
		margin-right: unset;
		margin-left: 16px;
	}
}
