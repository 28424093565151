@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.locationHeaderContainer {
	background-color: $white;
	width: 100%;
	padding-bottom: 16px;
	margin-bottom: 16px;
	border-bottom: 1px solid $grey100;

	.locationIndexText {
		margin-bottom: 16px !important;
	}

	.titleText {
		@include body2();
		color: $primaryColor;
		font-size: 22px;
		font-weight: 600;
		margin-bottom: 6px;
	}

	.grayText {
		@include small($grey500);
	}

	.locationInfo {
		display: flex;
		justify-content: space-between;

		.iconAndTitle {
			display: flex;
			flex-direction: row;
			gap: 16px;
			align-items: center;

			.icon {
				width: 64px;
				height: 64px;
				border-radius: 8px;
				background-color: $white;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 32px;
					height: 32px;
				}
			}
		}
	}

	.totalMinText {
		text-align: end;
	}
}
