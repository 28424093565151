@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.reportTagRouteSection {
	display: flex;
	flex-direction: column;
	width: $investigationReportTagRouteSectionWidth;
	background-color: $white;
	padding: 24px 16px 0 24px;
	border-radius: 8px;

	.nameTitle {
		@include subtitle-semi();
	}
}
