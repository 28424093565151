@import '@shared/styles/variables.scss';

.rowHeader {
	border: 1px solid $grey200;
	border-top: none;

	background-color: $white;
	position: sticky;
	inset-inline-start: 0;

	z-index: 20000;
	padding: 0;
}
.cell {
	border: 1px solid $grey200;
	border-top: none;
	border-inline-start: none;
	padding: 0;
}

.rowHovered {
	background-color: $grey50;
}
