@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.graphTitle {
	padding-bottom: 16px;
	@include subtitle-semi();
}

.vs {
	font-weight: 400;
}

.datePickerWrapper {
	position: absolute;
	top: -75px;
	left: 0;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding: 8px 12px;
}

.stats {
	gap: 40px;
	display: flex;
	width: 100%;
	padding: 8px 12px;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
}

.graphContainer {
	width: 100%;
}

.TagsAnalysisVsHours_container {
	height: 100%;
	display: flex;
	flex-direction: column;
}
