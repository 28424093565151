@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.InvestigationReportTagInfoCard_container {
	margin-top: 17px;
	background: $white;
	border-radius: 8px;
	padding: 16px 24px;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.topPart {
	display: flex;
	gap: 16px;

	.tagName {
		@include h6;
	}
}

.bottomPart {
	display: flex;
	align-items: center;
	margin-left: -3px;
	gap: 9px;

	@include body2($grey600);

	.tagIdSection {
		display: flex;
		align-items: center;
		font-weight: 600;
	}

	.tagIcon {
		filter: invert(44%) sepia(4%) saturate(1718%) hue-rotate(199deg) brightness(89%) contrast(83%);
	}
}

.safetySection {
	display: flex;
	align-items: center;
	gap: 16px;
	flex-wrap: wrap;
}

.differentTagNameWarningContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 5px;
	border-radius: 4px;
	background-color: rgba(249, 229, 234, 1);
	width: fit-content;
	padding: 8px 9px;

	img {
		width: 16px;
	}

	.warningText {
		display: flex;
		align-items: center;
		gap: 6px;
		@include small-regular;
	}

	.boldSection {
		@include small();
	}
}
