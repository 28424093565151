@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.projectEfficiencyContainer {
	border-radius: 8px;
	margin: 5px;
	padding: 16px;
	min-height: 250px;
	&.dark {
		background-color: #384758;
	}

	.breakLine {
		height: 1px;
		width: 100%;
		background-color: #878f99;
		margin: 10px 0;
	}
}
