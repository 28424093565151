@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.rowContainer {
	display: flex;
	justify-content: space-between;
	background-color: $white;
	border-top: 0.1px solid $grey100;
	height: 58px;
	padding: 12px 0px 12px 0;
	padding-inline-start: 24px;

	&:hover {
		background-color: $blue100;
	}

	.rowWrapper {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.tableText {
		@include small-regular();
		@include for-narrow-layout {
			font-size: 11px;
		}
	}

	.tableSubText {
		color: darkgray;
	}

	.cellContainer {
		display: flex;
		flex-direction: column;
		gap: 2px;
		width: 100%;
	}

	.cellCenter {
		align-items: center;
	}

	.headerWrapper {
		min-height: 20px;
		display: flex;
	}

	.smsIcon {
		padding-inline-start: 12px;
	}

	.headerTextWrapper {
		display: flex;
		align-items: center;
	}
}
