@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.createButton {
	cursor: pointer;
	border-radius: 44px;
	background-color: $darkBlue;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: $grey700 !important;
	}
}
