@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/_variables.scss';

.workersListSection {
	background: $white;
	border-radius: 4px;
	min-width: 270px;
	max-width: 330px;
	height: fit-content;
	max-height: calc(100vh - 190px);
	padding: 24px 16px;
	overflow: auto;

	@include for-narrow-layout {
		max-width: 270px;
	}
}

.sectionTitle {
	@include subtitle-semi();
	margin-bottom: 16px;
}

.tradeTitle {
	@include subtitle-semi();
}
