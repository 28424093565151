.container {
	display: flex;
	height: 100%;
	align-items: flex-end;
	flex: 3;

	.histogramContainer {
		margin-bottom: 18px;
		height: 70%;
	}
}
