@import '../../../styles/variables';
@import '../../../styles/mixins/text';

.baseButton {
	@include button-small();
	color: $white;
	border-radius: 3px;
	padding: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: unset;
	cursor: pointer;
	height: 40px;
}

.baseIcon {
	margin-inline-end: 3px;
}
