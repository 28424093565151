@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.floorPlanContainer {
	border-top: 1px solid $grey200;
	padding: 8px 0px 20px 0px;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: scroll;
	cursor: pointer;
}

.floorNickText {
	@include body2();
}

.floorItem {
	@include body2();
	padding: 16px 12px;
	border-bottom: 1px solid $grey200;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: $grey50;
	}
}

.selectedFloorMark {
	@include body2();
	display: flex;
	align-items: center;
	gap: 4px;
}
