@import '@shared/styles/mixins/text';
@import '@shared/styles/_variables.scss';

.tooltipIcon {
	width: 14px;
	height: 14px;
}

.tooltipRowText {
	padding: 4px;
	@include small-regular($white);
	width: 95%;
}

.tooltipRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 4px;
}
