@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/variables';
@import '@shared/styles/mixins/media_queries';

.safetyEventContainer {
	height: 25px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 8px;
	padding-right: 8px;
	padding-left: 8px;
	cursor: pointer;

	.eventText {
		@include small-regular;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 8px;
	}

	.eventProps {
		@include small-regular;
		margin-right: 2px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: capitalize;
		text-align: center;
		width: 50%;
	}

	.iconClass {
		width: 12px;
		height: 12px;
		margin-right: 8px;
	}

	.iconTextContainer {
		@include small-regular;
		width: 33%;
		display: flex;
		align-items: center;
		@include for-narrow-layout {
			width: unset;
		}
	}

	.chipContainer {
		display: flex;
		justify-content: flex-end;
		width: 33%;
		font-size: 12.5px !important;

		@include for-narrow-layout {
			display: none;
		}
	}
}

.tooltipRoot {
	background-color: $white !important;
	border-radius: 6px !important;
	max-width: 400px !important;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4) !important;
}

.arrow::before {
	background: $white !important;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4) !important;
}

.eventTextTooltip {
	@include small-regular;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.eventPropsContainer {
	display: flex;
	width: 30%;
	align-items: center;
}
