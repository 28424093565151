@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.containerDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 75%;
}

.floatingHeaderContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	top: 8px;
	margin: auto;
}

.datePickerWrapper {
	width: 100%;
}

.datePicker {
	font-size: 0.7rem;
}

.datePopper {
	z-index: 100;
}

.calendarDay {
	width: 1.3rem;
	line-height: 1.3rem;
}

.weekDay {
	width: 1.3rem;
}

.calenderWeekHover {
	&:hover {
		background-color: $white;
	}
	background-color: $black;
}

.item {
	@include small-regular();
	cursor: pointer;
	padding: 0 16px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;

	&:hover {
		background-color: $grey50;
	}

	.label {
		font-weight: 500;
		color: $darkBlue;
	}

	.value {
		font-weight: 400;
		color: $grey500;
	}
}

.calendarContainer {
	width: 100%;
	border-radius: 4px;
}

.calendar {
	display: flex;
	position: relative;
}
