@import '@shared/styles/mixins/text';

.timeSelectorContainer {
	:global(.react-datepicker__input-time-container) {
		display: flex;
		width: 90%;
	}

	:global(.react-datepicker-time__caption) {
		display: none;
	}

	:global(.react-datepicker__day--outside-month) {
		@include small($grey300 !important);
		margin: 0px !important;
		width: 40px !important;
		height: 40px !important;
		line-height: 2.4rem !important;
		visibility: visible;
	}
}

.datePickerCustom {
	width: 100%;
}

.calendar {
	z-index: 1000;
	border-radius: 4px;
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25) !important;
}

.datePickerIcon {
	:global(.react-datepicker__navigation--previous) {
		right: 110px !important;
	}

	:global(.react-datepicker__navigation--next) {
		right: 100px !important;
	}
}

.timeDropdown {
	max-height: 150px;
}
