@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.InvestigationReportEmptyPage_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 24px;
	border-radius: 8px;
	height: 514px;
	width: 100%;
	background: $white;

	&.clearStyle {
		margin-top: unset;
		border-radius: unset;
		height: auto;
		margin-inline-start: 3px;
		max-height: 700px;
	}
}

.innerContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
}

.warningClass {
	display: flex;
	background: rgba(240, 240, 240, 1);
	padding: 4px;
	border-radius: 4px;

	img {
		width: 60px;
	}
}

.bottomContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
}

.noData {
	@include h6;
}

.noDataDescription {
	@include body2(rgba(103, 104, 121, 1));
}
