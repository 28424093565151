@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/_text.scss';
@import '../../../styles/mixins/media_queries';

.criticalSection {
	display: flex;
	align-items: center;
	@include small;
	gap: 5px;
	height: 16px;

	@include for-wide-layout {
		cursor: pointer;
	}

	.label {
		@include small($darkBlue);
		display: flex;
		flex-direction: row;
	}
}

.filledIcon {
	:first-child {
		fill: inherit;
	}
}
