@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

$activitiesSingleActivityColumnWidth: 580px;
$activitiesWorkersAndTimeColumnWidth: 200px;
$activitiesManagerColumnWidth: 225px;

.rowContainer {
	display: flex;
	height: 57px;

	.activityInfoCell {
		display: flex;
		justify-content: center;
		align-items: center;
		border-inline-end: $tableBorderRight;
		width: $activitiesSingleActivityColumnWidth;

		.datesContainer {
			@include small-regular();
			padding: 0 20px;
			border-inline-end: $tableBorderRight;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 180px;
			border-bottom: $tableBorderRight;
			white-space: nowrap;
		}

		.activityTextContainer {
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: flex-start;
			padding-inline-start: 20px;
			flex: 1;
			border-bottom: $tableBorderRight;
			text-transform: capitalize;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.activityHoursContainer {
			@include small-regular();
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: center;
			width: 80px;
			border-bottom: $tableBorderRight;
			border-left: $tableBorderRight;
		}
	}

	.timeCell {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: $activitiesWorkersAndTimeColumnWidth;
		max-width: $activitiesWorkersAndTimeColumnWidth;
		border-inline-end: $tableBorderRight;
		border-bottom: $tableBorderRight;
	}

	.workersCell {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: $activitiesWorkersAndTimeColumnWidth;
		max-width: $activitiesWorkersAndTimeColumnWidth;
		border-inline-end: $tableBorderRight;
		border-bottom: $tableBorderRight;
	}

	.managerCell {
		min-width: $activitiesManagerColumnWidth;
		max-width: $activitiesManagerColumnWidth;
		border-bottom: $tableBorderRight;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-inline-start: 20px;
	}
}
