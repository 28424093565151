@import '../../styles/mixins/text';
@import '../../styles/variables';

.wrapper {
	@include small-regular;
	border-radius: 8px;
	border: 1px solid $grey200;
}

.wr {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-bottom: 1px solid $grey200;
}

.title {
	align-items: center;
	display: flex;
	gap: 4px;
}

.tagName {
	font-weight: 500;
}

.tagsSection {
	padding: 8px 16px;
	display: flex;
	flex-direction: column;
}

.tag {
	padding: 8px 0px;
	border-bottom: 1px solid $grey100;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.hideBorder {
	border-bottom: none;
}
