@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.ActivitiesProgress_container {
	width: 100%;
	padding-inline-start: 32px;
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 0;

		.title {
			@include h6();
		}
	}
}
