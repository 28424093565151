@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.settingsPickerContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 8px;

	.displayProfessionText {
		margin: 0;
		@include small($grey500);
	}

	.displayCheckboxSection {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.settingSection {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 12px;
		border-bottom: 1px solid $grey100;
	}

	.noBorder {
		border-bottom: none;
		padding: 6px;
	}
}
