.carouselContainer {
	display: flex;
	align-items: center;
	max-width: 100%;
	width: fit-content;
	position: relative;
	margin-top: 16px;
	padding: 0px 14px;

	.viewport {
		overflow: hidden;
		max-width: 100%;
		width: fit-content;
		margin: 0 8px;

		.itemsContainer {
			display: flex;
			transition: transform 0.5s ease;
			will-change: transform;
			.item {
				flex: 1 0 auto;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				width: fit-content;

				> * {
					max-width: 100%;
				}
			}
		}
	}
}
