@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.floorHighlightedArea {
	position: absolute;
	z-index: 1;
}

.highlightedAreaHovered {
	background-color: rgba($blue100, 0.5);
	cursor: pointer;
}

.floorHighlightedAreaChoosen {
	background-color: rgba(224, 237, 255, 0.8);
}

.highlightedChoosenAreaHovered {
	background-color: rgba(224, 237, 255, 0.8);
}
