@import '@shared/styles/mixins/text';

.container {
	display: flex;
	flex-direction: row;
	align-items: center;

	.text {
		@include body2();
		font-size: 12px;
		font-weight: 300;
		line-height: unset;
	}

	.disabledText {
		@include body2();
		font-size: 12px;
		font-weight: 300;
		line-height: unset;
		color: $disabledGray;
	}

	.lightText {
		@include body2();
		font-weight: 400;

		&.disabledText {
			color: $disabledGray;
		}
	}
}

.alignCheckboxToTop {
	span {
		padding-top: 0;
		padding-inline-end: 3px;
		bottom: 3px;
	}
}
