@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.chipTooltipContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 80vh;
	overflow-y: auto;
	overflow-x: hidden;

	.professionTooltipContainer {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		.topProfessionDetails {
			display: flex;
			align-items: center;
			width: 100%;
			gap: 4px;
			padding: 8px;
			border-bottom: 1px solid $grey300;
			&.topBorder {
				border-top: 1px solid $grey300;
			}
		}

		.professionActivitiesChipTooltipContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 350px;

			.activityDataContainer {
				padding: 8px;
				padding-inline-start: 24px;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				flex-direction: column;
				border-bottom: 1px solid $grey500;
				width: 100%;

				.activityDescription {
					@include small-regular($white);
					text-transform: capitalize;
					word-break: break-word;
					margin: 0;
				}

				.activityInfo {
					display: flex;
					align-items: center;
					flex-direction: row;
					gap: 0 16px;

					.iconText {
						display: flex;
						align-items: center;
						gap: 4px;
						@include small-regular($grey300);
					}
				}
			}
		}
	}
}

.detailsText {
	@include small($white);
}

.noBottomBorder {
	border-bottom: none !important;
}
