@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/mixins/_text.scss';

.emptyState {
	display: flex;
	height: 50%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.emptyStateIconContainer {
		padding: 8px;
		background-color: $grey50;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		.notificationIcon {
			position: relative;
			width: 48px;
			height: 48px;

			.emptyStateIcon {
				width: 48px;
				height: 48px;
				filter: brightness(0) saturate(100%) invert(1%) sepia(1%) saturate(190%) hue-rotate(314deg)
					brightness(98%) contrast(86%);
			}

			.dot {
				width: 16px;
				height: 16px;
				position: absolute;
				top: -6px;
				inset-inline-end: -6px;
				background-color: $yellow;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				.dotText {
					@include text;
					color: $white;
					font-size: 8px;
					font-weight: 600;
					margin-block-start: 2px;
					letter-spacing: unset;
				}
			}
		}
	}

	.emptyStateText {
		@include subtitle-reg;
		margin-top: 8px;
	}
}
