@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.reportPageContainer {
	width: 100%;
	overflow-y: auto;
}

.reportDataContainer {
	width: 100%;
	max-width: 1370px;
	margin: auto;
	padding: 0 10px;
	@include for-narrow-layout {
		max-width: 1224px;
	}

	.reportDescriptionContainer {
		display: flex;
	}
}
