@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.headerContainer {
	margin-top: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 8px;
}

.count {
	display: flex;
	gap: 4px;
	align-items: center;
	width: 90%;
	overflow: scroll;
}

.headerText {
	@include small();
}

.createButton {
	cursor: pointer;
	border-radius: 44px;
	background-color: $darkBlue;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
