@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '../constants.scss';

.progressTrackerTopSection {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $grey200;
	align-items: center;
	padding: 16px;
	padding-inline-start: 24px;
	background: $white;
}

.starredSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	width: 100%;
	direction: initial;
	border-bottom: 1px solid rgba(16, 48, 69, 0.2);

	.starredToggleSection {
		&.rtl {
			direction: rtl;
		}
	}

	.starredText {
		margin: 0 7px;
		white-space: nowrap;
		@include button-small();
	}
	div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.progressTrackerContainer {
	display: flex;
	outline: none;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding-bottom: 22px;
}

.fullPage {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: $white;
}

.absoluteCenter {
	position: absolute;
	left: 48%;
}

.endHeaderSection {
	display: flex;
	align-items: center;
	gap: 8px;
}

.selectedTextSection {
	display: flex;
	padding: 0px 8px 0 16px;
	align-items: center;
	gap: 8px;
	color: $blue300;
	border-inline-end: 1px solid $grey200;
	height: 100%;
	width: max-content;
}

.selectedText {
	width: max-content;
}

.exceededThreshold {
	color: $red400;
}

.statusSelect {
	display: flex;
	align-items: center;
	color: $grey500;
	padding: 8px 4px 8px 8px;
	width: 40%;
	justify-content: space-between;
	gap: 4px;
	cursor: pointer;

	&.disabled {
		pointer-events: none;
		cursor: none;
	}
}

.multiSelectHeaderContainer {
	width: fit-content;
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: 1px solid $grey200;
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
	height: 40px;
	@include body2();
}

.toggleSectionContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(100% - 400px);
}

.progressTrackerBody {
	background: $blue100;
	display: flex;
	overflow: hidden;
	//flex-grow: 1;
	padding-bottom: 16px;
}

.closeIconContainer {
	cursor: pointer;
}

.sequencesSelection {
	display: flex;
	align-items: center;
	gap: 8px;
}

.endAdornment {
	top: 8px !important;
}

.statusUpdateMenu {
	position: absolute;
	top: 123px;
	z-index: 50000;
}
