@import '../../styles/variables';
@import '../../styles/mixins/text';

.container {
	border-radius: 4px;
	@include small-regular();
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 4px;
	padding-inline-end: 10px;
	gap: 2px;
	background: $grey100;
	position: relative;

	&.hoverState {
		&:hover {
			background: $grey200;
		}
	}
}

.dropdownItems {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 35px;
	background-color: $white;
	border: 1px solid $grey200;
	border-radius: 4px;
	z-index: 100000;
	overflow-y: auto;
	max-height: 200px;
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}

.item {
	width: 177px;
	display: flex;
	align-items: center;
	padding: 8px 16px;
	cursor: pointer;
	&:hover {
		background-color: $grey50;
	}
	&.selected {
		background: $blue100;
	}
}

.text {
	@include small-regular($grey600);

	&.selected {
		font-weight: 500;
		color: $primaryColor;
	}
}

.dropdownDescription {
	&:hover {
		background-color: $white !important;
	}
}

.currentSequenceTypeText {
	margin: 0;
	width: max-content;
}
