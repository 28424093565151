@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.createButton {
	background-color: $grey50;
	color: $primaryColor;
	margin-right: 16px;
	display: flex;
	gap: 8px;
}

.cardContainer {
	display: flex;
	flex-direction: column;
	padding: 16px 16px 0 16px;
	border: 1px solid $grey200;
	border-radius: 8px;
	margin-bottom: 16px;
	width: 100%;
	background-color: $white;

	.cardHeader {
		display: flex;
		.cardIcon {
			margin-inline-end: 8px;
		}
		.titleText {
			@include subtitle-semi();
			text-transform: capitalize;
			line-height: 25px;
		}
	}
}

.textSection {
	@include body2();

	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	margin: 30px 0;
	width: 40%;

	.description {
		color: $grey600;
		font-weight: 400;
	}
}

.actionsSection {
	display: flex;
	white-space: nowrap;
	flex: 1;
}

.cardBody {
	@include body2();

	.singleCardSection {
		display: flex;
		align-items: center;

		justify-content: space-between;

		.textSection {
			font-size: 12px;
			line-height: 18px;
			margin: 30px 0;
			width: 40%;
			margin-inline-end: 10%;

			.title {
				font-weight: 500;
			}

			.description {
				color: $grey600;
				font-weight: 400;
			}
		}

		.dropdown {
			width: 170px;
			margin: 0 5%;
			@include for-narrow-layout {
				margin: 0 2%;
				width: 130px;
			}
		}
		.freq {
			width: 15% !important;
			& > div:first-child {
				width: 100% !important;
			}
			& :global(.MuiOutlinedInput-input) {
				padding: 8px 0 !important;
				background-color: transparent !important;
			}
		}

		label {
			font-size: 13px !important;
		}

		.actions {
			display: flex;
			margin-top: 5px;
			flex: 1;
			justify-content: flex-end;

			.switchContainer {
				display: flex;
				align-items: center;
				margin-inline-end: 16px;

				.switchLabel {
					@include small-regular;
				}
			}
		}
	}
}

.restrictedAreaSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.divider {
	width: 100%;
	height: 1px;
	background-color: $grey200;
	margin-top: 16px;
}

.recipientsLength {
	white-space: nowrap;
}

.endAdornment {
	top: 5px !important;
}

.notificationDialogContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.mainText {
		@include subtitle-reg();
		margin: 3px 0;
	}

	.text {
		@include body2($grey600);
		margin: 3px 0;
	}
}

:global(.MuiAutocomplete-popper) {
	min-width: max-content !important;
}
