@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.overviewTableCell {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.stripes {
	background-image: url('../../../../../../assets/workplanIcons/disabledMatrixCellBackgroud.svg');
	height: 100%;
}
