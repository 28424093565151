@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.container {
	position: relative;
	cursor: pointer;
	display: flex;
	height: 40px;
	padding-inline-end: 8px;
	justify-content: space-between;
	align-items: center;
	border-radius: 4px;
	border: 1px solid $grey300;

	&:hover {
		border: 1px solid $grey500;
	}
}

.dropdown {
	border-radius: 4px;
	display: flex;
	width: 270px;
	flex-direction: column;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
	position: absolute;
	top: 40px;
	z-index: 1111000;
	background: $white;
}

.dropdownItem {
	cursor: pointer;
	@include small-regular($darkBlue);
	display: flex;
	height: 40px;
	padding: 8px;
	gap: 16px;
	align-self: stretch;
	align-items: center;

	&.selected {
		background-color: $blue100;
	}
	&:hover {
		background-color: $grey50;
	}
}

.input {
	min-width: 40px;
	max-width: 100px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.newButton {
	@include button-small($grey400);
	display: flex;
	height: 40px;
	padding: 8px 8px;
	align-items: center;
	gap: 8px;

	&:hover {
		background-color: $grey50;
	}
}
