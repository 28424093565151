@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

$FLOOR_CHIP_HEIGHT: 20px;
$MANAGER_CHIP_MIN_WIDTH: 22px;

.root {
	min-width: $MANAGER_CHIP_MIN_WIDTH !important;
	margin: 2px;
	border: solid 1px #313131 !important;
	border-radius: 13px !important;
	white-space: nowrap !important;
	overflow: hidden;
	background-color: #ffffff !important;
}

.floorChipHeight {
	height: $FLOOR_CHIP_HEIGHT !important;
}

.label {
	font-family: Heebo, sans-serif;
	line-height: 1.28;
	color: $darkBlue;
	font-size: 11px;
	font-weight: 500;
	padding-inline-end: 1px !important;
	padding-inline-start: 1px !important;
}

.text {
	height: 18px;
	line-height: 18px;
	text-align: center;
	margin-left: 4px;
	margin-right: 6px;
	text-overflow: ellipsis;
	overflow: hidden;
	color: $darkBlue;
}

.labelContainer {
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
}

.initialContainer {
	font-size: 11px;
}
