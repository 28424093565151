@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerText {
	@include body1;
	text-align: left;
	text-transform: capitalize;
	@include for-narrow-layout {
		@include body2;
	}
}

.boldTitle {
	@include subtitle-reg;
}
