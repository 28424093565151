@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins/text';

.groupTitle {
	@include caption($darkBlue);
	border-top: 1px solid $grey200;
	border-bottom: 1px solid $grey200;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px 12px;
}

.titleContainer {
	display: flex;
	align-items: center;
	overflow: hidden;
}

.groupTitleWithCheckbox {
	justify-content: flex-start;
	padding: 8px 12px;
}

.highlighted {
	background: $grey50 !important;
}

.withSecondaryText {
	justify-content: space-between;
}

.noBorders {
	border: none !important;
}

.groupDisplayOption {
	overflow: hidden;
}

.detailsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
	overflow: hidden;
}
