@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import './constants.scss';

$SIDE_MAIN_COMPONENT_WIDTH: 240px;

.sideTopContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding: 20px;
	border-bottom: $regularBorder;

	.titleSection {
		max-height: max-content;

		.titleText {
			@include subtitle-semi();
		}
	}

	.summaryButton {
		background-color: $white;
		color: #6a6766;
	}
}

.absoluteCenterLtr {
	position: absolute;
	left: 48%;

	@media (max-width: 1300px) {
		position: static;
		margin: auto;
	}
}

.absoluteCenterRtl {
	position: absolute;
	right: 48%;

	@media (max-width: 1300px) {
		position: static;
		margin: auto;
	}
}

.container {
	width: 100%;
	display: flex;
}

.sequenceAnalysisButton {
	@include button-small();
	display: flex;
	gap: 10px;
	height: 32px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	border: 1px solid $blue200;
	background: $blue200;
	cursor: pointer;

	& .image {
		width: 30px;
	}
}

.bottomContainer {
	background-color: $blue100;
	flex-grow: 1;
	overflow: hidden;
	width: 100%;
	display: flex;
}

.tableContainer {
	width: calc(100% - #{$SIDE_MAIN_COMPONENT_WIDTH});
	flex: 1;
	background-color: $white;
	display: flex;
	flex-direction: column;

	.workplanHeader {
		display: flex;
		flex-direction: column;

		.upperSide {
			border-bottom: 1px solid $grey200;
			width: 100%;
			display: flex;
			height: $workplanHeaderUpperSideHeight;
			justify-content: space-between;

			.workplanMenu {
				padding-inline-start: 24px;
				display: flex;
				justify-content: center;
				align-items: center;

				.workplanText {
					@include h6();
					line-height: 29px;
					margin: 0 12px;
				}

				.menuItem {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 8px;
					padding: 8px;
					padding-inline-start: 0;
					background-color: transparent;
					transition: all 0.1s ease-in-out;
					border-bottom: 1px solid transparent;

					&.selected {
						border-bottom: 2px solid $primaryColor;
					}

					&:hover {
						cursor: pointer;
						background-color: $grey50;
						border-radius: 8px;
						border-bottom: 1px solid transparent;
					}

					img {
						margin: 0 9px;
						&.unSelectedIcon {
							filter: invert(60%) sepia(10%) saturate(335%) hue-rotate(174deg) brightness(92%)
								contrast(92%);
						}
					}

					.menuItemText {
						@include body2(#878f99);
						line-height: 21px;

						&.selected {
							color: $primaryColor;
							font-weight: 600;
						}
					}
				}
			}

			.wokrplanActions {
				display: flex;
				align-items: flex-end;
				justify-content: center;

				& > * {
					margin-inline-end: 18px;
					margin-bottom: 8px;
				}

				.createActivityIcon {
					filter: invert(0%) sepia(95%) saturate(0%) hue-rotate(258deg) brightness(97%) contrast(98%)
						invert(15%) sepia(77%) saturate(338%) hue-rotate(173deg) brightness(88%) contrast(86%);
				}

				.planningCounter {
					@include subtitle-reg($white);
					cursor: pointer;
					border-radius: 50%;
					margin-bottom: 8px;
					background-color: $blue300;
					line-height: unset;
					height: 40px;
					width: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.bottomSide {
			display: flex;
			padding: 16px 0;
			justify-content: space-between;
			padding-inline-end: 20px;
			padding-inline-start: 24px;
			border-bottom: 1px solid $grey200;

			.bottomSideLeft {
				align-items: center;
				height: 40px;
				gap: 8px;
				display: flex;
				align-self: flex-start;
			}

			.today {
				@include body2();
				line-height: 21px;
				border: 1px solid #cdcdcd;
				border-radius: 4px;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 4px 8px;
				cursor: pointer;
				margin-inline-end: 8px;
				width: -webkit-fill-available;
			}
		}
	}
}

.oneTwoWeekContainer {
	height: 100%;
	display: flex;
	border-radius: 4px;

	&:last-child {
		border-right: none;
	}
}

.weekText {
	@include body2($grey500);
	line-height: 21px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 16px;
	white-space: nowrap;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid $grey200;
	flex: 1;
	user-select: none;

	&.first {
		border-right: 0;
		border-radius: 4px 0 0 4px;
		&.selected {
			border: 1px solid $darkBlue !important;
			color: $darkBlue;
			border-bottom-right-radius: unset;
			border-top-right-radius: unset;
		}
	}
	&.second {
		border-radius: 0;
		border-right: 0;
		&.selected {
			border: 1px solid $darkBlue !important;
			color: $darkBlue;
			border-radius: unset;
		}
	}
	&.third {
		border-radius: 0 4px 4px 0;
		&.selected {
			border: 1px solid $darkBlue !important;
			color: $darkBlue;
			border-bottom-left-radius: unset;
			border-top-left-radius: unset;
		}
	}

	&.isRTL {
		&.first {
			border-left: 0;
			border-right: 1px solid $grey200;
			border-radius: 0 4px 4px 0;
			&.selected {
				border-bottom-left-radius: unset;
				border-top-left-radius: unset;
			}
		}
		&.second {
			border-left: 0;
			border-right: 1px solid $grey200;
		}
		&.third {
			border-radius: 4px 0 0 4px;

			&.selected {
				border-bottom-right-radius: unset;
				border-top-right-radius: unset;
			}
		}
	}

	&:hover {
		background-color: $grey50;
	}
}

.noContentSection {
	height: 100%;
	text-align: center;
	margin: 200px auto;

	.noContentMessage {
		@include subtitle-reg();
	}

	.icon {
		margin: 20px auto auto;
	}
}

.alertDialogText {
	@include small-regular();
	margin-bottom: 10px;
	margin-top: 25px;
}

.alertDialogTextLink {
	text-decoration: underline;
	font-weight: 400;
	cursor: pointer;
}

.exportMenuWrapper {
	position: absolute;
	z-index: 10000000 !important;
}
