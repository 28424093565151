@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.sequenceItemContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.descriptionContainer {
	display: flex;
	flex-direction: column;

	.inputContainer {
		border: none;
		padding: 0;
	}

	.titleInputText {
		@include subtitle-semi();
		height: 20px;
		color: $darkBlue;
	}

	.descriptionInputText {
		@include body2();
		color: $grey400;
		height: 20px;
	}

	.title {
		@include subtitle-semi();
		height: 24px;
	}

	.description {
		@include body2();
		color: $grey400;
		height: 24px;
	}
}

.areasContainer {
	padding-top: 8px;
	padding-bottom: 8px;
	display: flex;
	gap: 16px;
}

.radioButtonContainer {
	@include small();
	display: flex;
	gap: 8px;
}

.errorText {
	@include small($feedbackCriticalDark);
}
