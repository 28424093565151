@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/variables';

.InvestigationReportSafetyEventCard_container {
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 5px;
	border-radius: 4px;
	background-color: rgba(249, 229, 234, 1);
	width: fit-content;
	padding: 8px 9px;

	img {
		width: 16px;
	}

	.safetyEventText {
		display: flex;
		align-items: center;
		gap: 6px;
		@include small-regular;
	}

	.boldSection {
		@include small();
	}
}
