@import './_variables.scss';
@import 'typography';
@import './mixins/_text.scss';

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-bottom: env(safe-area-inset-bottom);
	min-height: -webkit-fill-available;

	@include for-hebrew-text();

	@include for-english-text();

	@include for-jpn-text();

	& ::-webkit-scrollbar {
		width: 12px;
		height: 12px;
	}

	& ::-webkit-scrollbar-thumb {
		height: 100px;
		border: 4px solid rgba(0, 0, 0, 0);
		background-clip: padding-box;
		background-color: $grey200;
		-webkit-border-radius: 7px;
		border-radius: 8px;
	}

	& ::-webkit-scrollbar-track {
		border-radius: 7px;
	}

	& ::-webkit-scrollbar-button {
		display: none;
		width: 0;
		height: 0;
	}

	& ::-webkit-scrollbar-corner {
		background-color: transparent;
	}
}

body * {
	.projectProgressGraphYAxisLabel {
		@include small;
		direction: ltr;
		& :after {
			content: '\200F';
		}
	}
	.apexcharts-legend-text {
		color: #103045 !important;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
