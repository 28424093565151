@import '@shared/styles/mixins/text';

.periodContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 8px;
	margin-top: 8px;

	.textSection {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		& > * {
			margin-inline-end: 8px;
		}
	}
}

.calendarIcon {
	width: 32px;
	height: 32px;
}

.title {
	@include h6();
	line-height: 24px;
}

.trusstorTextInput {
	width: 170px;
}

.inputContainer {
	cursor: pointer;
	position: relative;
	align-self: flex-end;
}

.dateContainer {
	top: -35px;
	position: relative;
	right: 120px;

	&.rtl {
		right: unset;
		left: 120px;
	}
}
