@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/variables';

.areaNickTooltip {
	padding: 8px !important;
	@include small-regular($white);
	max-width: 400px;
}

.closeButton {
	margin-inline-start: 20px;
}

.expandIcon {
	z-index: 1;
	--position: 8px;
	position: absolute;
	top: var(--position);
	right: var(--position);

	&.isRtl {
		right: initial;
		left: var(--position);
	}
}

.mixedEntitiesTooltip {
	background: $darkBlue;
	border-radius: 4px !important;
	padding: 0 !important;
	border: unset !important;
	min-width: 300px;
	max-width: 400px;
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}

.wrapper {
	z-index: 100;
	height: 100%;
	background-color: $white;
	padding: 0 !important;

	.boxShadow {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	}

	.headlineContainer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		min-height: 75px;
		max-height: 75px;

		.headline {
			@include subtitle-reg;
			text-transform: capitalize;
		}
	}

	.contentContainer {
		overflow: auto;
		position: relative;
		border-top: 1px solid $grey100;
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.professionsContainer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		align-content: flex-start;
		margin: 8px 16px;
		gap: 8px;
	}

	.managersContainer {
		display: flex;
		flex-wrap: wrap;
		margin: 8px 16px;
		align-items: center;
		align-content: flex-start;
		gap: 8px;
	}

	.activitiesContainer {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		margin: 0 16px;

		.descriptionText {
			@include small;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.svgParentContainer {
		min-height: 341px;
		max-height: 341px;
		width: 341px;
		margin: 0 auto;
		padding: 16px;
		display: block;
		position: relative;
	}
}

.loader {
	position: absolute;
	top: 0;
	margin-top: auto;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100px;
}

.svg {
	display: flex;
	justify-content: center;
}

.floorContainer {
	padding: 16px;
	width: 100%;
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.iconContainer {
		width: 40px;
		height: 40px;
		background: $grey100;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			background: $white;
			width: 24px;
			height: 24px;
		}
	}

	.floorInfoContainer {
		display: flex;
		align-items: center;
		gap: 16px;

		.floorInfo {
			max-width: 85%;
			display: flex;
			flex-direction: column;
			gap: 2px;

			.floorInfoTitle {
				@include h6;
				text-transform: capitalize;
			}

			.floorInfoSubtitle {
				@include cut-line(3);
				@include small-regular($grey500);
			}
		}
	}
}

.statsSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	height: 100%;
}

.statTextHeader {
	@include small-regular($grey500);
	width: 100%;
	padding: 0;
}

.statText {
	@include subtitle-semi;
	text-align: center;
}

.divider {
	width: 1px;
	border: 1px solid $white;
	height: 100%;
}

.statsInnerSection {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}
