@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.issueLocationSelectorDropdownContainer {
	width: 100%;
	height: 40px;
	padding: 8px;
	position: relative;
	border-radius: 4px;
	border: 1px solid $grey200;
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	@include button-small($grey600);

	&:hover {
		border: 1px solid $grey400;
	}
}

.issueLocationSelectorContainer {
	position: absolute;
	width: 400px;
	background-color: white;
	z-index: 10000000000000;
	border-radius: 8px;
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
	padding: 12px;
	height: 60vh;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
}

.selectedIssuesText {
	@include body2();
	color: #757575 !important;
	all: initial;
}

.popper {
	z-index: 10000000000000;
}

.popperDialog {
	top: -300px !important;
}

.locationsText {
	color: $darkBlue !important;
}
