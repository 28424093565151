@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.forNarrowClass {
	img {
		@include for-narrow-layout {
			width: 14px !important;
			height: 14px !important;
		}
	}

	.checkIcon {
		height: 12px;
		width: 12px;
	}
}
