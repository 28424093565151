@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.pageContainer {
	width: 100%;
}

.bodyContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: 1370px;
	margin: 16px auto;
	gap: 16px;

	@include for-narrow-layout {
		max-width: 1190px;
	}

	.title {
		align-self: center;
		@include h5();
		margin-bottom: 32px;
	}
}
