@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.hoistTopSection {
	.hoistHeader {
		display: flex;
		justify-content: space-between;

		p {
			@include small($white);
			margin: 0;
		}

		.timeSpentContainer {
			.timeSpentTextStyles {
				@include small-regular($white);
			}

			.notAvailableText {
				@include small-regular($white);
				opacity: 0.6;
				color: $white;
			}
		}

		.actualTimeSpentTextStyles {
			text-align: end;
			font-size: 12px;
			font-weight: 100;
			color: $white;
		}
	}

	.hoistOperator {
		display: flex;
		border-bottom: 1px solid #e6e9ef;
		align-items: center;
		padding: 7px 0 7px;

		img {
			margin: 0 2.5px;
		}

		.hoistOperatorText {
			@include small-regular($white);
			line-height: 0;
			margin-top: 3px;
		}
	}
}

.hoistChipsSection {
	display: flex;
	flex-wrap: wrap;
	padding-top: 0.5rem;
	max-width: 100%;

	.noWorkersText {
		@include small($white);
		opacity: 0.7;
		margin: 9px auto 5px;
		font-weight: 400;
	}
}

.tooltipTextStyles {
	@include small-regular($white);
	align-self: start;
	margin-bottom: 3px;
	margin-top: 3px;
	text-transform: capitalize;
}

.divider {
	height: 2px;
	opacity: 0.12;
	background-color: #242424;
}

.avatar {
	border-radius: 50%;
	box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.45);
	background-color: #fefefe;
	position: absolute;
	top: -4px;
	right: 0;
	height: 15px;
	width: 15px;
	z-index: 12;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hoistIconText {
	@include small-regular($white);
	text-align: center;
}

.hoist {
	height: 25px;
	width: 25px;
}

.tooltipSection {
	display: flex;
	flex-direction: column;
	padding: 7px 5px 0 5px;
	min-width: 275px;
	max-width: 370px;
}

.chipsContainer {
	display: flex;
	flex-wrap: wrap;
	margin: 2px 0 0 0;
}
