@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.section {
	width: 100%;
	max-width: 1200px;

	@include for-narrow-layout {
		max-width: 1190px;
	}
}

.title {
	@include h6;
}

.recentReportSectionWrapper {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	max-height: max-content;
	overflow: hidden;

	.divider {
		width: 100%;
		border-bottom: 1px solid $grey100;
	}
}
