@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.TopStats_container {
	margin-top: 16px;
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.StatCard_container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
}

.dataSection {
	display: flex;
	gap: 8px;
	align-items: center;
}

.title {
	@include small-regular;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	background: $blue100;
}

.percentage {
	@include h5;
}

.subtitle {
	@include body1;
}

.progressTotalText {
	@include small($grey500);
}
