@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.dateInputContainer {
	input {
		width: 100%;
	}
}

.datePickerWrapper {
	width: 100%;
}
