@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.label {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

.selectSection {
	height: 38px;
	cursor: pointer;
	border-radius: 4px;
	outline: 1px solid #d5d5d5;
	display: flex;
	align-items: center;
	padding: 8px;

	gap: 4px;

	& .text {
		@include small($grey400);
	}

	&:hover {
		border: 1px solid $primaryColor;
	}
}

.imageInput {
	display: none;
}

.imagesWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 10px;
	padding-inline-start: 5px;

	.imageContainer {
		position: relative;

		img,
		.pdfIcon {
			width: 40px;
			height: 40px;
			border-radius: 4px;
			object-fit: cover;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
		}

		.closeIcon {
			width: 17px;
			height: 17px;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			top: -4px;
			right: -4px;
		}
	}
}
