@import '../../../styles/variables';
@import '../../../styles/mixins/text';

.container {
	position: relative;
	width: 100%;

	&.pointer {
		cursor: pointer;
	}

	.close {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 0;
		inset-inline-end: 0;
		filter: brightness(0) saturate(100%) invert(68%) sepia(12%) saturate(296%) hue-rotate(173deg) brightness(81%)
			contrast(86%);
		cursor: pointer;
	}

	.content {
		display: flex;
		flex-direction: row;
		gap: 20px;

		.image {
			background-color: $grey600;
			min-width: 32px;
			height: 32px;
			border-radius: 50%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			.dot {
				width: 6px;
				height: 6px;
				position: absolute;
				top: -2px;
				inset-inline-start: -2px;
				background-color: $yellow;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.details {
			display: flex;
			flex-direction: column;
			padding-inline-end: 30px;
			gap: 8px;
			overflow: hidden;

			.time {
				@include small-regular($grey400);
				margin-top: 8px;
			}
		}
	}
}
