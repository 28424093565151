@import '../../../styles/mixins/text';
@import '../../../styles/variables';

.groupContainer {
	margin-inline-start: 8px;
}

.optionStyle {
	&:global(.MuiAutocomplete-option) {
		padding-inline-start: 0;

		&:hover {
			background-color: $blue100 !important;
		}
	}
}

.loading {
	&:global(.MuiAutocomplete-loading) {
		@include small-regular(#7b7b7b);
	}
}

.wideContainer {
	&:global(.MuiAutocomplete-popper) {
		width: 288px !important;
		left: 75px !important;
	}
}

.optionContainer {
	padding-inline-start: 8px;
	display: flex;
	align-items: center;
}

.groupUl {
	width: 96%;
	border-bottom: solid 1px #dfdada;
	margin: 10px auto 10px;
	text-align: center;
	line-height: 0.1 !important;

	span {
		@include small();
	}
}

.groupLabel {
	@include small;
	line-height: 0.1 !important;
	border-radius: 3px;
	background: $white;
	padding: 0 10px;
	text-align: center;
	margin-top: 8px;
	margin-bottom: 8px;
}

.root {
	input {
		@include small;
	}

	& .text {
		@include body2($black);
		background-color: $white !important;
		border-bottom: 1px solid $black;
	}
}

.autocompleteContainer {
	width: 100%;
	caret-color: transparent;
	@include small-regular;
}

.errorWrapper {
	height: 15px;
	margin-bottom: 4px;
	margin-top: 4px;
}

.tagsContainer {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	align-self: end;
}

.tagView {
	@include small-regular($primaryColor);
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	width: 250px;
	height: 18px;

	.tagName {
		display: flex;
	}
}
