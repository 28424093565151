@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.searchTagContainer {
	input {
		padding-inline-end: 25px !important;
	}
}

.selectedDate {
	color: $primaryColor;
}

.dateRangeInput {
	@include small-regular($grey500);
	width: 100%;
	margin-top: 5px;
}

.periodSelectorContainer {
	position: relative;
}

.emptyDateInput {
	font-size: 13.5px;
}

.fullLengthContainerDiv {
	width: 100% !important;
}

.dateInputValue {
	font-size: 12px;
	color: black;
}

.datePicker {
	width: 481px;
}

.dropdownEndAdornment {
	top: 5px !important;
	filter: brightness(0) saturate(100%);
}
