.MuiSelect-select:focus {
	background-color: #ffffff !important;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.sharedRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
}

.checkboxDate {
	position: relative;
	bottom: 15px;
	margin-inline-start: 10px;
	height: 20px;
	white-space: nowrap;
}

.professionsDropdownContainer {
	margin-bottom: 10px;
}
