@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/media_queries';

.dateFlagContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 4px;
	background-color: transparent;
	@include small();
	color: $grey600;

	&.darkTheme {
		color: $grey300;
	}

	.overdue {
		padding: 2px 4px;
		background-color: $feedbackCriticalLight;
		color: $grey700;

		&.darkTheme {
			background-color: $grey700;
			color: $white;
		}
	}

	.dateText {
		text-wrap: nowrap;
		text-transform: uppercase;
	}
}

.red {
	background-color: $feedbackCriticalLight;
	padding: 2px 4px;
}
