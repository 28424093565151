@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.topWrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.workerTimesheetHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 126px;
	padding: 0 20px;
	box-sizing: border-box;
}

.workerIdSection {
	display: flex;
	align-items: center;
	gap: 10px;

	img {
		filter: invert(42%) sepia(13%) saturate(432%) hue-rotate(199deg) brightness(95%) contrast(93%);
	}
}

.tagNickText {
	@include body2($grey500);
	color: $grey500;
}

.workerNameText {
	@include h6;
}

.entityText {
	@include small;
}

.statsSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	height: 100%;
	padding: 38px 0;
}

.statTextHeader {
	@include small-regular($grey500);
	width: 100%;
	padding: 0;
}

.statText {
	@include subtitle-semi;
	text-align: center;
}

.divider {
	width: 1px;
	height: 100%;
	border: 1px solid #f0f0f0;
}

.statsInnerSection {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}
