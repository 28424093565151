@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/text';

.title {
	@include h6;
}

.icon {
	border-radius: 50%;
	background-color: $white;
}

.description {
	@include small($grey700);
	width: 80%;
	padding: 8px 0 0;

	&.isBanner {
		background-color: $blue200;
	}
}

.titleDarkTheme {
	color: $white;
}

.image {
	width: 50px;

	&.isBanner {
		align-self: baseline;
		margin-top: 30px;
	}
}

.button {
	align-self: flex-end;
}
