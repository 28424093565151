@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.radioButtonContainer {
	@include small();
	display: flex;
	gap: 8px;
}

.baseRadioButton {
	width: 18px;
	height: 18px;
	border-radius: 100%;
	border: 1px $grey200 solid;
	cursor: pointer;
}

.hover {
	&:hover {
		border: 1px $primaryColor solid;
	}
}

.checkedRadioButton {
	background-color: $primaryColor;
	border: 1px $primaryColor solid;
}

.disabled {
	cursor: not-allowed;
}
