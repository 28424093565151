/*Date picker global classes*/
@import '@shared/styles/mixins/text';
@import '../_variables.scss';

.react-datepicker {
	@include body2();
	border: none !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.react-datepicker__header {
	background-color: unset !important;
	border-bottom: unset !important;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.react-datepicker__triangle {
	display: none !important;
}

.react-datepicker-popper {
	margin-top: 5px !important;
}

.react-datepicker__year-read-view--selected-year {
	font-size: 15px !important;
	font-weight: 500;
}

.react-datepicker__header__dropdown--scroll {
	position: absolute;
	top: 7px;
	right: 27%;
}

.react-datepicker__year-option--selected {
	display: none !important;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: rgba(0, 0, 0, 0.04) !important;
}

.react-datepicker__year-dropdown {
	width: 130% !important;
	top: 5px !important;
	background-color: #ffffff !important;
	box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	border: none !important;
}

// Week
.react-datepicker__week {
	position: relative;
	z-index: 1;
}

// Days
.react-datepicker__day {
	@include small($primaryColor !important);
	margin: 0px !important;
	width: 40px !important;
	height: 40px !important;
	line-height: 2.4rem !important;
}

.react-datepicker__day:not(.react-datepicker__day--disabled):hover {
	background-color: $grey50 !important;
	color: $primaryColor !important;
	border-radius: 50% !important;
}

.react-datepicker__day-name {
	@include small($grey400 !important);
	width: 32px !important;
	margin: 8px 4px !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--highlighted {
	background-color: $blue100 !important;
	border-radius: 0px !important;
	position: relative;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-end {
	background-color: $primaryColor !important;
	border-radius: 50% !important;
	color: $white !important;
}

.react-datepicker__day--keyboard-selected {
	background-color: transparent !important;
	border-radius: 50% !important;
	color: $primaryColor !important;
	border: $white 0px !important;
}

.react-datepicker__day--disabled {
	@include small($grey300 !important);
}

.react-datepicker__day--outside-month {
	@include small($primaryColor !important);
	margin: 0px !important;
	width: 40px !important;
	height: 0px !important;
	visibility: hidden;
}

.react-datepicker__day--in-range:not(
		.react-datepicker__day--weekend,
		.react-datepicker__day--range-end,
		.react-datepicker__day--range-start
	)::before,
.react-datepicker__day--highlighted:not(
		.react-datepicker__day--weekend,
		.react-datepicker__day--range-end,
		.react-datepicker__day--range-start
	)::before {
	content: '' !important;
	background-color: $blue100;
	height: 40px;
	width: 80px;
	left: -20px;
	position: absolute;
	z-index: -1;
}

.react-datepicker__year-read-view--down-arrow {
	border: solid black !important;
	border-width: 0 2px 2px 0 !important;
	display: inline-block !important;
	padding: 3px !important;
	transform: rotate(45deg) !important;
	width: 0 !important;
	top: 4px !important;

	&::before {
		border-top-color: transparent !important;
	}
}

.react-datepicker__navigation--years-upcoming {
	transform: rotate(-135deg) !important;
	top: 4px !important;
	height: 1px !important;
	width: 1px !important;
}

.react-datepicker__navigation--years-previous {
	transform: rotate(45deg) !important;
	top: -3px !important;
	height: 1px !important;
	width: 1px !important;
}

.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
	background-color: #1d5d90 !important;
}
