@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.chipContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;

	.iconContainer {
		@include small($white);
		border-radius: 4px;
		padding-inline-start: 2px;
		padding-inline-end: 6px;
		width: max-content;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2px;
		height: 100%;

		.noIconText {
			margin-inline-start: 4px;
		}
	}
}
