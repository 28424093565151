@import '../../../styles/mixins/media_queries';
@import '../../../styles/variables';
@import '../../../styles/mixins/text';

.trusstorButton {
	@include small-regular();
	position: relative;
	@include for-mobile-layout {
		@include body2();
	}
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: unset;
	cursor: pointer;
	min-width: 50px;

	img {
		filter: brightness(0) invert(1);
		margin-inline-end: 3px;
	}

	.loaderClass {
		position: absolute;
	}
}

.invisible {
	visibility: hidden;
}

.largeButton {
	@include button-large();
	padding: 8px 16px;
	height: 48px;

	&.startPadding {
		padding-inline-start: 24px;
	}

	&.endPadding {
		padding-inline-end: 24px;
	}
}

.defaultButton {
	@include body2();
	padding: 8px;
	height: 40px;

	&.startPadding {
		padding-inline-start: 16px;
	}

	&.endPadding {
		padding-inline-end: 16px;
	}
}

.smallButton {
	@include button-small();

	padding: 4px;
	height: 32px;

	&.startPadding {
		padding-inline-start: 16px;
	}

	&.endPadding {
		padding-inline-end: 16px;
	}
}

.primary {
	background-color: $darkBlue;
	color: $white;

	@include for-wide-layout {
		&:hover {
			background-color: $grey700;
		}
	}

	&.disabled {
		background-color: $grey100;
		color: $grey300;
		cursor: not-allowed;
		pointer-events: none;

		img {
			filter: brightness(0) saturate(100%) invert(81%) sepia(7%) saturate(191%) hue-rotate(173deg) brightness(90%)
				contrast(97%);
		}
	}
}

.primaryColored {
	background-color: $darkBlue;
	color: $blue200;

	&.disabled {
		background-color: $grey100;
		color: $grey300;
		cursor: not-allowed;
		pointer-events: none;

		img {
			filter: brightness(0) saturate(100%) invert(81%) sepia(7%) saturate(191%) hue-rotate(173deg) brightness(90%)
				contrast(97%);
		}
	}
}

.primaryWithLightHover {
	background-color: $darkBlue;
	color: $white;

	&:hover {
		background-color: $white;
		color: $darkBlue;
		border: 1px solid $darkBlue;
	}

	&.disabled {
		background-color: $grey100;
		color: $grey300;
		cursor: not-allowed;
		pointer-events: none;

		img {
			filter: brightness(0) saturate(100%) invert(81%) sepia(7%) saturate(191%) hue-rotate(173deg) brightness(90%)
				contrast(97%);
		}
	}
}

.secondary {
	background-color: $grey100;
	color: $darkBlue;

	@include for-wide-layout {
		&:hover {
			background-color: $grey200;
		}
	}

	&.disabled {
		background-color: $grey100;
		color: $grey300;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.outline {
	background-color: $white;
	color: $darkBlue;
	border: 1px solid $grey300;

	@include for-wide-layout {
		&:hover {
			background-color: $grey50;
			border: 1px solid $darkBlue;
		}
	}

	&.disabled {
		background-color: $white;
		color: $grey300;
		border: 1px solid $grey100;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.outlinePositive {
	background-color: $white;
	color: $darkBlue;
	border: 1px solid $green300;
	@include button-small();

	@include for-wide-layout {
		&:hover {
			border: 1px solid $feedbackPositiveDark;
		}
	}

	&.selected {
		background-color: $feedbackPositiveLight;
		border: 1px solid $grey300;

		@include for-wide-layout {
			&:hover {
				border: 1px solid $feedbackPositiveDark;
			}
		}
	}

	&.disabled {
		background-color: $white;
		color: $grey300;
		border: 1px solid $grey100;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.outlineNegative {
	background-color: $white;
	color: $darkBlue;
	border: 1px solid $red300;
	@include button-small();

	@include for-wide-layout {
		&:hover {
			border: 1px solid $feedbackCriticalDark;
		}
	}

	&.selected {
		background-color: $feedbackCriticalMedium;
		border: 1px solid $feedbackCriticalDefault;

		@include for-wide-layout {
			&:hover {
				border: 1px solid $feedbackCriticalDark;
			}
		}
	}

	&.disabled {
		background-color: $white;
		color: $grey300;
		border: 1px solid $grey100;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.ghost {
	background-color: transparent;
	color: $darkBlue;

	@include for-wide-layout {
		&:hover {
			color: $grey600;
			opacity: 0.8;
		}
	}

	&.disabled {
		color: $grey300;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.ghostColored {
	background-color: transparent;
	color: $blue300;

	&.disabled {
		background-color: transparent;
		color: $grey300;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.hasIconElement {
	gap: 4px !important;
	padding-inline-start: 8px !important;
	padding-inline-end: 16px !important;
}
