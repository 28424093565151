@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.boxWithTitle {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	height: 60px;
	width: 100%;
	padding: 20px 15px 10px 10px;
}

.titleText {
	@include subtitle-semi;

	@include for-narrow-layout {
		@include body2;
	}
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 40px;
	cursor: pointer;
}
