@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/_text.scss';

.issueLocationContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
	.locationItem {
		display: flex;
		align-items: center;
		gap: 8px;

		.floorText {
			@include body2($grey400);
		}
	}
}

.fullPageContainer {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dialog {
	min-width: 350px !important;
	width: 90vw;
	max-height: 80vh;
	display: flex;
	flex-direction: column;
	padding: 12px 12px 0 12px;
	overflow: hidden;
}
