@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.scoreBarContainer {
	width: 100%;
	display: flex;
	align-items: flex-end;

	.barContainer {
		@include small-regular($white);
		margin: 0 0.5px;
		position: relative;
		flex: 1;

		.startText {
			position: absolute;
			inset-inline-start: -7px;
			inset-block-end: -17px;
		}

		.endText {
			position: absolute;
			inset-inline-end: -7px;
			inset-block-end: -17px;
		}

		.tooltipContainer {
			position: relative;
			inset-block-end: 30px;
			inset-inline-end: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			height: 20px;
			min-width: 70%;
			width: max-content;
			padding: 0 5px;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			&.rtl {
				inset-inline-start: auto;
				inset-inline-end: 50%;
				transform: translateX(-50%);
				margin: 0 auto;
			}
			.tooltipText {
				display: flex;
				align-items: center;
				justify-content: center;
				@include small();

				span {
					font-size: 9px;
					margin-inline-start: 5px;
				}
			}

			.arrowEl {
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5px 5px 5px;
				inset-block-start: 19px;
				inset-inline-start: 50%;
				transform: translateX(-50%) rotate(180deg);
				direction: ltr;
			}
		}
	}
}
