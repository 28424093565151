@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.tableContainerWrapper {
	display: flex;
	flex-grow: 1;
	background-color: $white;
	margin-top: 8px;
}

.tableContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: $white;
	overflow-x: auto;
}

.tableContent {
	width: 100%;
	height: 100%;
	min-height: 245px;
}

.emptyState {
	@include subtitle-reg();
}

.emptyStateContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
