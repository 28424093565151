@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins/text';

.dropdownItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 8px 12px;
	cursor: pointer;
	@include body2;
	gap: 4px;
}

.highlighted {
	background: $grey50 !important;
}

.selected {
	background: $blue100;
}

.leftSection {
	display: flex;
	align-items: center;
}
