@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.titleContainer {
	margin-bottom: 4px;
	margin-inline-end: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pageTitleBox {
	width: 100%;
	justify-content: center;
	padding: 20px !important;
}

.title {
	@include h6;
	width: 100%;

	@include for-narrow-layout {
		font-size: 16px !important;
	}
}

.pageContainer {
	display: flex;
	flex-direction: column;
	margin: 16px;
	width: 100%;
}

.pageContent {
	display: flex;
	max-height: calc(100% - 70px);

	@include for-narrow-layout {
		max-height: calc(100% - 64px);
	}
}

.pageDashboardContainer {
	min-width: 15%;
	max-width: 15%;
	overflow: auto;
	flex-wrap: nowrap;
}

.pageTableContainer {
	min-width: 98%;
}

.addUserButton {
	width: 73px;
}
