@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.container {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.eventDetailsContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.eventDetailsContainerClosed {
	opacity: 0.5;
}
.eventTitle {
	@include body2;
}

.detailContainer {
	display: flex;
	align-items: center;
	width: fit-content;
	padding-bottom: 4px;
	border-bottom: 1px solid rgba(16, 48, 69, 0.2);
}

.detailTitle {
	@include small-regular;
	margin-inline-end: 4px;
}

.detailText {
	@include small;
}

.initialDirection {
	direction: initial;
}

.row {
	display: flex;
	flex-direction: row;
	gap: 16px;
	flex-wrap: wrap;
}

.closeDataContainer {
	align-items: center;
	padding: 8px;
	display: flex;
	flex-direction: row;
	height: 30px;
	background-color: rgba(16, 48, 69, 0.1);
	border-radius: 4px;
	width: fit-content;
}
