@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.widgetCard {
	display: flex;
}

.sequenceProgressCardsContainer {
	max-height: 664px;
	overflow: auto;
	width: 100%;
	flex: 1;
	border-top: 1px solid $grey200;
	padding: 0 16px;
}

.floorNick {
	@include small();
}

.count {
	@include small($grey600);
}

.floorTitleSection {
	display: flex;
	justify-content: space-between;
	padding: 16px 8px;
}

.floorAsisWrapper {
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
	border-bottom: 1px solid $grey100;
}

.floorAsis {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

.areaSequenceItemContainer {
	@include small-regular();
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	flex-direction: column;
	background-color: $white;
	border: 1px solid $grey200;
	padding: 8px 0;
	border-radius: 8px;
	position: relative;
	min-width: 50px;
	cursor: pointer;

	&:hover {
		background-color: $grey50;
	}

	&.done {
		background-color: lighten($feedbackPositiveDefault, 15%);
	}

	.areaText {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
	}

	.timeText {
		color: $grey500;
	}

	img {
		position: absolute;
		top: 5px;
		right: 5px;
	}
}
