@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/_text.scss';
@import '../../../styles/mixins/media_queries';

.dateTextContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 4px;
	border-radius: 4px;

	&.red {
		background-color: $feedbackCriticalLight;
	}

	.dateText {
		@include small($grey500);
		text-transform: uppercase;
	}
}
