@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.emptyState {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	padding-top: 46px;

	.mainText {
		@include h5();
	}

	.subText {
		@include body1();
	}
}
