@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.professionActivitiesChipContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	padding: 4px;
	border-radius: 4px;
	pointer-events: visiblePainted;
	max-height: 90%;
	gap: 4px;
}

.flexContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	@include small($white);
}

.numberOfActivitiesChip {
	background-color: $white;
	color: $darkBlue;
	border-radius: 2px;
	padding: 0px 2px;
	min-width: 13px;
}

.progressPercentageContainer {
	padding: 0px 1px 0px 4px;
}

.minimizedMode {
	background-color: transparent !important;
	color: $white !important;
}
