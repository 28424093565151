@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.multiFloorPlanDisplayContainer {
	display: flex;
	flex-direction: column;
	.floorSwitcher {
		@include subtitle-semi($darkBlue);
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.arrow {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 24px;
			width: 24px;
		}

		.floorSwitcherText {
			@include body2($grey700);
		}
	}
}

.loaderContainer {
	padding-top: 14px;
	width: 100%;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.floorPlanContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.floorNickText {
		@include subtitle-semi();
		text-transform: capitalize;
	}
}
