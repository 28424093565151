@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/mixins/_text.scss';

$headerHeight: 45px;

.container {
	height: 100%;
	position: absolute;
	z-index: 9;
	background: white;
	top: 0;
	inset-inline-start: $sideBarWidth;
	min-width: $leftSideViewWith;
	max-width: $leftSideViewWith;
	box-shadow: $componentShadow;
	transition: inset-inline-start 0.7s;
	overflow-y: auto;

	@include for-narrow-layout {
		min-width: $narrowLeftSideViewWidth;
		max-width: $narrowLeftSideViewWidth;
		inset-inline-start: $narrowSideBarWidth;
	}

	.header {
		padding: 8px 8px 8px 16px;
		border-bottom: $grey100 solid 1px;
		height: $headerHeight;
		position: fixed;
		background-color: $white;
		min-width: $leftSideViewWith;
		max-width: $leftSideViewWith;
		z-index: 20;

		@include for-narrow-layout {
			min-width: $narrowLeftSideViewWidth;
			max-width: $narrowLeftSideViewWidth;
		}

		.title {
			@include h6;
		}

		.close {
			position: absolute;
			top: 0;
			inset-inline-end: 0;
			margin: 10px;
			cursor: pointer;
			width: 24px;
			height: 24px;
		}
	}

	.content {
		display: flex;
		margin-top: $headerHeight;
		height: calc(100% - $headerHeight);

		.cardsContainer {
			width: 100%;
			padding: 0 24px;

			.cardsSection {
				display: flex;
				flex-direction: column;
				gap: 16px;
				margin-top: 16px;

				.title {
					@include body2($grey400);
				}

				.divider {
					height: 1px;
					background-color: $grey100;
					width: 100%;
				}
			}
		}
	}
}

.notDisplay {
	inset-inline-start: -600px;
}

.notification {
	opacity: 1;
	transition: transform 0.3s ease, opacity 0.3s ease;
}

.notificationRemove {
	transform: translateX(-200%);
	opacity: 0;
}
