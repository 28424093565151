.floorPlanContainer {
	position: relative;
	width: 100%;
	height: 100%;
}

.svg {
	width: 100%;
}

.pinPoint {
	position: absolute;
	transform: translate(-50%, -50%);
	pointer-events: none;
}
