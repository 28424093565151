@import '../../styles/mixins/text';
@import '../../styles/_variables.scss';

.container {
	display: flex;
	width: 100%;
	height: 38px;
	border-radius: 4px;
	border: 2px solid #d5d5d5;
	justify-content: space-between;
	align-items: center;
	padding: 0 9px;
	cursor: pointer;

	&:hover {
		border: 1px solid $grey700;
	}

	&.isOpen {
		border: 1px solid $grey700;
	}

	&.isDisabled {
		border: 2px solid $grey300;
		background-color: $grey50;
		pointer-events: none;
	}
}

.text {
	@include small-regular();
	line-height: 18px;
	color: $grey400;
}

.smallPicker {
	@include small($grey600);
	display: flex;
	height: 24px;
	padding: 8px 2px;
	align-items: center;
	gap: 4px;
	border-radius: 2px;
	border: 1px solid $grey200;
}
