@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.NoSequencesEmptyState_container {
	height: calc(100vh - 292px);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 26px;
}

.image {
	width: 150px;
	margin-bottom: 20px;
}

.texts {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	border-bottom: 20px;
}

.title {
	margin-top: 20px;
	@include h5;
}

.description {
	@include body1;
}
