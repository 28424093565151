@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/text';

.title {
	@include body2;
}

.description {
	@include small-regular($grey600);
}

.descriptionDarkTheme {
	color: $grey200;
}

.titleDarkTheme {
	color: $white;
}

.descriptionSnackbar {
	background-color: inherit;
	padding: 0px;
	color: $grey200;
	white-space: normal;
}
