@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.RecentInvestigationReportCard_container {
	cursor: pointer;
	padding: 12px 0;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $grey100;

	&:hover {
		box-shadow: 0 0 8px rgba(17, 24, 39, 0.16);
	}
}

.leftSection {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.title {
	@include subtitle-semi();
}

.bottomLeftPart {
	@include small-regular;
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.divSeparator {
	width: 1px;
	height: 12px;
	border-left: 1px solid rgb(154, 174, 187);
	align-self: center;
}

.tagSection {
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.rightSection {
	display: flex;
	align-items: center;
}

.rightInnerSection {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	gap: 33px;
}

.downloadImage {
	width: 24px;
	height: 24px;
}

.dateGenerated {
	@include small-regular;
}
