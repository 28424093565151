@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/media_queries';

.MainPageDashboardContainer {
	display: flex;
	flex-direction: column;
	min-width: $leftSideViewWith;
	max-width: $leftSideViewWith;
	box-shadow: $componentShadow;
	@include for-narrow-layout {
		min-width: $narrowLeftSideViewWidth;
		max-width: $narrowLeftSideViewWidth;
	}
	z-index: 10;
}

.contentSection {
	height: 100%;
	overflow: auto;
}

.projectSelectorContainer {
	padding: 16px;
	border-bottom: 1px solid rgba(217, 217, 217, 0.3);
}

.widgetHeader {
	position: sticky;
	top: 0;
	background: $white;
	z-index: 10;
}
