@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.linkContainer {
	cursor: pointer;
	position: relative;
	background-color: $primaryColor;
	margin-bottom: 1px;
	margin-right: 1px;
	text-decoration: none;
	color: #fbfbfb;
	width: 100%;
	height: 108px;
	display: none;

	&.isSmallScreen {
		height: unset;
	}

	&.show {
		display: block;
	}

	.buttonContainer {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 8px 5px 8px 5px;
		width: 100%;

		button {
			border-radius: unset !important;
			padding: 4px !important;
			border-left: 2px solid transparent;
		}
	}

	.text {
		@include small($grey300);
		font-size: 11px !important;
		text-align: center;

		&.selected {
			color: $white !important;
		}
	}

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 4px;

		&.isSelected {
			background: $grey700;
		}

		&.isHover {
			background: $grey700;
		}
	}

	.subMenuContainer {
		position: absolute;
		left: 86px;
		top: 10px;
		display: flex;
		width: 100%;
		z-index: 20;

		@include for-narrow-layout {
			left: 75px;
		}

		&.rtl {
			left: 0;
			right: 86px;
			width: 100%;

			@include for-narrow-layout {
				right: 75px;
			}
		}

		.arrowContainer {
			width: 9px;
			height: 100%;
		}

		.triangle {
			top: 8px;
			position: relative;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 7.5px 15px 7.5px 0;
			border-color: transparent $primaryColor transparent transparent;
		}

		.triangleRtl {
			transform: rotate(180deg);
		}

		.subMenuListContainer {
			background-color: $primaryColor;
			box-shadow: 0px 4px 4px rgba(128, 128, 128, 0.2);
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			height: max-content;
			width: max-content;
			padding-top: 8px;
			padding-bottom: 8px;
			z-index: 20000;

			&.fullListContainer {
				min-width: 170px;
				width: max-content;
			}

			.pullHeadlineDown {
				margin-top: 4px !important;
			}

			.subMenuHeadline {
				@include body2($white !important);
				text-align: start;
				margin: 2px 0;
				line-height: 16px;
				width: max-content;
			}
		}
	}
}
