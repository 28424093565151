@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.dialogContainer {
	flex-direction: column;
	display: flex;
	min-height: 600px;
	gap: 8px;
	padding: 16px;
	border-radius: 8px;
	@include small();

	&.dark {
		background-color: $grey700;
		color: #b7bcc2;
	}

	.headerContainer {
		border-radius: 8px;
		margin: 5px;
		padding: 16px;
		min-height: 250px;
	}

	.lineBreak {
		width: 100%;
		height: 1px;
		margin-top: 30px;
		border-bottom: 1px solid #6f7985;
	}
}

.todayIndicatorContainer {
	position: relative;

	.separateLine {
		position: relative;
		inset-inline-start: 20px;
	}

	.todayIndicatorIcon {
		position: absolute;
		top: -5px;
		inset-inline-start: 17px;
	}

	.todayIndicatorTextContainer {
		position: absolute;
		top: -15px;
		inset-inline-start: 42px;

		.todayText {
			&.dark {
				color: $white;
			}
		}
	}
}
