@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.progressBarContainer {
	display: flex;
	gap: 6px;
	align-items: center;

	.progressBar {
		width: 106px;
		min-height: 10px;
		padding: 2px;
		border-radius: 48px;
		border: 1px solid $grey100;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.progressContainer {
			border-radius: 48px;
			min-height: 6px;
			width: 100%;
			background-color: $grey50;
			position: relative;

			.progress {
				position: absolute;
				min-height: 6px;
				border-radius: 48px;
				background-color: $feedbackPositiveDefault;
			}
		}
	}

	.percentageText {
		@include h5();
	}
}

.progressTooltip {
	@include small-regular();
	display: flex;
	align-items: center;
	color: $white;
	line-height: 10px;

	.icon {
		width: 16px;
		height: 16px;
		margin-inline-end: 2px;
	}

	.addtionalTooltipText {
		margin-inline-start: 4px;
	}
}
