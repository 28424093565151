@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.tooltip {
	background: $grey700 !important;
}

.wrapper {
	display: flex;
	width: 436px;
}

.oneMoreWrapper {
	display: flex;
	gap: 40px;
}

.section {
	padding: 16px;
}

.title {
	@include small($white);
}

.text {
	padding: 0 0 8px;
	list-style-type: circle;
	@include small-regular($white);
}

.ul {
	padding: 8px 0 0 5px;
}
