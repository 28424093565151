@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.SequenceSelectionSection_container {
	display: flex;
	gap: 8px;
	align-items: center;
}

.isInfoOpen {
	border-radius: 50%;
	background: $grey100;
}

.tooltipComponent {
	position: relative;
	cursor: pointer;
}

.tooltip {
	cursor: initial;
	position: absolute;
	top: 35px;
	width: 500px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	border-radius: 4px;
	@include link($grey100);
	background: $grey700;
	z-index: 1000000000;
}

.title {
	@include h5(#2cffcb);
}

.close {
	cursor: pointer;
	width: fit-content;
	align-self: end;
	display: flex;
}
