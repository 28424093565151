@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.bottomSection {
	overflow: scroll;
	padding: 8px 0px;
	padding-inline-end: 6px;
	.sectionName {
		width: 100%;
		@include small;
		padding: 12px;
		border-top: 1px solid $grey400;
		border-bottom: 1px solid $grey400;
		cursor: pointer;

		&:hover {
			background-color: $grey50;
		}
	}

	.areaItem {
		display: flex;
		align-items: center;
		padding: 12px;
		@include body2($grey600);
		padding-inline-start: 24px;
		cursor: pointer;

		&:hover {
			background-color: $grey50;
		}
	}
}
