@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.masterWrapper {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid rgba(240, 240, 240, 1);
}

.wrapper {
	display: flex;
	gap: 4px;
	padding: 10px 0;
	cursor: pointer;
}

.textWrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.title {
	@include body2;
}

.description {
	@include small-regular($grey600);
}

.dropdownWrapper {
	margin-left: 30px;
	margin-top: -5px;
}

.radioButton {
	margin-right: 10px;
	display: inline-block;
	position: relative;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 1px solid rgba(16, 48, 69, 0.1);
	top: 5px;
	vertical-align: middle;
}

.selected {
	display: inline-block;
	position: relative;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 5px solid $primaryColor;
	top: 5px;
	vertical-align: middle;
}

.selectAllOptionClass {
	width: 100%;
}
