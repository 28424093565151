@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.iconContainer {
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2px;
	border: $grey400 solid 2px;
}

.checked {
	background-color: $darkBlue;
	border-color: $darkBlue;
}

.unchecked {
	background-color: $white !important;
}

.disabled {
	background-color: $grey200;
	border: $grey200 solid 2px;
}
