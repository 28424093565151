@import '../../styles/variables';
@import '../../styles/mixins/text';

.DashboardTabEmptyStateContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 32px;
	padding: 0px 16px 16px 16px;
	overflow: hidden;

	.img {
		padding-top: 100px;
	}

	.DashboardTabEmptyStateTitle {
		text-align: center;
		@include subtitle-semi();
	}
}
