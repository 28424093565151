@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/text';

.title {
	@include body2;
}

.description {
	@include small-regular($grey400);
}

.titleDarkTheme {
	color: $white;
}

.descriptionDarkTheme {
	color: $grey200;
}

.icon {
	filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(0%) hue-rotate(21deg) brightness(105%)
		contrast(100%);
	width: 20px;
}

.tagsText {
	unicode-bidi: plaintext;
}
