@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.TagsAnalysisCustomTooltip_container {
	display: flex;
	@include small-regular();
	flex-direction: column;
}

.date {
	@include small-regular($white);
	background: $grey500;
	padding: 8px;
	margin-bottom: 5px;
}

.workHours {
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 5px 15px;
}

.workers {
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 5px 15px;
}

.dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
}
