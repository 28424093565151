@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/utility_classes.scss';
@import '../../../styles/mixins/media_queries';

.textInputWrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	gap: 8px;
	padding: 0 16px;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 76px;
	background: $white;
	border-top: 1px solid $grey200;

	.imageInputContainer:hover {
		cursor: pointer;
	}

	.imageInput {
		display: none;
	}
}

.inputBox {
	@include flex-center;
	justify-content: space-between;
	min-height: 50px;
	width: 95%;
	border-radius: 30px;
	border: 1px solid rgba(16, 48, 69, 0.12);
	padding: 10px 18px;
	@include small-regular();

	& textarea {
		all: unset;
		width: 80%;
		height: 16px;
		overflow: auto;
		max-height: 150px;
		padding: 5px 0;
		overflow-wrap: break-word;

		&::placeholder {
			color: $disabledGray;
		}
	}
}

.button {
	@include button-small();
	cursor: pointer;

	@include for-wide-layout {
		&:hover {
			color: $grey600;
		}
	}
}
