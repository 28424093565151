@import '../../styles/mixins/text';
@import '../../styles/variables';

.root {
	white-space: nowrap;
	overflow: hidden;
}

.text {
	@include small(#212121);
	line-height: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.lightText {
	@include small-regular;
	line-height: unset;
	width: 100%;
}

.text {
	@include small-regular;
}

.labelContainer {
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	justify-content: space-between;
}

.clickable {
	cursor: pointer;
}

.closeIcon {
	width: 8px !important;
	color: $primaryColor !important;
}

.label {
	&:global(.MuiChip-label) {
		padding: 8px;
	}
}

.fullWidth {
	width: 100%;
}

.allowTextOverflow {
	overflow: unset !important;
}

.menuDots {
	display: flex;
	justify-content: center;
}
