@import '../../../styles/variables';
@import '../../../styles/mixins/text';

.root {
	height: 40px;
	border: 1px solid #d5d5d5;
	border-radius: 4px;
}

.label {
	@include small-regular();
	color: $grey400 !important;
	margin-inline-start: -4px !important;
	top: -5px !important;
}

.textInputContainer {
	display: flex;
}

.input {
	&:global(.MuiInputBase-input) {
		@include small();
		width: 95% !important;
		height: 100%;
		padding: 0 !important;
		cursor: pointer;
		position: absolute;
	}
}
