@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.specialFloorsContainer {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
}

.rowTitles {
	width: 100%;
	display: grid;
	grid-template-columns: 200px 1fr auto;
	padding: 16px;
	background: $grey50;
	border-radius: 8px;

	div {
		@include subtitle-semi();
	}
}

.title {
	@include h6();
	margin-top: 40px;
}
