@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/_variables.scss';

.tradeTitle {
	@include subtitle-semi();
	margin-bottom: 16px;
}

.workerList {
	padding-bottom: 32px;
}
