@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.visitsContainer {
	display: flex;
	height: 100%;
	margin-top: 16px;
	flex-direction: column;
	margin-inline-end: 20px;

	.row {
		display: flex;
		justify-content: space-between;
		padding: 8px 0;
		border-bottom: 1px solid $grey100;

		.visitsTitle {
			@include small-regular;
		}

		.visitsValue {
			@include small;
		}
	}
}
