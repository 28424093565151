@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

@mixin leftTableSide {
	padding: 12px 16px;
	display: flex;
	width: 240px;
	border-right: 1px solid $grey100;
	border-left: 1px solid $grey100;
}

@mixin rightTableSide {
	display: flex;
	flex: 1;
}

.periodsGraphContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	.header {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $grey100;

		.leftHeader {
			@include leftTableSide();
			justify-content: space-between;

			.mainTitleText {
				@include subtitle-semi();
				line-height: 21px;
				text-transform: capitalize;
			}

			.subtitleText {
				@include small-regular();
				line-height: 18px;
				text-transform: capitalize;
			}
		}

		.rightHeader {
			@include rightTableSide();
			justify-content: space-between;
			direction: ltr;

			.graphTitleEl {
				@include small();
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 13px;
				white-space: nowrap;
				width: 100%;
			}
		}
	}

	.rowContainer {
		display: flex;
		align-items: center;
		flex: 1;
		border-bottom: 1px solid $grey100;

		.leftRowSide {
			@include leftTableSide();
			justify-content: space-between;
			max-width: 258px;

			.leftRowDetails {
				color: $primaryColor;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				max-width: 95%;

				.leftRowTitleContainer {
					display: flex;
					align-items: center;

					img {
						margin-inline-end: 9px;
					}

					.titleText {
						@include subtitle-semi();
						margin-inline-end: 8px;
						line-height: 21px;
					}

					.extraTitleText {
						@include small();
						line-height: 18px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.leftRowInfoContainer {
					display: flex;
					flex-wrap: wrap;
					@include small-regular(#676879);
					line-height: 18px;

					.titleInfoText {
						margin-inline-end: 5px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}

					.divider {
						margin-inline-end: 5px;
					}
				}
			}

			.secondTitleText {
				@include small(#676879);
				line-height: 18px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-overflow: ellipsis;
			}
		}

		.graphContainer {
			@include rightTableSide();
			padding-top: 0;
			padding-bottom: 0;
			height: 100%;
			position: relative;

			.cube {
				box-shadow: 1px 0 0 $grey100;
				min-width: 6px;
				height: 100%;
			}

			.periodBar {
				height: 10px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
