@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

$adHocCardWidth: 48px;

.adhoCardsContainer {
	border-top: 1px solid $grey100;
	border-bottom: 1px solid $grey100;
	display: grid;
	grid-column-gap: 24px;
	grid-template-columns: repeat(auto-fill, $adHocCardWidth);
	width: 100%;
	padding: 0 16px;

	.floorCard {
		height: 76px;
		width: $adHocCardWidth;
		padding: 8px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.floorText {
			text-align: center;
			@include small($grey700);
			line-height: 16px;
		}

		.floorHours {
			@include small-regular($grey400);
			line-height: 16px;
		}
	}
}
