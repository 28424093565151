@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.boxContainer {
	display: flex;
	flex-direction: column;
	width: 300px;
	height: 300px;
	background-color: $white;
	border-radius: 4px;
	padding: 8px;
	overflow: hidden;
	margin-left: 4px;
	margin-right: 4px;
	margin-top: 0;
}

.titleContainer {
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
	margin-bottom: 8px;
}

.subtitleContainer {
	display: flex;
	flex-direction: row;
}

.iconContainer {
	height: 18px;
	width: 16px;
}

.titleWrapper {
	display: flex;
	align-items: center;
}

.title {
	margin-bottom: 8px;
	margin-left: 4px;
	line-height: 1.5;
	text-transform: capitalize;
	@include body2;

	@include for-narrow-layout {
		@include small-regular;
	}
}

.subtitle {
	@include small-regular;
	margin-left: 4px;

	@include for-narrow-layout {
		@include small-regular;
	}
}
