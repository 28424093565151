@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.searchTagContainer {
}

.dateInputContainer {
	input {
		width: 100%;
	}

	.selectedDate {
		color: $primaryColor;
	}
}

.dropdownEndAdornment {
	top: 5px !important;
	filter: brightness(0) saturate(100%);
}
