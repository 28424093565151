@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.container {
	display: flex;
	align-items: center;
	height: 100%;
	inset-inline-start: 0;
	position: relative;
	padding: 0;
	border-inline-end: none;
	border-top: unset !important;
	border-inline-start: unset !important;

	.fixedText {
		@include body2();
		position: sticky;
		height: 100%;
		padding-inline-start: 4px;
		inset-inline-start: 0;
		border-inline-start: 1px solid $grey200;
		z-index: 40000;
		width: 148px;
		display: flex;
		align-items: center;
		text-align: start;
		text-transform: capitalize;
	}
}
