@import '../../styles/variables';
@import '../../styles/mixins/text';

.menu {
	@include small-regular();
	line-height: 18px;
	background-color: $white;
	color: $darkBlue;
	border-radius: 4px;
	box-shadow: 4px 0 6px rgba(17, 24, 39, 0.1), 0 2px 4px rgba(17, 24, 39, 0.06);
}

.menuTitle {
	@include small-regular();
	color: $grey600;
	padding: 8px;
	cursor: default !important;
}

.item {
	display: flex;
	flex-direction: row;
	gap: 2px;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: rgba($primaryColor, 0.1);
	}
}

.icon {
	margin-inline-start: 8px;
}

.title {
	margin-inline-end: 8px;
	margin-inline-start: 8px;
	width: max-content;
}

.circle {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	margin-inline-start: 16px;
	margin-inline-end: 6px;
	border: 1px solid $grey200;
}
