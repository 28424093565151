@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.professionSection {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	img {
		height: 16px;
		width: 16px;
	}

	.professionText {
		@include body2();
	}
}
