@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.tableContainerWrapper {
	display: flex;
	background-color: $white;
	margin-top: 8px;
	width: 100%;
}

.tableContainer {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	display: flex;
	align-items: flex-start;
	background-color: $white;

	.rowsContainer {
		display: flex;
		flex-direction: column;

		.rowContainer {
			display: flex;
		}
	}
}

.emptyHeaderCell {
	height: 25px;
	display: flex;
	justify-content: center;
	background-color: $white;
}

.header {
	display: flex;
	align-items: center;
	width: 95%;
	margin-top: 8px;
	margin-left: 8px;
}

.title {
	@include small();
	text-align: left;
	margin: 12px;
}

.workHours {
	height: 40px;
	display: flex;
	align-items: center;
}

.activityPercentage {
	height: 100px;
	display: flex;
	align-items: center;

	p {
		font-size: 12.5px;
	}
}
