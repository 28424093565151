@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/media_queries';

$containerWidth: 412px;

.menuContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: $white;
	box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.25);
	position: absolute;
	width: $containerWidth;
	top: 0;
	z-index: 99999;
	padding: 8px 12px;
	.menuHeader {
		border-bottom: 1px solid $grey200;
		padding-bottom: 8px;

		.arrowContainer {
			width: 100%;
			display: flex;
			justify-content: flex-start;

			&.arrowContainerRtl {
				transform: rotate(180deg);
			}

			.closeButton {
				cursor: pointer;
			}
		}
	}

	.menuBody {
		margin-top: 16px;
		overflow-y: auto;
		flex: 1;
		.counterSection {
			display: flex;
			justify-content: space-between;
			.counterSectionText {
				@include link();
			}

			.counterSectionNumber {
				@include body2();
				color: $blue300;
			}
		}

		.sequenceTitle {
			@include link();
			margin-top: 16px;
			background-color: $grey50;
			padding: 8px;
		}

		.professionSequences {
			margin-top: 16px;
			border-bottom: 1px solid $grey200;
			padding-bottom: 16px;
			.professionTitle {
				display: flex;
				align-items: center;
				margin-bottom: 16px;
				.professionText {
					@include small-regular;
					margin-inline-start: 5px;
				}
			}

			.floorContainer {
				margin-top: 16px;
				.floorTitle {
					@include small-regular;
				}

				.chipsContainer {
					margin-top: 6px;
					display: flex;
					flex-wrap: wrap;
					gap: 8px;
				}
			}
		}
	}

	.menuFooter {
		display: flex;
		align-items: center;
		padding: 24px;
		border-top: 1px solid $grey200;
		justify-content: space-evenly;
		.cancelButton {
			margin-inline-end: 16px;
			color: $darkBlue;
			background-color: $white;
			border: 1px solid $grey200;
			flex: 1;
		}
		.createButton {
			flex: 3;
		}
	}
}

//____________________________________________________________________________________________________________________

.planningEmptyStateContainer {
	& > div {
		@include subtitle-semi();
		margin: 0 85px 20px 85px;
		text-align: center;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100% - 100px);
	background-color: $grey50;
}
//____________________________________________________________________________________________________________________

.slideLeft {
	right: 0;
	-webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-left {
	0% {
		-webkit-transform: translateX(300px);
		transform: translateX(300px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slide-left {
	0% {
		-webkit-transform: translateX(300px);
		transform: translateX(300px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.hideLeft {
	right: 0;
	-webkit-animation: hide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: hide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes hide-left {
	0% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
	100% {
		-webkit-transform: translateX(300px);
		transform: translateX($containerWidth);
	}
}

@keyframes hide-left {
	0% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
	100% {
		-webkit-transform: translateX(300px);
		transform: translateX($containerWidth);
	}
}

.slideRight {
	left: 0;
	-webkit-animation: slideRight 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slideRight 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slideRight {
	0% {
		-webkit-transform: translateX(-300px);
		transform: translateX(-300px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slideRight {
	0% {
		-webkit-transform: translateX(-300px);
		transform: translateX(-300px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.hideRight {
	left: 0;
	-webkit-animation: hideRight 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: hideRight 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes hideRight {
	0% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
	100% {
		-webkit-transform: translateX(-$containerWidth);
		transform: translateX(-$containerWidth);
	}
}

@keyframes hideRight {
	0% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
	100% {
		-webkit-transform: translateX(-$containerWidth);
		transform: translateX(-$containerWidth);
	}
}
