@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.SpecialFloorInfo_container {
	padding: 16px 0;
	margin: auto;
	position: relative;
}

.hoistOperator {
	@include button-small($grey600);
	display: flex;
	align-items: center;
	gap: 4px;
}

.safetyIcon {
	position: relative;
}
