@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.iconContainer {
	border-radius: 44px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
	cursor: pointer;

	&.squaredButton {
		border-radius: 4px;
	}

	&.small {
		width: 24px;
		height: 24px;

		img,
		svg {
			width: 15px;
			height: 15px;
		}
	}

	&.large {
		width: 40px;
		height: 40px;

		div,
		img,
		svg {
			width: 32px;
			height: 32px;
		}
	}

	&.outline {
		background-color: transparent;
		border: 1px solid $grey300;

		&:hover {
			border: 1px solid $darkBlue;
			background-color: $grey50;
		}

		&.disabled {
			border: 1px solid $grey100;
		}
	}

	&.default {
		background-color: $grey50;

		&:hover {
			background-color: $grey200;
		}

		&.disabled {
			background-color: $grey100;
		}
	}

	&.ghost {
		background-color: transparent;
		filter: brightness(0) saturate(100%) invert(37%) sepia(17%) saturate(448%) hue-rotate(174deg) brightness(94%)
			contrast(86%) !important;

		&:hover {
			filter: brightness(0) saturate(100%) invert(13%) sepia(3%) saturate(5509%) hue-rotate(175deg)
				brightness(90%) contrast(90%) !important;
		}

		&.disabled {
			filter: brightness(0) saturate(100%) invert(84%) sepia(8%) saturate(191%) hue-rotate(173deg) brightness(88%)
				contrast(93%) !important;
		}
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.7;
	}
}

.iconElement {
	background-color: transparent;
}
