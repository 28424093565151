@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.progressSection {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.progressText {
		@include subtitle-semi();
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;

		&.dark {
			color: $white;
		}
	}
}

.completedSection {
	@include small();
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;

	&.dark {
		color: $white;
	}

	.counterSection {
		.percentageText {
			font-size: 16px;
			font-weight: 600;
			@include for-mobile-layout {
				display: none;
			}
		}

		&.rtl {
			direction: rtl;
		}
	}
}

.milestonesProgressSection {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 26px;

	.progressBar {
		width: 85%;
		height: 16px;
		position: relative;

		&.dark {
			background-color: #273547;
		}

		.startPolygon {
			position: absolute;
			right: -10px;
		}

		.endPolygon {
			position: absolute;
			inset-inline-start: -10px;
			&.rtl {
				inset-inline-start: unset;
				inset-inline-end: -10px;
			}
		}

		.startDateText {
			@include small();
			position: absolute;
			inset-inline-start: -20px;
			bottom: -25px;

			&.dark {
				color: $white;
			}
		}

		.endDateText {
			@include small();
			display: flex;
			align-items: center;
			position: absolute;
			inset-inline-end: -20px;
			bottom: -25px;

			&.dark {
				color: $white;
			}
		}

		.milestonePolygon {
			position: absolute;
			inset-inline-start: 25px;
			top: 1px;
		}

		.todayContainer {
			position: relative;

			.todayLine {
				position: absolute;
				top: -9px;
				z-index: 99;
			}

			.todayText {
				@include small();
				position: absolute;
				inset-inline-start: -15px;
				top: -23px;

				&.dark {
					color: $white;
				}
			}
		}
	}
}
