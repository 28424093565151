@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.dropdownButtonContainer {
	width: fit-content;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 2px 8px 8px;
	gap: 4px;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: $grey50;
	}

	.text {
		@include small($grey500);
	}

	.selected {
		color: $darkBlue;
	}

	.disabled {
		color: $grey300;
		cursor: not-allowed;
	}

	.count {
		@include small($white);
		background-color: $darkBlue;
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 32px;
	}
}
