@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/_text.scss';

.dialog {
	width: 512px;
	display: flex;
	flex-direction: column;
	max-height: 80vh;
	padding: 12px 12px 0 12px;
	overflow: hidden;
}

.header {
	display: flex;
	justify-content: space-between;

	.criticalSection {
		cursor: pointer;
		display: flex;
		align-items: center;
		@include small;

		img {
			margin: 0 5px;
		}
	}

	.leftHeader {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;

		.dateText {
			@include small($grey500);
			text-transform: uppercase;
		}
	}
}

.body {
	margin-top: 24px;

	.descriptionContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		padding-bottom: 8px;

		.title {
			@include subtitle-semi($darkBlue);
			@include cut-line(3);
			width: 90%;
			padding-left: 4px;

			&:focus-visible {
				outline: unset;
				@include cut-line(10);
			}
		}
	}
}

.infoSection {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-bottom: 16px;
	padding-top: 16px;

	.professionSection {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;

		img {
			height: 16px;
			width: 16px;
		}

		.professionText {
			@include body2($grey700);
		}
	}

	.locationItemsContainer {
		display: flex;
		flex-direction: column;
	}

	.assigneeSection {
		display: flex;
		@include body2($grey700);
		align-items: center;
		gap: 8px;
	}

	.assigneeText {
		color: $grey500;
	}

	.activitySection {
		display: flex;
		gap: 8px;
		align-items: center;

		img {
			height: 16px;
			width: 16px;
		}

		.activityText {
			@include body2($grey700);
			background-color: $grey50;
			border-radius: 4px;
			padding: 8px;
		}
	}
}

.divider {
	border-bottom: 1px solid $grey100;
	margin-inline-start: -16px;
	width: 110%;
}

.threadsSection {
	padding: 16px 4px;
	overflow: auto;
}

.privateTooltipText {
	@include small($white);
}
