@import '@shared/styles/_variables.scss';

.option {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	min-height: 24px;
	border-radius: 20px;
	color: $grey400;
	position: relative;

	&:hover {
		background-color: $grey50;
		color: $grey600;
	}
}

.selectedOption {
	background-color: $darkBlue;
	color: $white;

	&:hover {
		background-color: $darkBlue;
		color: $white;
	}
}

.dotIcon {
	width: 4px;
	height: 4px;
	position: absolute;
	top: 1px;
	inset-inline-end: 2px;
}
