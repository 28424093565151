@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.wrapper {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 16px;
}

.orderIndex {
	text-align: center;
	min-width: 60px;
	@include h4;
}

.MilestoneCreationCard_container {
	display: flex;
	width: clamp(460px, 100%, 960px);
	padding: 0 26px 8px 16px;
	flex-direction: column;
	border-radius: 8px;
	border: 1px solid $darkBlue;
	background: $white;
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.bottom {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: 8px;
	align-items: center;
	padding: 12px 0px;
}

.divider {
	border-top: 1px solid $grey100;
}

.top {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 12px 0px;
}

.titleDescriptionSection {
	flex: 1;

	.titleSection {
		cursor: text;
		@include subtitle-semi();
		display: flex;
		align-items: center;
		gap: 8px;

		.title {
			&:hover {
				text-decoration: underline;
			}

			&:focus {
				outline: none;
				text-decoration: underline;
			}
		}
	}
}

.icon {
	margin: 0 5px;
	cursor: pointer;
}
