@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.wrapper {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 16px;
}

.MilestoneItemCard_container {
	cursor: pointer;
	display: flex;
	align-items: center;
	width: clamp(460px, 100%, 960px);
	height: 78px;
	padding: 8px 26px 8px 16px;
	gap: 16px;
	border-radius: 8px;
	background: $white;
	border: 1px solid $grey200;

	&:hover {
		box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
		border: 1px solid $darkBlue;
	}
}

.orderIndex {
	text-align: center;
	min-width: 60px;
	@include h4;
}

.titleDescriptionSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;

	.titleSection {
		cursor: text;
		@include subtitle-semi();
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;

		.title {
			&:hover {
				text-decoration: underline;
			}

			&:focus {
				outline: none;
				text-decoration: underline;
			}
		}
	}
}

.bottomPart {
	@include body2($grey600);
	display: flex;
	align-items: center;
	gap: 2px;
}

.linkedSection {
	display: flex;
	align-items: center;
	gap: 24px;
}
