@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.tabContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 100px;

	.descriptionSide {
		.titleSection {
			@include small($white);
			display: flex;
			align-items: center;
			margin-bottom: 5px;

			img {
				margin-inline-end: 6px;
			}
		}
		.descriptionText {
			color: $grey200;
			font-size: 11px;
			padding: 0 5px;
		}
	}

	.graphSide {
		min-width: 40%;
		margin-inline-start: 10px;
	}
}
