.floorElevatorScaffoldContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 3;
}
