@import '../../../styles/variables';
@import '../../../styles/mixins/text';

input:-webkit-autofill {
	-webkit-background-clip: text;
}

.inputContainer {
	display: flex;
	flex-direction: column;
}

.root {
	box-sizing: border-box;
	height: 30px;
	max-height: 30px;
}

.errorText {
	@include small-regular();
	color: $feedbackCriticalDark !important;
	padding-right: 5px;
	padding-top: 2px;
	line-height: 12px;
}

.errorContainer {
	margin-top: 18px;
	height: 12px;
}

.adornment {
	margin-top: 10px;
	margin-inline-start: -3px;
	margin-inline-end: -8px;
	height: 10px;
	width: 10px;
}

.colorAdornment {
	border-radius: 2px;
}

.label {
	&:global(.MuiInputLabel-root) {
		@include small-regular();
		line-height: 12px;

		&:global(.MuiInputLabel-shrink) {
			transform: unset;
		}

		&:global(.MuiInputLabel-formControl) {
			top: 10px;
		}

		&:global(.MuiFormLabel-filled) {
			font-size: 10px !important;
			left: 0;
			top: 18px;
		}

		&:global(.Mui-disabled) {
			color: $disabledGray !important;
		}

		&:global(.Mui-focused) {
			font-size: 10px !important;
			color: $primaryColor;
			margin-left: 0;

			&:global(.MuiInputLabel-formControl) {
				left: 0;
				top: 18px;
			}
		}
	}
}

.labelAdornment-ltr {
	&:global(.MuiInputLabel-root) {
		@include small;
		line-height: 12px;

		&:global(.MuiInputLabel-formControl) {
			top: 31px;
			left: 15px;
		}

		&:global(.MuiInputLabel-shrink) {
			transform: unset;
		}

		&:global(.MuiFormLabel-filled) {
			font-size: 10px !important;
			left: 0;
			top: 18px;
		}

		&:global(.Mui-disabled) {
			color: $disabledGray !important;
			cursor: pointer;
		}

		&:global(.Mui-focused) {
			color: $primaryColor;
			margin-left: 0;
			font-size: 10px !important;

			&:global(.MuiInputLabel-formControl) {
				left: 0;
				top: 18px;
			}
		}
	}
}

.labelAdornment-rtl {
	&:global(.MuiInputLabel-root) {
		@include small;
		line-height: 12px;

		&:global(.MuiInputLabel-formControl) {
			top: 32px;
			right: 25px;
		}

		&:global(.MuiInputLabel-shrink) {
			transform: unset;
		}

		&:global(.MuiFormLabel-filled) {
			font-size: 10px !important;
			right: 0;
			top: 10px;
		}

		&:global(.Mui-disabled) {
			color: $disabledGray !important;

			&:global(.MuiInputLabel-formControl) {
				right: -12px;
			}
		}

		&:global(.Mui-focused) {
			color: $primaryColor;
			margin-right: 0;
			font-size: 10px !important;

			&:global(.MuiInputLabel-formControl) {
				right: 0;
				top: 10px;
			}
		}
	}
}

.textInput {
	margin-top: 3px !important;
	padding-left: 3px !important;

	&:global(.MuiInputBase-root) {
		@include body1();
	}

	&:global(.MuiFilledInput-input) {
		padding-bottom: 0;
	}

	&:global(.MuiInputBase-input) {
		border-bottom-width: 0;

		&:after {
			border-bottom-width: 0;
		}
	}
}

.underline {
	&:global(.MuiFilledInput-underline) {
		border-bottom: 1px solid $primaryColor;

		&:global(.Mui-disabled) {
			border-bottom-color: $disabledGray;

			&:hover {
				border-bottom-color: $disabledGray;
			}
		}

		&::after {
			border-bottom-width: 0;
		}

		&::before {
			border-bottom-width: 0 !important;
		}

		&:hover {
			border-bottom: 1px solid $primaryColor;
		}
	}
}
