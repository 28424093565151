@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';
@import '@shared/styles/mixins/media_queries';

.loginPage {
	@include body2();
	background-image: url('../../../assets/login/loginBackground.png');
	background-position: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100vh;

	@include for-mobile-layout {
		background-image: url('../../../assets/login/loginMobileBackground.png');
		background-size: 100% 100%;
	}

	.headline {
		position: absolute;
		left: 52px;
		top: 52px;
		width: 300px;
		@include for-mobile-layout {
			filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7499%) hue-rotate(58deg)
				brightness(104%) contrast(103%);
			width: 150px;
			left: 50%;
			transform: translateX(-50%);
			@include for-mobile-keyboard-up {
				display: none;
				padding-bottom: 20px;
			}
		}
	}

	.inputSection {
		width: 484px;
		height: 408px;
		@include for-mobile-layout {
			padding: 16px;
			width: auto;
			height: auto;
		}
		display: flex;
		background-color: $white;
		box-shadow: 0 4px 4px $grey100;
		border-radius: 8px;
		flex-direction: column;
		justify-content: flex-start;

		.inputSectionData {
			display: flex;
			flex-direction: column;
			align-items: center;

			@include for-mobile-keyboard-up {
				margin-bottom: 20px;
			}

			.subHeadlines {
				margin-top: 70px;
				@include for-mobile-layout {
					margin-top: 0;
				}

				align-self: center;
				padding-bottom: 16px;

				.firstSubHeadline {
					@include h4;
					font-size: 30px;
					font-weight: 700;
					text-align: center;
					@include for-mobile-layout {
						font-size: 24px;
						font-weight: 600;
					}
				}
			}

			.inputs {
				margin-top: 32px;
				width: 300px;
				@include for-mobile-layout {
					@include body2($grey500);
					margin-top: 0;
				}
			}

			.formButton {
				margin-top: 20px;
				width: 300px;
				height: 50px;
			}

			.subText {
				@include body2($grey600);
				@include for-mobile-layout {
					margin-top: 10px;
				}
				align-self: center;
				margin-top: 10px;
				cursor: pointer;
			}

			.disabled {
				opacity: 0.5;
				cursor: default;
			}
		}

		.ErrorContainer {
			margin-top: 1px;
			height: 12px;

			.ErrorText {
				@include body2();
				font-size: 10px;
				font-weight: 400;
				color: $feedbackCriticalDark;
				padding-right: 2px;
				line-height: 12px;
			}
		}
	}
}

.setPasswordInput {
	margin-bottom: 8px;
}
