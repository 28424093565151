@import '@shared/styles/mixins/_media_queries.scss';

.activityRow {
	overflow: hidden;
}

.firstColumn {
	border-inline-start: 1px solid rgba(224, 224, 224, 1);
	min-width: 150px !important;
	width: 150px;

	@include for-narrow-layout {
		min-width: 120px !important;
		width: 120px;
	}

	@include for-small-layout {
		min-width: 120px !important;
		width: 120px;
	}
}
