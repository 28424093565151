@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.divSeparator {
	width: 1px;
	height: 12px;
	border-left: 1px solid rgb(154, 174, 187);
	align-self: center;
}

.tagSection {
	display: flex;
	flex-direction: row;
	gap: 5px;
}
