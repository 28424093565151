@import '@shared/styles/variables.scss';

.commonHeader {
	background-color: $grey50;
	top: 0;
	inset-inline-start: 0;
	position: sticky;
	padding: 0;
	text-align: start;
	z-index: 30000;
}
