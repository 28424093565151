@import '@shared/styles/variables';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/mixins/text';

.privateSection {
	display: flex;
	flex-direction: row;
	align-items: center;

	.privateLabel {
		@include small($grey600);
		display: flex;
		flex-direction: row;
		align-items: center;

		.privatePrimaryText {
			@include small($grey600);
		}
		.privateSecondaryText {
			@include small($grey400);
		}
	}
}
