@import '../mixins/text';

.MuiInputLabel-root {
	@include small-regular();
	color: $primaryColor !important;
	line-height: 12px;

	&.Mui-disabled {
		color: $disabledGray !important;
	}
}

.MuiInputLabel-shrink {
	font-size: 10px !important;
}

.MuiFilledInput-root {
	background-color: #ffffff !important;
}

.MuiFilledInput-underline {
	border-bottom: 1px solid $primaryColor !important;

	.Mui-disabled {
		border-bottom-color: $disabledGray !important;

		&:hover {
			border-bottom-color: $disabledGray !important;
		}
	}

	&::after {
		border-bottom-width: 0 !important;
	}

	&::before {
		border-bottom-width: 0 !important;
	}

	&:hover {
		border-bottom: 1px solid $primaryColor !important;
	}
}

.MuiInputBase-root.Mui-disabled {
	color: $disabledGray !important;
	background-color: $grey50 !important;
	pointer-events: none !important;
}

.MuiInputBase-root {
	@include small-regular();

	& .Mui-disabled {
		color: $disabledGray !important;
	}
}

.MuiFilledInput-input {
	padding-bottom: 0 !important;
}

.MuiInputBase-input {
	border-bottom-width: 0 !important;
	padding-bottom: 0 !important;

	&:after {
		border-bottom-width: 0 !important;
	}
}

.MuiAutocomplete-endAdornment {
	top: calc(50% - 4px) !important;
}

.MuiMenuItem-root {
	@include small-regular();
}

.MuiSvgIcon-root {
	width: 18px !important;
	height: 18px !important;
}

.MuiCheckbox-root.MuiIconButton-colorSecondary {
	color: $primaryColor !important;
}

.MuiAutocomplete-noOptions {
	@include small-regular();
	color: $primaryColor !important;
}

.MuiSvgIcon-root {
	width: 16px !important;
}
