@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/text';

.title {
	@include subtitle-semi();
	font-weight: 600;
}

.description {
	@include small-regular($grey500);
}

.titleDarkTheme {
	color: $white;
}

.descriptionDarkTheme {
	color: $grey200;
}

.icon {
	filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(0%) hue-rotate(21deg) brightness(105%)
		contrast(100%);
}
