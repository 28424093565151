@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.wrapper {
	display: flex;
	padding-inline-start: 4px;
}

.topSection {
	display: flex;
	justify-content: space-between;
}

.container {
	position: relative;
	@include body2();
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	text-transform: capitalize;
}

.text {
	user-select: unset;
	align-self: flex-start;
	padding-left: 4px;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;

	&.rtl {
		direction: initial;
	}

	&.makePlace {
		max-width: 60%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.sideText {
	gap: 2px;
	justify-content: flex-start;
	@include body2();
	color: $grey600;
	font-weight: 400;
	font-size: 12px;
	display: flex;
	align-items: center;
	white-space: nowrap;
	position: absolute;

	&.top {
		width: max-content;
		top: 3px;
		left: 50%;
		transform: translateX(-50%);
	}
	&.bottom {
		width: max-content;
		bottom: 3px;
		right: 5px;
	}
	&.end {
		width: 75px;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	&.start {
		width: 75px;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.textInput {
	max-width: 90%;
	align-self: center;
	padding-left: 4px;
	all: unset;
	max-height: 100px;
}

.bottomWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
