@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';
.pageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: $blue100;
	overflow: auto;
}

.cardsContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1200px;
	padding: 24px 0;
	padding-inline-start: 16px;

	@include for-narrow-layout {
		max-width: 1190px;
	}

	.title {
		@include h5;
		margin-bottom: 32px;
	}
}

.mainInvestigationReportContainer {
	padding-top: 40px;
	display: flex;
	width: 100%;
	background-color: $white;
	justify-content: center;
	flex: 2;
	padding-inline-start: 16px;
}

.reportPageContainer {
	width: 100%;
	overflow-y: auto;
}

.tagInfoContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	max-width: 1370px;
	margin: auto;
	padding: 0 10px;

	@include for-narrow-layout {
		max-width: 1224px;
	}
}

.bodyContainer {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.locationInfoContainer {
	display: flex;
	flex-direction: column;
	width: calc(100% - $investigationReportTagRouteSectionWidth);
}

.locationInfoAndSvgSection {
	width: 100%;
	display: flex;
	flex-direction: row;
	background-color: $white;
	max-height: 1000px;
	border-radius: 8px;
	margin: 5px 0;
}

.tagsLocationInfoSection {
	border-inline-end: 1px solid $grey100;
}
