@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.tagsLocationDistributionContainer {
	height: 100%;
	display: flex;
	flex-direction: column;

	.statsContainer {
		display: flex;
		align-items: center;
		gap: 40px;
		padding: 8px 12px;
	}

	.aboveGraphSection {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.smallTitle {
			@include small();
			padding: 8px;
		}
	}

	.sortSection {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 8px 0px;

		.sortText {
			@include small();
		}
	}
}

.graphTitle {
	padding-bottom: 16px;
	@include subtitle-semi();
}
