@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.headerContainer {
	background: $white;
	padding: 0px 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	align-self: stretch;
	border-bottom: 1px solid $grey200;
	height: 56px;
	padding: 0px 96px;

	@include for-narrow-layout {
		padding: 16px 24px;
	}

	.headerContent {
		width: 100%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title {
			@include h6();
			&:focus {
				outline: none;
				text-decoration: underline;
			}
		}

		.descriptionSection {
			@include body2($grey500);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			max-width: 90%;

			&:focus {
				outline: none;
				text-decoration: underline;
			}
		}

		.actionTimeSection {
			padding: 8px 0;
			display: flex;
			flex-direction: column;
			align-self: flex-start;

			.dateSection {
				@include small-regular();
				display: flex;
				align-items: center;
				justify-content: flex-end;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				gap: 8px;
			}
		}
	}
}
