@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.GraphStat_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.icon {
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $grey50;
	border-radius: 50%;
}

.title {
	@include small-regular();
}

.value {
	@include h5();
}
