@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

$theme-spacing-unit: 8px; // Example value, adjust based on your theme settings

.UtilitiesChipsContainer {
	display: flex;
	align-items: center;
}

.iconAvatarWrapper {
	position: relative;
}

.iconContainer {
	margin-left: $theme-spacing-unit * 0.25;
	margin-right: $theme-spacing-unit * 0.25;
}

.icon {
	width: 20px;
	height: 20px;
}

.avatar {
	border-radius: 50%;
	box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.45);
	background-color: #fefefe;
	position: absolute;
	top: -$theme-spacing-unit * 1; // Adjusted for negative value
	left: $theme-spacing-unit * 2;
	height: 15px;
	width: 15px;
	z-index: 12;
	display: flex;
	align-items: center;
	justify-content: center;
}

.utilityIconText {
	@include small();
	color: #3b3b3b;
	text-align: center;
}
