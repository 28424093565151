@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.somethingWentWrongPageContainer {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;

	.infoContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 32px;
		width: 600px;

		.assetsContainer {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;

			.roadBlock {
				position: absolute;
				bottom: -32px;
				max-width: 32%;
			}

			.skyLine {
				max-width: 90%;
			}
		}

		.texts {
			display: flex;
			flex-direction: column;
			gap: 24px;
			margin-top: 30px;
			padding: 0px 12px;

			.title {
				@include h4();
				text-align: center;
			}
			.description {
				@include body1($grey600);
				text-align: center;
				white-space: pre-line;
			}
		}
	}
}

.refreshIcon {
	margin-inline-end: 8px;
}
