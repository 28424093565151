@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.topSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.saveButton {
	@include button-large();
}

.switcherContainer {
	width: 100%;
	padding: 0px 16px;
	margin-bottom: 8px;
	background-color: $white;
	position: sticky;
	top: 0;
	z-index: 1;
}
