@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.milestoneTitleText {
	font-size: 12px;
	padding: 1px 0;

	&.dark {
		color: $white;
	}
}

.rowContainer {
	display: flex;
	flex-direction: column;

	.row {
		display: flex;
		align-items: center;
	}

	.separateLineContainer {
		min-height: 34px;
		position: relative;

		.separateLine {
			position: absolute;
			inset-inline-start: 20px;
		}
	}
}

.iconContainer {
	margin: 0 8px;
	position: relative;

	.milestoneIndex {
		position: absolute;
		top: 50%;
		inset-inline-start: 50%;
		transform: translate(-50%, -50%);
		@include small();

		&.dark {
			color: $white;
		}

		&.rtl {
			inset-inline-start: unset;
			inset-inline-end: 50%;
		}
	}
}

.markCompletedContainer {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	cursor: pointer;
	width: 110px;

	&.futureMilestone {
		pointer-events: none;
	}

	.markCompletedButton {
		padding: 4px 5px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.dark {
			background-color: $grey800;
		}
	}

	img {
		margin-inline-end: 6px;
	}

	.markCompletedText {
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		&.futureMilestone {
			text-decoration: line-through;
		}
	}
}
