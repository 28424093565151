@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.chipsContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	margin-top: 8px;
}

.chipText {
	font-size: 12px;
}

.endAdornment {
	top: 5px !important;
}

.selectLocationText {
	@include body2();
	margin: 0;
	margin-inline-start: 3px;
}

.tagsContainer {
	@include small();
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.locationSelect {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}
