@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.listContainer {
	margin-top: 4px;
	width: 100%;
	height: 100%;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: $white;
	overflow: hidden;
}

.title {
	justify-content: center;
	width: 100%;
	@include h6;

	@include for-narrow-layout {
		font-size: 14px !important;
	}
}

.listWrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.headerContainer {
	display: grid;
	grid-template-columns: repeat(20, 1fr);
	align-items: center;
	width: calc(100% - 24px);
	margin-inline-start: 12px;
	border-bottom: 1px solid $grey100;
}

.header {
	height: 26px;
	flex-wrap: nowrap;
	display: flex;
	margin-top: 7px;
	margin-left: 12px;
}

.headerText {
	@include body2;
}

.headerTextWrapper {
	min-height: 20px;
	text-align: center;
}

.rowsContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100% - 42px);
	overflow-y: auto;
	flex-wrap: nowrap;
}

.searchAndButtonContainer {
	height: 48px;
	width: 100%;
	margin-bottom: 8px;
}

.arrow {
	width: 14px;
	height: 14px;
}

.tableContainer {
	padding-inline-start: 10px;
	padding-inline-end: 20px;
	flex-wrap: nowrap !important;
	height: 99%;
}
