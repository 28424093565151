@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.baseCell {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@include small();
	cursor: pointer;
	position: relative;

	.menuDots {
		transform: rotate(90deg);
		position: absolute;
		top: 8px;
		right: 5px;
		box-shadow: 0 0 4px rgba(17, 24, 39, 0.16);
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: ease-in-out 0.2s;
		padding: 2px;

		&.isHover {
			background-color: $blue100;
		}

		img {
			width: 12px;
			height: 12px;
		}
	}

	.issuesIcon {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 4px;
		right: 5px;
	}

	.multiSelectionCheckbox {
		position: absolute;
		top: 8px;
		left: 5px;
		width: 16px;
		height: 16px;
		border: 1px solid $grey600;
		border-radius: 2px;
		box-sizing: border-box;

		&.isHover {
			background-color: $blue100;
		}
	}

	.multiSelectionCheckboxSelected {
		background-color: $darkBlue;
		top: 7px;

		&.isHover {
			background-color: $grey600;
		}
	}

	.chipMenu {
		position: absolute;
		margin-top: 2px;
		z-index: 9999999;
	}
}

.icon {
	width: 16px;
	height: 16px;
}

.hideComponent {
	visibility: hidden;
}
.cellTextContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.multiSelectedCell {
	border: 1px solid $darkBlue !important;
	background-color: $blue100 !important;
	color: $darkBlue !important;
}

.checkIcon {
	width: 100%;
	height: 100%;
	filter: unset !important;
}

.inProgress {
	background-color: $yellow500;
	color: $grey600;

	&.isHover {
		background-color: $yellow300;
	}

	img {
		filter: invert(36%) sepia(11%) saturate(677%) hue-rotate(174deg) brightness(100%) contrast(89%);
	}
}

.done {
	background-color: $feedbackPositiveDefault;
	color: $white;

	&.isHover {
		color: $white;
		background-color: $green300;
	}
}

.unscheduled {
	background-color: $white;
	color: $grey600;

	&.isHover {
		color: $grey600;
	}

	img {
		filter: invert(94%) sepia(6%) saturate(139%) hue-rotate(175deg) brightness(90%) contrast(90%);
	}
}

.readyForReview {
	background-color: $feedbackHighDefault;
	color: $white;

	&.isHover {
		color: $grey600;
	}
}

.scheduleModeUnscheduled {
	background-color: $white;

	&.isHover {
		color: $grey200;
		border: 2px solid $blue300;
	}

	img {
		filter: invert(94%) sepia(6%) saturate(139%) hue-rotate(175deg) brightness(90%) contrast(90%);
	}
}

.upComing {
	background-color: $grey50;
	color: $grey600;

	&.isHover {
		color: $grey600;
		background-color: $grey100;
	}

	img {
		filter: invert(53%) sepia(6%) saturate(778%) hue-rotate(173deg) brightness(88%) contrast(90%);
	}
}

.overdue {
	background-color: $feedbackCriticalDefault;
	color: $white;

	&.isHover {
		color: $grey600;
		background-color: $feedbackCriticalMedium;
	}

	img {
		filter: invert(36%) sepia(11%) saturate(677%) hue-rotate(174deg) brightness(100%) contrast(89%);
	}
}

.delayed {
	background-color: $feedbackCriticalDefault;
	color: $white;

	&.isHover {
		color: $grey600;
		background-color: $feedbackCriticalMedium;
	}

	img {
		filter: invert(36%) sepia(11%) saturate(677%) hue-rotate(174deg) brightness(100%) contrast(89%);
	}
}

.checkedForActivityCreation {
	background-color: $blue300;
}

.disableHover {
	pointer-events: none;
}

.isReadyForReviewCell {
	&:not(.multiSelectedCell) {
		.isHover {
			background-color: $feedbackHighMedium !important;
		}
		&.isLimitedUser {
			&.isHover {
				background-color: $feedbackMediumMedium !important;
			}
		}
	}
}

.stripes {
	background-image: url('../../../../../../../assets/workplanIcons/disabledMatrixCellBackgroud.svg');
	height: 100%;
}
