@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.FloorProgressGraph_container {
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid $grey100;
}

.row {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $grey100;
}

.floorNick {
	@include small($grey500);
	min-width: 110px;
	padding: 8px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.bar {
	display: flex;
	width: 100%;
}

.barCompleted {
	background: $blue100;
}

.barCompletedInPeriod {
	background: $blue300;
}

.completedPercentage {
	@include small;
	padding: 8px;
}

.periodPercentageAdded {
	display: flex;
	align-items: center;
	direction: ltr;
	@include button-small($blue300);
	padding: 8px;
}
