@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.switcherContainer {
	@include small-regular($white);
	background-color: $grey100;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px;
	border-radius: 20px;
	width: 100%;
	cursor: pointer;
	text-transform: capitalize;
	cursor: pointer;

	.active {
		border-radius: 15px;
		background-color: $white;
		@include button-large($black);
		padding: 8px 8px 6px 8px;
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.inActive {
		display: flex;
		justify-content: center;
		align-items: center;
		@include button-large($grey500);
		width: 50%;
		border-radius: 15px;
		padding: 8px 8px 6px 8px;
	}
}
