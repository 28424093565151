@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.createCardContainer {
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	.clickable {
		cursor: pointer;
	}

	.details {
		display: flex;
		flex-direction: column;
		margin-top: 8px;

		.inputContainer {
			border: none;
			padding: 0;
		}

		.titleInputText {
			@include subtitle-semi();
			height: 20px;
			color: $darkBlue;
		}

		.descriptionInputText {
			@include body2();
			color: $grey400;
			height: 20px;
		}

		.title {
			@include subtitle-semi();
			height: 24px;
		}

		.description {
			@include body2();
			color: $grey400;
			height: 24px;
		}
	}

	.metadataSection {
		border-top: 1px solid $grey100;
		border-bottom: 1px solid $grey100;
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 8px 0px;

		.inputContainer {
			height: 42px;
			position: relative;
			bottom: 10px;
		}

		.metadataLine {
			display: flex;
			gap: 4px;
			align-items: center;
			padding: 4px 16px 4px 8px;
		}

		.text {
			@include small($grey400);
			margin: 0;
		}
	}

	.buttonsContainer {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
	}

	.cursorPointer {
		cursor: pointer;
	}
}

.issueLocationSelectorContainer {
	position: absolute;
	width: 480px;
	background-color: white;
	z-index: 100;
}
