@import '@shared/styles/mixins/media_queries';

.svgParentContainer {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;

	.fullSize {
		width: 350px;

		@include for-narrow-layout {
			width: 200px;
		}
	}
}
