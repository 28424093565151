@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.wrapper {
	width: 100%;
	@include small-regular;
	border-radius: 8px;
	border: 1px solid $grey200;
}

.wr {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-bottom: 1px solid $grey200;
}

.tagName {
	font-weight: 500;
}

.tagsSection {
	flex-wrap: wrap;
	gap: 10px;
	padding: 8px 16px;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.tag {
	display: flex;
	gap: 4px;
	flex-direction: row;
	align-items: center;
}
