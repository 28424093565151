@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.timelineContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 24px 0 36px 0;
}

.timelineContainerRTL {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 24px 0 36px 0;
}

.timelineItem {
	display: flex;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	height: 68px;
	padding-left: 21px;
}

.timelineItemRTL {
	display: flex;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	height: 68px;
	padding-right: 21px;
}

.selectedBackground {
	background-color: $blue100;
}

.noBorderBottom {
	border-bottom: none !important;
}

.timelineItemContent {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 12px 8px;
	width: 260px;
	text-align: right;
	border-bottom: $grey200 1px solid;
}

.line {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.lineUnit {
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.rangeTime {
	@include small-regular();
	margin-right: 4px;
}

.lightText {
	@include small-regular();
}

.boldText {
	@include small();
}

.bigBoldText {
	@include link();
}

.circle {
	background-color: $grey100;
	border-radius: 50%;
	position: absolute;
	top: calc(50% - 10px);
	left: -7px;
	width: 16px;
	height: 16px;
	z-index: 100;
	@include small();
	font-size: 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 0;
}

.circleRTL {
	background-color: $grey100;
	border-radius: 50%;
	position: absolute;
	top: calc(50% - 10px);
	width: 16px;
	height: 16px;
	z-index: 100;
	@include small();
	font-size: 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	right: -7px;
}

.circleDark {
	background-color: $grey300;
}

.circleSelected {
	background-color: $primaryColor;
	color: $white;
}

.timelineContainer::after {
	background-color: $grey100;
	content: '';
	position: absolute;
	left: 0;
	top: 36px;
	width: 2px;
	height: calc(100% - 68px);
}

.timelineContainerRTL:after {
	background-color: $grey100;
	content: '';
	position: absolute;
	right: 0;
	top: 36px;
	width: 2px;
	height: calc(100% - 68px);
}
