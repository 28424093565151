@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/media_queries.scss';

.SequenceProgressAnalysis_container {
	background: $white;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.content {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0 96px;
	height: 100%;
	overflow: hidden;

	@include for-narrow-layout {
		padding: 0 24px;
	}
}

.leftSection {
	max-height: calc(100vh - 150px);
	overflow: auto;
	display: flex;
	flex-direction: column;
	min-width: 500px;

	@include for-narrow-layout {
		min-width: 400px;
	}
}

.divider {
	min-width: 1px;
	height: 100vh;
	background: $grey200;
}

.rightSection {
	display: flex;
	flex-direction: column;
	width: calc(100% - 501px);
	overflow: auto;

	@include for-narrow-layout {
		width: calc(100% - 401px);
	}
}
