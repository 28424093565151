@import '../../../styles/variables';
@import '../../../styles/mixins/media_queries';
@import '../../../styles/mixins/text';

.greyBlock {
	border-radius: 16px;
	background-color: $grey50;
	padding: 16px;
	gap: 8px;
	display: flex;
	flex-direction: column;

	.sender {
		@include button-small($grey700);
	}

	.pdfViewContainer {
		cursor: pointer;
		background-color: $white;
		display: flex;
		gap: 8px;
		height: 56px;
		padding: 8px;
		border-radius: 8px;

		.pdfIcon {
			height: 40px;
			width: 40px;
			padding: 8px;
			background-color: $primaryColor;
			border-radius: 8px;
		}

		.pdfDetails {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			overflow: hidden;

			.pdfName {
				@include cut-line(1);
				@include button-small($grey700);
			}

			.pdfSize {
				@include body2($grey600);
			}
		}
	}

	.footer {
		display: flex;
		justify-content: space-between;
		flex-direction: row;

		.hour {
			@include small($grey700);
		}
	}
}
