@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/mixins/_text.scss';

.container {
	height: 100%;
	position: absolute;
	z-index: 20;
	background: white;
	top: 0;
	inset-inline-start: 0;
	min-width: $leftSideViewWith;
	max-width: $leftSideViewWith;
	box-shadow: $componentShadow;
	transition: inset-inline-start 0.7s;

	@include for-narrow-layout {
		min-width: $narrowLeftSideViewWidth;
		max-width: $narrowLeftSideViewWidth;
	}
}

.notDisplay {
	inset-inline-start: -600px;
}
