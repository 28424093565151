$hebrew-unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
$latin-unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
	U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
$jpn-unicode-range: U+3000-303f, U+3040-309f, U+30a0-30ff, U+ff00-ff9f, U+4e00-9faf;

/* Heebo*/
/* hebrew */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Thin.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}
/* latin */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Thin.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/Heebo-ExtraLight.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}
/* latin */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/Heebo-ExtraLight.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Light.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Light.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Regular.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Regular.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Medium.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Medium.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Bold.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/Heebo-Bold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/Heebo-ExtraBold.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/Heebo-ExtraBold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* OUTFIT*/
/* hebrew */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Thin.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Thin.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/Outfit-ExtraLight.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/Outfit-ExtraLight.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Light.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Light.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Regular.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Regular.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Medium.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Medium.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Bold.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Bold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/Outfit-ExtraBold.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/Outfit-ExtraBold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Noto */
/* hebrew */
@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-ExtraLight.woff2) format('woff2');
}

@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Light.woff2) format('woff2');
}
@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Thin.woff2) format('woff2');
}
@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Medium.woff2) format('woff2');
}
@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Medium.woff2) format('woff2');
}
@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Regular.woff2) format('woff2');
}
@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-SemiBold.woff2) format('woff2');
}
@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Bold.woff2) format('woff2');
}

@font-face {
	font-family: 'Noto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-ExtraBold.woff2) format('woff2');
}

//Noto Sans
/* weight 400 */
/* latin */
@font-face {
	font-family: 'NotoSans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/NotoSans-Regular.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

//TrusstorFont

/* weight 100 */
/* hebrew */
@font-face {
	font-family: 'TrusstorFontHeb';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-ExtraLight.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}
/* latin */
@font-face {
	font-family: 'TrusstorFontEng';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/Outfit-ExtraLight.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

@font-face {
	font-family: 'TrusstorFontJpn';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/NotoSansJP-ExtraLight.woff2) format('woff2');
	unicode-range: $jpn-unicode-range;
}

/* weight 200 */
/* hebrew */
@font-face {
	font-family: 'TrusstorFontHeb';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Light.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}
/* latin */
@font-face {
	font-family: 'TrusstorFontEng';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Light.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

@font-face {
	font-family: 'TrusstorFontJpn';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/NotoSansJP-Light.woff2) format('woff2');
	unicode-range: $jpn-unicode-range;
}

/* weight 300 */
/* hebrew */
@font-face {
	font-family: 'TrusstorFontHeb';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Thin.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}
/* latin */
@font-face {
	font-family: 'TrusstorFontEng';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Thin.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

@font-face {
	font-family: 'TrusstorFontJpn';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/NotoSansJP-Thin.woff2) format('woff2');
	unicode-range: $jpn-unicode-range;
}

/* weight 400 */
/* hebrew */
@font-face {
	font-family: 'TrusstorFontHeb';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Regular.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}
/* latin */
@font-face {
	font-family: 'TrusstorFontEng';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Regular.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

@font-face {
	font-family: 'TrusstorFontJpn';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/NotoSansJP-Medium.woff2) format('woff2');
	unicode-range: $jpn-unicode-range;
}
/* weight 500 */
/* hebrew */
@font-face {
	font-family: 'TrusstorFontHeb';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Medium.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}
/* latin */
@font-face {
	font-family: 'TrusstorFontEng';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Medium.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

@font-face {
	font-family: 'TrusstorFontJpn';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/NotoSansJP-Regular.woff2) format('woff2');
	unicode-range: $jpn-unicode-range;
}

/* weight 600 */
/* hebrew */
@font-face {
	font-family: 'TrusstorFontHeb';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-SemiBold.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}
/* latin */
@font-face {
	font-family: 'TrusstorFontEng';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(../assets/fonts/Outfit-SemiBold.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

@font-face {
	font-family: 'TrusstorFontJpn';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(../assets/fonts/NotoSansJP-SemiBold.woff2) format('woff2');
	unicode-range: $jpn-unicode-range;
}
/* weight 700 */
/* hebrew */
@font-face {
	font-family: 'TrusstorFontHeb';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-Bold.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}

/* latin */
@font-face {
	font-family: 'TrusstorFontEng';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/Outfit-Bold.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

@font-face {
	font-family: 'TrusstorFontJpn';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/NotoSansJP-Bold.woff2) format('woff2');
	unicode-range: $jpn-unicode-range;
}

/* weight 800 */
/* hebrew */
@font-face {
	font-family: 'TrusstorFontHeb';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/NotoSansHebrew-ExtraBold.woff2) format('woff2');
	unicode-range: $hebrew-unicode-range;
}

/* latin */
@font-face {
	font-family: 'TrusstorFontEng';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/Outfit-ExtraBold.woff2) format('woff2');
	unicode-range: $latin-unicode-range;
}

@font-face {
	font-family: 'TrusstorFontJpn';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/NotoSansJP-ExtraBold.woff2) format('woff2');
	unicode-range: $jpn-unicode-range;
}
