.svgDiv {
	display: flex;
	align-items: center;
	justify-content: center;
	height: fit-content;

	&.extraSmall {
		height: 16px;
		width: 16px;

		svg {
			height: 16px;
			width: 16px;
			stroke-width: 1.5px;
		}
	}

	&.small {
		height: 20px;
		width: 20px;

		svg {
			height: 20px;
			width: 20px;
			stroke-width: 1.5px;
		}
	}

	&.medium {
		height: 24px;
		width: 24px;

		svg {
			height: 24px;
			width: 24px;
			stroke-width: 1.5px;
		}
	}

	&.large {
		height: 32px;
		width: 32px;

		svg {
			height: 32px;
			width: 32px;
			stroke-width: 2px;
		}
	}

	&.extraLarge {
		height: 56px;
		width: 56px;

		svg {
			height: 56px;
			width: 56px;
			stroke-width: 2.5px;
		}
	}

	&.grey800 {
		svg {
			filter: invert(16%) sepia(9%) saturate(2398%) hue-rotate(174deg) brightness(97%) contrast(89%);
		}
	}

	&.grey700 {
		svg {
			filter: invert(24%) sepia(16%) saturate(949%) hue-rotate(172deg) brightness(96%) contrast(86%);
		}
	}

	&.grey600 {
		svg {
			filter: invert(36%) sepia(55%) saturate(156%) hue-rotate(173deg) brightness(88%) contrast(84%);
		}
	}

	&.grey500 {
		svg {
			filter: invert(70%) sepia(11%) saturate(411%) hue-rotate(160deg) brightness(94%) contrast(96%);
		}
	}

	&.grey400 {
		svg {
			filter: invert(62%) sepia(7%) saturate(471%) hue-rotate(173deg) brightness(90%) contrast(92%);
		}
	}

	&.grey300 {
		svg {
			filter: invert(83%) sepia(4%) saturate(331%) hue-rotate(173deg) brightness(90%) contrast(93%);
		}
	}

	&.grey200 {
		svg {
			filter: invert(86%) sepia(5%) saturate(174%) hue-rotate(174deg) brightness(103%) contrast(80%);
		}
	}

	&.grey100 {
		svg {
			filter: invert(100%) sepia(1%) saturate(3469%) hue-rotate(174deg) brightness(100%) contrast(85%);
		}
	}

	&.white {
		svg {
			filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(58deg) brightness(104%) contrast(103%);
		}
	}

	&.blue {
		svg {
			filter: invert(34%) sepia(10%) saturate(4945%) hue-rotate(173deg) brightness(93%) contrast(81%);
		}
	}

	&.blue200 {
		svg {
			filter: invert(76%) sepia(18%) saturate(1168%) hue-rotate(157deg) brightness(99%) contrast(111%);
		}
	}

	&.blue500 {
		svg {
			filter: invert(36%) sepia(32%) saturate(1332%) hue-rotate(169deg) brightness(88%) contrast(83%);
		}
	}

	&.red900 {
		svg {
			filter: invert(27%) sepia(92%) saturate(1840%) hue-rotate(344deg) brightness(82%) contrast(98%);
		}
	}

	&.red500 {
		svg {
			filter: invert(58%) sepia(47%) saturate(602%) hue-rotate(314deg) brightness(107%) contrast(107%);
		}
	}

	&.feedbackCriticalDark {
		svg {
			filter: invert(34%) sepia(67%) saturate(3123%) hue-rotate(333deg) brightness(90%) contrast(97%);
		}
	}

	&.feedbackCriticalDefault {
		svg {
			filter: invert(60%) sepia(100%) saturate(987%) hue-rotate(312deg) brightness(104%) contrast(111%);
		}
	}

	&.feedbackPositiveDark {
		svg {
			filter: invert(67%) sepia(50%) saturate(3376%) hue-rotate(38deg) brightness(90%) contrast(81%);
		}
	}

	&.feedbackHighDark {
		svg {
			filter: invert(73%) sepia(38%) saturate(7495%) hue-rotate(6deg) brightness(102%) contrast(91%);
		}
	}

	&.yellow500 {
		svg {
			filter: invert(58%) sepia(93%) saturate(963%) hue-rotate(11deg) brightness(102%) contrast(103%);
		}
	}

	&.darkGreen {
		svg {
			filter: invert(63%) sepia(35%) saturate(3822%) hue-rotate(40deg) brightness(93%) contrast(81%);
		}
	}

	&.green500 {
		svg {
			filter: invert(80%) sepia(21%) saturate(793%) hue-rotate(69deg) brightness(96%) contrast(88%);
		}
	}

	&.black {
		svg {
			filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(0deg) brightness(104%) contrast(104%);
		}
	}

	&.darkBlue {
		svg {
			filter: invert(12%) sepia(15%) saturate(1182%) hue-rotate(173deg) brightness(95%) contrast(89%);
		}
	}
}

.filledIcon {
	:first-child {
		fill: inherit;
	}
}
