@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.SequenceLinkTableContainer_container {
	border-inline-start: 1px solid $grey200;
	width: 100%;
	padding: 16px;
}
.tableTitle {
	@include subtitle-semi();
}

.tableContainerHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	margin-bottom: 8px;
}

.rightPart {
	display: flex;
	align-items: center;
	gap: 16px;
}

.editButton {
	gap: 4px;
	padding-inline-start: 8px !important;
	padding-inline-end: 16px !important;
}

.leftContainer {
	align-items: center;
	display: flex;
	gap: 24px;
}

.buttons {
	display: flex;
	gap: 8px;
}
