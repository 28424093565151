@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.appContainer {
	height: calc(100% - $topBarHeight);
	width: calc(100% - $sideBarWidth);
	position: fixed;
	top: $topBarHeight;
	left: $sideBarWidth;
	display: flex;
	background: $grey50;

	&.isRtl {
		left: unset;
		right: $sideBarWidth;
	}

	@include for-narrow-layout {
		width: calc(100% - $narrowSideBarWidth);
		left: $narrowSideBarWidth;
		&.isRtl {
			left: unset;
			right: $narrowSideBarWidth;
		}
	}
}

.fullAppContainer {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	background: $grey50;

	&.isRtl {
		left: unset;
		right: 0;
	}

	@include for-narrow-layout {
		width: 100%;
		left: 0;
		&.isRtl {
			left: unset;
			right: 0;
		}
	}
}

.noProjectSelector {
	top: 0;
	height: 100%;
	background-color: $white;
}
