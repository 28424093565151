@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/_variables.scss';

.emptyFirstCell {
	padding: 1px !important;
	width: 120px;
	min-width: 120px;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.emptyRow {
	max-height: 330px;
}

.noMatchText {
	@include body2($primaryColor);
}

.emptyWorkplanContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	height: 250px;
	margin-top: 60px;
}

.iconContainer {
	display: flex;
	justify-content: center;
	background: #f8f8f8;
	border-radius: 50%;
	width: 90px;
	height: 90px;
}

.emptyWorkplanIcon {
	width: 57px;
}
