@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.scroll-arrow-container {
	height: 20px;
	width: 20px;
	align-self: center;
}

.rows-container {
	overflow: auto;
	padding-bottom: 4px;
}

.wrapper {
	width: 100%;
	height: 100%;
}

.workplan-widget-container-header {
	margin: 0;
}

.slide-container {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding: 16px;
}

.widgetContainer {
	display: flex;
	flex-direction: column;

	.widgetBody {
		background-color: $grey50;
		padding: 10px 16px;
		padding-inline-end: 6px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		height: 100%;

		.emptyStateContainer {
			padding: 72px 0;
			@include small-regular;
			text-align: center;
		}
	}
}
