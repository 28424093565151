@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.topContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: center;
	border-radius: 8px 8px 0 0;
	height: 215px;
	background-size: cover !important;
	transition: background-image 5s ease-in-out;
}

.infoSection {
	padding: 0 8px 14px 8px;
}

.redirectSection {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.redirectLink {
	@include subtitle-semi();
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	border-radius: 8px;
	width: 144px;
	height: 40px;

	&.dark {
		border: 1px solid $white;
		color: $white;
	}
}

.projectName {
	@include h5();

	&.dark {
		color: $white;
	}
}

.projectLocation {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.locationIcon {
	width: 17px;
	height: 17px;
	margin-right: 4px;
}

.locationName {
	@include small();

	&.dark {
		color: $white;
	}
}
