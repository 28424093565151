@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.milestonesContainer {
	border-radius: 8px;
	margin: 5px;
	padding: 16px;
	min-height: 149px;

	&.dark {
		background-color: #384758;
	}

	.lastUpcomingContainer {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: 8px;
		border: 1px solid #878f99;
		border-radius: 8px;
		min-height: 145px;
		@include small();
		margin-top: 36px;
		position: relative;

		&.dark {
			color: #b7bcc2;
		}

		.expandIcon {
			margin: 5px;
			position: absolute;
			top: 0;
			inset-inline-end: 0;
			cursor: pointer;
		}
	}
}

.dialogRootStyle {
	width: 31%;
	padding: unset;
	background-color: $grey700 !important;
	overflow: scroll !important;
	overflow-x: hidden !important;
	max-height: calc(100vh - 100px) !important;
}

.mobileScreenDialogRootStyle {
	width: 90%;
	padding: unset;
	background-color: $grey700 !important;
	overflow: scroll !important;
	overflow-x: hidden !important;
	max-height: calc(100vh - 100px) !important;
}
