// Breakpoints
$break-narrow: 1600px;
$break-narrow-height: 800px;
$break-small: 1200px;
$break-small-height: 700px;
$break-mobile: 768px;
$break-mobile-keyboard-up: 750px;

// responsive constants
$leftSideViewWith: 516px;
$narrowLeftSideViewWidth: 390px;
$sideMenuWith: 34%;
$narrowSideMenuWidth: 28%;
$topBarHeight: 40px;
$tableHeightWithoutHeader: calc(100% - 60px);
$sideBarWidth: 75px;
$narrowSideBarWidth: 63px;
$sideMainComponentWidth: 300px;
$investigationReportTagRouteSectionWidth: 325px;
$dashboardTabsSectionHeight: 85px;
$monitorHeaderHeight: 85px;

// new standard colors
$darkBlue: #212a35;
$white: #ffffff;
$black: #000000;
$blue50: #f6fafd;
$blue100: #eaf3fb;
$blue200: #68e4ff;
$blue300: #2ebffd;
$brightTurquoise: #2cffcb;
$yellow: #ffc700;
$grey50: #f4f7f8;
$grey100: #e7e9eb;
$grey200: #cfd2d6;
$grey300: #b7bcc2;
$grey400: #878f99;
$grey500: #6f7985;
$grey600: #576270;
$grey700: #384758;

// Primary Color
$primaryColor: $darkBlue;

// Feedback colors
$green100: #cdf3cd;
$green300: #a2e7a2;
$green400: #73d973;
$green600: #7aa418;

$feedbackPositiveLight: $green100;
$feedbackPositiveMedium: $green300;
$feedbackPositiveDefault: $green400;
$feedbackPositiveDark: #7aa418;

$yellow100: #fff9d9;
$yellow300: #fff3b2;
$yellow500: #ffe658;
$yellow600: #f9bc15;

$feedbackMediumLight: $yellow100;
$feedbackMediumMedium: $yellow300;
$feedbackMediumDefault: $yellow500;
$feedbackMediumDark: $yellow600;

$feedbackHighLight: #ffe6c9;
$feedbackHighDefault: #feb953;
$feedbackHighMedium: #fbcd98;
$feedbackHighDark: #e8900c;

$red100: #ffdbd9;
$red300: #ffcac7;
$red400: #ff837a;
$red600: #e23749;
$red900: #cf3126;

$feedbackCriticalLight: $red100;
$feedbackCriticalMedium: $red300;
$feedbackCriticalDefault: $red400;
$feedbackCriticalDark: $red600;

// Trades Colors
$spaceCadet: #4d0068;
$azure: #004fc6;
$blueSky: #4e9fe5;
$beetrootPurple: #cf2d71;
$fandango: #a5398d;
$africanViolet: #b990c2;
$freshPurple: #d260e1;
$pinkSherbet: #f8839f;
$orange: #ffa253;
$lightSeaGreen: #14a4a4;
$supremeTurquoise: #19cbdc;
$cerise: #98001b;

// Colors
$disabledGray: rgba(16, 48, 69, 0.5); // this will be changed with the inputs design system

// Dark theme Special Colors
$blue900: #0f1f33;
$grey800: #273547;

// General style
$componentBorder: 2px solid rgba(16, 48, 69, 0.2);
$componentShadow:
	-2px 0px 6px 1px rgba(128, 128, 128, 0.2),
	0px -8px 4px 1px rgba(128, 128, 128, 0.2);
$sideBarFilterShadow:
	2px 0px 6px 1px rgba(128, 128, 128, 0.2),
	0px -8px 4px 1px rgba(128, 128, 128, 0.2);
$regularBorder: 0.2px solid $grey100;
$tableBorderRight: 2px solid $grey100;
$executivePageTopBarHeight: 72px;

// Input style
$inputBorder: 1px solid $grey300;
$inputHoverBorder: 1px solid $grey400;
$inputFocusBorder: 1px solid $primaryColor;

// chips style
$activityChipHeight: 20px;

// Contractor analysis
$contractorAnalysisHeader: 120px;
