@import '@shared/styles/variables';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/mixins/text';

.chipContainer {
	border-radius: 4px;
	border: 1px solid $grey700;
	display: flex;
	flex-direction: row;
	background-color: $white;
	align-items: center;
	gap: 2px;
	justify-content: space-between;
	min-width: 48px;
	margin: 0 2px;
	max-width: 220px;

	&.small {
		min-width: 38px;

		.number {
			width: 12px;
			height: 12px;
			min-width: 12px;
		}
	}

	.number {
		@include small();
		border-radius: 4px;
		background-color: $primaryColor;
		color: $white;
		margin: 2px;
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 16px;
		height: 16px;
		min-width: 16px;
	}

	.description {
		@include small($grey600);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-inline-end: 4px;
	}
}
