.avatar {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.deleteButtonWrapper {
	display: flex;
	gap: 17px;
	margin-inline-end: 18px;

	& > div {
		display: flex;
		align-items: center;
	}
}
