@import '../../../../../styles/variables';
@import '../../../../../styles/mixins/text';

.SimpleDropdown_container {
	width: 100%;
	position: relative;
}

.SimpleDropdown_options {
	max-height: calc(40vh - 100px);
	overflow-y: auto;
	box-shadow: 0 0 16px 0 #0000001f;
	background: $white;
	width: 100%;
	position: absolute;
	top: 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px;
	z-index: 100000;
}

.footer {
	width: 100%;
	border-top: 1px solid $grey200;
	position: sticky;
	bottom: 0;
	background: $white;
	z-index: 1;
}
