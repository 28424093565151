@import '../../styles/variables';
@import '../../styles/mixins/media_queries';
@import '../../styles/mixins/text';

.container {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.greyBlock {
		border-radius: 16px;
		background-color: $grey50;
		padding: 16px;
		gap: 8px;
		display: flex;
		flex-direction: column;

		.sender {
			@include button-small($grey700);
		}

		.textComment {
			@include body2($grey700);
			word-break: break-word;
		}

		.showLess {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
		}

		.footer {
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.hour {
				@include small($grey700);
			}

			.showMore {
				@include link($darkBlue);
				text-decoration-line: underline;
				cursor: pointer;
			}
		}
	}
}
