@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';
.appBar {
	background-color: $white;
}

.right {
	right: 0;
}

.left {
	left: 0;
}

.toolbar {
	display: flex;
	justify-content: center;
	background-color: $white;
	padding: 0 16px !important;
	position: fixed !important;
	z-index: 8;
	width: calc(100% - $sideBarWidth);
	min-height: $topBarHeight !important;
	max-height: $topBarHeight !important;
	border-bottom: 1px solid $grey100;
	@include for-narrow-layout {
		width: calc(100% - $narrowSideBarWidth);
	}
}

.projectsSelectorContainer {
	display: flex;
	width: 500px;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
}

.logosContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}

.trusstorLogo {
	height: 26px;
	width: 26px;
}

.projectLogo {
	height: 28px;
	width: auto;
	margin-inline-end: 20px;
}

.versionText {
	@include small($primaryColor);
	margin-inline-end: 12px;
}

.versionWeather {
	display: flex;
	align-items: center;
}

.noProjectSelector {
	justify-content: end;
	width: auto;
	border-bottom: none;
}
