@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.list {
	position: fixed !important;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding-top: 0 !important;
	background-color: $primaryColor;
	height: 100dvh;
	width: $sideBarWidth;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	z-index: 10;
	@include for-narrow-layout {
		width: $narrowSideBarWidth;
	}
}

.iconButtonImg {
	width: 26px;
	height: 26px;
}

.lastItemContainer {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end !important;
}

.iconContainer {
	border-radius: 4px;
	width: 32px;
	height: 32px;
	position: relative;

	&:hover {
		background-color: $grey700;
	}

	&.isSelected {
		background-color: $grey700;
	}

	.dot {
		width: 10px;
		height: 10px;
		position: absolute;
		top: 3px;
		inset-inline-end: 5px;
		z-index: 10;
		background-color: $yellow;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		.dotText {
			@include text;
			color: $white;
			font-size: 8px;
			font-weight: 600;
			margin-inline-start: 2px;
			margin-top: 2px;
		}
	}
}

.iconRootClass {
	&:hover {
		border-radius: 8px !important;
		width: 32px;
		height: 32px;
	}
}

.sideBarIcon {
	width: 36px;
	height: 36px;
	border-radius: 4px;
	padding: 2px;

	&:hover {
		background-color: rgba(38, 68, 87);
	}
}

.profileIcon {
	position: relative;

	&:hover {
		background: $grey700;
	}

	&.isSelected {
		background: $grey700;
	}
}

.sideBarItem {
	width: 98%;
}

.text {
	@include small-regular($white);
}

.menuContainer {
	display: flex;
	align-self: flex-start;
	width: 100%;
}

.logoutContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 10px;
}

.listContainer {
	position: absolute;
	top: 0;
	left: 50px;
}

.logo {
	padding: 16px 0px 35px 0px;
}

.notificationIcon {
}

.notificationInMenu {
	display: flex;
	align-items: center;
	gap: 8px;

	svg {
		width: 24px;
		height: 24px;
	}
}

.textInExpandMenu {
	@include small($grey200);
}
