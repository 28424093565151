@import '@shared/styles/variables';
.pageContainer {
	flex-wrap: unset;

	.pageContent {
		display: flex;
		height: 100%;
	}

	.pageDashboardContainer {
		min-width: 300px;
		max-width: 300px;
		flex-wrap: nowrap;
		overflow-y: auto;
		background: $white;
		box-shadow: $sideBarFilterShadow;
	}
}
