@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	gap: 16px;
	height: 100%;
	border: 1px solid $grey200;
}

.header {
	display: flex;
	height: 130px;
	align-items: center;
	align-self: stretch;
	border-bottom: 1px solid $grey200;
	border-inline-end: 1px solid $grey200;
}

.colHeader {
	height: 100%;
	width: 150px;
	border-inline-end: 1px solid $grey200;
	border-inline-start: none;
	position: sticky;
	top: 0;
	padding: 0;
	background-color: $white;

	z-index: 10000;
}

.floorsTitle {
	display: flex;
	justify-content: center;
	align-items: center;
	position: sticky;
	top: -3px;
	inset-inline-start: 0;
	z-index: 20000;
	img {
		margin: 0 4px;
	}
	.floorsText {
		@include body2();
		font-size: 14px;
		font-weight: 600;
	}
}
