@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.listContainer {
	width: 100%;
	min-height: 435px;
	padding: 0 0 8px;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: $white;
	overflow: hidden;
}

.headerContainer {
	display: flex;
	padding-inline-start: 24px;
}

.header {
	display: flex;
	flex-wrap: nowrap;
}

.rowsContainer {
	display: flex;
	flex-direction: column;
	height: calc(100% - 42px);
	width: 100%;
	overflow-y: auto;
	flex-wrap: nowrap;
}

.tableWrapper {
	height: calc(100% - 14px);
}

.searchAndButtonContainer {
	height: 48px;
	width: 100%;
	margin-bottom: 8px !important;
}

.arrow {
	width: 14px;
	height: 14px;
}

.tableContainer {
	height: 100%;
	flex-wrap: nowrap;
}

.placeholderText {
	text-align: center;
	font-size: 20px;
	line-height: 29.38px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.5;
}

.titleContainer {
	margin-bottom: 8px;
}

.pageTitleBox {
	width: 100%;
	justify-content: center;
	padding: 20px !important;
}

.title {
	@include h6;
	width: 100%;

	@include for-narrow-layout {
		font-size: 16px !important;
	}
}
