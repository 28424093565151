@import '../../../styles/mixins/media_queries';
@import '../../../styles/mixins/text';
@import '../../../styles/variables';

.input {
	all: initial;
	width: 100%;
	@include body2;
	line-height: 18px;
	color: $primaryColor;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: start;

	&.isRtl {
		direction: rtl;
	}
}

.root {
	width: 100%;
}

.container {
	user-select: none;
	background: $white;
	position: relative;
	display: flex;
	width: 100%;
	height: 48px;

	&.isSmall {
		height: 38px;
	}

	border-radius: 4px;
	border: $inputBorder;
	gap: 5px;
	align-items: center;
	padding: 12px 8px;
	@include body2($grey600);

	&:hover {
		border: $inputHoverBorder;
	}

	&:focus-within {
		border: $inputFocusBorder;
	}

	&.hasPlaceholder,
	&.hasStartIcon,
	&.hasText,
	&:focus-within {
		&.hasLabel {
			.label {
				@include small-regular();
				transform: translateY(-70%);
				color: $grey500;
			}
			.input {
				transform: translateY(30%);
			}
			.startIcon {
				transform: translateY(25%);
			}
		}
	}

	&.isDisabled {
		background: $grey50;
		border-color: $grey300;
		color: $grey300 !important;
		input {
			color: $grey300 !important;
		}
		label {
			color: $grey400 !important;
		}
		img,
		svg {
			filter: invert(72%) sepia(19%) saturate(72%) hue-rotate(172deg) brightness(98%) contrast(101%);
		}
	}
}

.label {
	position: absolute;
	align-items: center;
	display: flex;
	gap: 2px;
	pointer-events: none;
	transition: 0.2s ease;
	font-weight: 400;
}

.required {
	color: $feedbackCriticalDefault;
}

.icon {
	cursor: pointer;
	display: flex;
}

.errorText {
	@include small($feedbackCriticalDark);
}

.errorContainer {
	position: absolute;

	.errorText {
		@include small($feedbackCriticalDark);
	}
}
