@import './../variables';
@import './media_queries';
@import '../typography';

@mixin text($color: $primaryColor) {
	@include for-hebrew-text();
	@include for-english-text();
	@include for-jpn-text();
	@include for-mobile-layout-font-family();
	line-height: 1.28;
	color: $color;
	margin-top: 0;
	margin-bottom: 0;
	letter-spacing: 0.25px;
}

@mixin h1($color: $primaryColor) {
	@include text($color);
	font-size: 48px !important;

	@include for-hebrew-text {
		font-weight: 700 !important;
	}
	@include for-english-text {
		font-weight: 600 !important;
	}
	@include for-jpn-text {
		font-weight: 600 !important;
	}
}

@mixin h2($color: $primaryColor) {
	@include text($color);
	font-size: 48px !important;

	@include for-hebrew-text {
		font-weight: 400 !important;
	}
	@include for-english-text {
		font-weight: 300 !important;
	}
	@include for-jpn-text {
		font-weight: 300 !important;
	}
}

@mixin h3($color: $primaryColor) {
	@include text($color);
	font-size: 40px !important;
	font-weight: 600 !important;

	@include for-hebrew-text;
	@include for-english-text;
	@include for-jpn-text;
}

@mixin h4($color: $primaryColor) {
	@include text($color);
	font-size: 32px !important;
	font-weight: 600 !important;

	@include for-hebrew-text;
	@include for-english-text;
	@include for-jpn-text;
}

@mixin h5($color: $primaryColor) {
	@include text($color);
	font-size: 24px !important;
	font-weight: 600 !important;

	@include for-hebrew-text;
	@include for-english-text;
	@include for-jpn-text;
}

@mixin h6($color: $primaryColor) {
	@include text($color);
	font-size: 20px !important;
	font-weight: 600 !important;

	@include for-hebrew-text {
		font-size: 20px !important;
	}
	@include for-english-text {
		font-size: 20px !important;
	}
	@include for-jpn-text {
		font-size: 20px !important;
	}
}

@mixin subtitle-semi($color: $primaryColor) {
	@include text($color);
	font-size: 16px !important;

	@include for-hebrew-text {
		font-weight: 700 !important;
	}
	@include for-english-text {
		font-weight: 600 !important;
	}
	@include for-jpn-text {
		font-weight: 600 !important;
	}
}

@mixin subtitle-reg($color: $primaryColor) {
	@include text($color);
	font-weight: 400 !important;

	@include for-hebrew-text {
		font-size: 16px !important;
	}
	@include for-english-text {
		font-size: 16px !important;
	}
	@include for-jpn-text {
		font-size: 16px !important;
	}
}

@mixin button-large($color: $primaryColor) {
	@include text($color);
	font-size: 16px !important;
	font-weight: 600 !important;

	@include for-hebrew-text;
	@include for-english-text;
	@include for-jpn-text;
}

@mixin button-small($color: $primaryColor) {
	@include text($color);
	font-weight: 600 !important;

	@include for-hebrew-text {
		font-size: 14px !important;
	}
	@include for-english-text {
		font-size: 13px !important;
	}
	@include for-jpn-text {
		font-size: 13px !important;
	}
}

@mixin link($color: $primaryColor) {
	@include text($color);
	font-size: 14px !important;
	font-weight: 600 !important;

	@include for-hebrew-text;
	@include for-english-text;
	@include for-jpn-text;
}

@mixin body1($color: $primaryColor) {
	@include text($color);
	font-weight: 400 !important;

	@include for-hebrew-text {
		font-size: 16px !important;
	}
	@include for-english-text {
		font-family: 'NotoSans', 'TrusstorFontEng', sans-serif;
		font-size: 15px !important;
	}
	@include for-jpn-text {
		font-family: TrusstorFontJpn, 'NotoSans', sans-serif;
		font-size: 15px !important;
	}
}

@mixin body2($color: $primaryColor) {
	@include text($color);
	font-weight: 400 !important;

	@include for-hebrew-text {
		font-size: 14px !important;
	}
	@include for-english-text {
		font-family: 'NotoSans', 'TrusstorFontEng', sans-serif;
		font-size: 13px !important;
	}
	@include for-jpn-text {
		font-family: TrusstorFontJpn, 'NotoSans', sans-serif;
		font-size: 13px !important;
	}
}

@mixin small-regular($color: $primaryColor) {
	@include text($color);
	font-weight: 400 !important;

	@include for-hebrew-text {
		font-size: 12px !important;
	}
	@include for-english-text {
		font-size: 12px !important;
	}
	@include for-jpn-text {
		font-size: 12px !important;
	}
}

@mixin small($color: $primaryColor) {
	@include text($color);
	font-weight: 500 !important;

	@include for-hebrew-text {
		font-size: 12px !important;
	}
	@include for-english-text {
		font-size: 12px !important;
	}
	@include for-jpn-text {
		font-size: 12px !important;
	}
}

@mixin caption($color: $primaryColor) {
	@include small($color);
	text-transform: uppercase;
}

@mixin for-hebrew-text {
	html[lang='he-il'] & {
		font-family: 'TrusstorFontHeb', 'TrusstorFontEng', sans-serif;
		@content;
		@include for-mobile-layout-text-styles;
	}
}

@mixin for-english-text {
	html[lang='en-us'] & {
		font-family: 'TrusstorFontEng', sans-serif;
		@content;
		@include for-mobile-layout-text-styles;
	}
}

@mixin for-jpn-text {
	html[lang='jpn'] & {
		font-family: 'TrusstorFontJpn', Outfit, sans-serif;
		@content;
		@include for-mobile-layout-text-styles;
	}
}

@mixin for-mobile-layout-text-styles {
	@include for-mobile-layout {
		input,
		textarea {
			font-size: 16px !important;
		}
	}
}

@mixin for-mobile-layout-font-family {
	@include for-mobile-layout {
		font-family: Outfit, sans-serif !important;
	}
}

@mixin cut-line($max-lines: 1) {
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $max-lines;
	-webkit-box-orient: vertical;
}
