@import './../variables';

/* Media queries Breakpoint mixins */

@mixin for-narrow-layout {
	@media (max-width: $break-narrow) {
		@content;
	}
}

@mixin for-narrow-height-layout {
	@media (max-height: $break-narrow-height) {
		@content;
	}
}

@mixin for-small-layout {
	@media (max-width: $break-small) {
		@content;
	}
}

@mixin for-mobile-layout {
	@media (max-width: $break-mobile) {
		@content;
	}
}

@mixin for-small-height-layout {
	@media (max-height: $break-small-height) {
		@content;
	}
}

@mixin for-mobile-keyboard-up {
	@media (max-height: $break-mobile-keyboard-up) {
		@content;
	}
}

@mixin for-wide-layout {
	@media (min-width: $break-small) {
		@content;
	}
}
