@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.ContractorAnalysisHeader_container {
	display: flex;
	justify-content: space-between;
	height: $contractorAnalysisHeader;
	padding: 16px 96px;
	align-items: center;
	background: $blue100;
}

.ContractorAnalysisHeader_middleSection {
	margin-inline-end: 32px;
	position: relative;
	top: 25px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.ContractorAnalysisHeader_title {
	display: flex;
	justify-content: center;
	flex-direction: row;
	gap: 4px;

	&.isRtl {
		flex-direction: row-reverse;
	}
	text-align: center;
	@include h5();
}

.light {
	font-weight: 400;
}
