@import '../../../../../styles/variables';
@import '../../../../../styles/mixins/text';

.dropdownContainer {
	width: 100%;
	position: relative;
}

.dropdownOptions {
	max-height: calc(50vh - 100px);
	overflow-y: auto;
	box-shadow: 0 0 16px 0 #0000001f;
	background: $white;
	width: 100%;
	position: absolute;
	top: 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px;
	z-index: 100000;
	padding-top: 8px;
}

.groupContainerWithTopLevelGroup {
	width: 100%;
}

.groupContainer {
	width: 100%;
}

.groupTitle {
	@include caption($darkBlue);
	border-top: 1px solid $grey200;
	border-bottom: 1px solid $grey200;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px 12px;
}

.indentation {
	padding-inline-start: 4px;
}

.selectAllTitle {
	@include body2($grey600);
	text-transform: capitalize !important;
}

.selectAllSecondaryText {
	@include small($grey500);
}

.topLevelGroupTitle {
	justify-content: flex-start !important;
}

.moreIdentation {
	padding-inline-start: 16px;
}

.footer {
	width: 100%;
	border-top: 1px solid $grey200;
	position: sticky;
	bottom: 0;
	background: $white;
	z-index: 1;
}
