@import '@shared/styles/variables';

.sideMainComponent {
	display: flex;
	flex-direction: column;
	min-width: $sideMainComponentWidth;
	max-width: $sideMainComponentWidth;
	border-inline-end: 0.2px solid $grey100;
	background: $white;
	box-shadow: $componentShadow;
	overflow: auto;
}
