@import '../../styles/_variables.scss';
@import '../../styles/mixins/_text.scss';
@import '../../styles/mixins/media_queries';

.issueContainer {
	width: 100%;
	border-radius: 8px;
	background-color: $white;
	padding: 12px 8px;
	position: relative;

	.editIconContainer {
		background-color: $darkBlue;
		position: absolute;
		top: 8px;
		right: 8px;
		cursor: pointer;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;

		.hoverEditIcon {
			width: 24px;
			height: 24px;
			filter: brightness(0) saturate(100%) invert(83%) sepia(100%) saturate(2%) hue-rotate(203deg)
				brightness(107%) contrast(101%);
		}

		&.editIconRtl {
			left: 8px;
			right: unset;
		}
	}

	.hideElement {
		display: none;
	}

	.header {
		display: flex;
		justify-content: space-between;
	}

	.body {
		margin-top: 8px;

		.description {
			@include button-small($darkBlue);
			line-height: 24px;
			margin-bottom: 8px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.infoSection {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 12px;
		padding: 8px 0;
		border-top: 1px solid $grey100;
		border-bottom: 1px solid $grey100;
		gap: 8px;

		.locationItem {
			display: flex;
			align-items: center;

			.floorText {
				@include body2($grey400);
			}
		}

		.assigneeSection {
			display: flex;
			@include body2($grey700);
			gap: 8px;
		}

		.assigneeText {
			color: $grey500;
		}

		.professionSection {
			display: flex;
			align-items: center;
			@include body2($grey700);
			gap: 8px;
		}

		.activitySection {
			display: flex;
			gap: 8px;
			align-items: center;

			.activityText {
				@include body2($grey700);
				background-color: $grey50;
				border-radius: 4px;
				padding: 8px;
			}
		}
	}

	.footer {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding-top: 8px;
		gap: 8px;
	}
}

.issueMobileDisplayContainer {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	background-color: $white;
	overflow: hidden;
}

.itemHovered {
	cursor: pointer;
	outline: 1.5px solid $darkBlue;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

.comments {
	gap: 8px;
	display: flex;
	align-items: center;

	.commentsLength {
		@include small($grey600);
	}
}
