.locationDataContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	margin-inline-start: 8px;
	flex-grow: 7;
	padding: 16px;

	.topSection {
		display: flex;
		width: 100%;
		margin-bottom: 30px;
	}
}
