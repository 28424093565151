@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.professionIssuesChipTooltipContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 350px;

	.topProfessionDetails {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 4px;
		padding: 8px;
		border-bottom: 1px solid $grey300;
	}

	.issueDataContainer {
		padding: 8px;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		border-bottom: 1px solid $grey500;
		width: 100%;
		gap: 8px;

		.issueDescriptionContainer {
			display: flex;
			align-items: center;
			gap: 8px;

			.issueDescription {
				@include small-regular($white);
				text-transform: capitalize;
				margin: 0;
			}
		}

		.issueInfo {
			display: flex;
			align-items: center;
			flex-direction: row;
			align-items: center;
			gap: 0 16px;

			.iconText {
				display: flex;
				align-items: center;
				gap: 4px;
				@include small-regular($grey300);
			}
		}
	}
}

.detailsText {
	@include small($white);
}

.noBottomBorder {
	border-bottom: none !important;
}
