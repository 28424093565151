@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.milestoneProgressStatsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 20px;
	background-color: $grey50;
	height: 80px;

	.milestoneIndexAndTitle {
		display: flex;
		align-items: center;
		gap: 12px;
		.selectedMilestoneRectangle {
			min-width: 24px;
			min-height: 24px;
			background-color: $grey300;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: rotate(45deg);
			@include small($white);
			border-radius: 4px;
			background-color: $darkBlue;

			.selectedMilestoneIndexText {
				transform: rotate(-45deg);
				@include button-small($white);
			}
		}

		.milestoneTitle {
			@include subtitle-semi($darkBlue);
		}
	}

	.milestoneStatsContainer {
		display: flex;
		align-items: center;
		height: 100%;

		.milestoneStat {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			height: 100%;
			padding: 0px 8px;
			width: max-content;

			.milestoneStatLabel {
				@include small($grey600);
			}

			.progressValues {
				display: flex;
				align-items: center;
				gap: 16px;
			}

			.dueDateContainer {
				padding: 0px 4px;
			}

			.dueDateFlagAndText {
				display: flex;
				align-items: center;
				height: 16px;
			}

			.milestoneStatValue {
				@include subtitle-semi($darkBlue);
			}

			.milestoneStatValueSmall {
				@include small($darkBlue);
			}
		}
	}
}
