@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: $blue100;
}

.content {
	display: flex;
	width: clamp(460px, 100%, 960px);
	flex-direction: column;
	align-items: center;
	height: calc(100% - 60px);
	padding: 24px 20px;
	gap: 8px;
	overflow-y: auto;
}
