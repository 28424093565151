@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.tocContainer {
	@include body2();
	font-size: 14px;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	gap: 24px;
	border: 1px solid $grey200;
	background: $white;
	align-items: center;
	min-width: 48px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-inline-start: 8px;
	border-radius: 4px;
}

.icon {
	filter: invert(58%) sepia(20%) saturate(171%) hue-rotate(173deg) brightness(94%) contrast(90%);
	width: 16px;
	height: 16px;
}
