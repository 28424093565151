@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.ContractorAnalysisDatePickerSection_container {
	padding: 16px 0;
	display: flex;
	gap: 24px;
	height: 56px;
	width: 100%;
}

.title {
	display: flex;
	gap: 4px;
	@include h6();
}

.oneTwoWeekContainer {
	height: 100%;
	display: flex;
	border-radius: 4px;

	&:last-child {
		border-right: none;
	}
}

.weekText {
	@include small($grey500);
	line-height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 16px;
	white-space: nowrap;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid $grey200;
	flex: 1;
	user-select: none;

	&.first {
		border-right: 0;
		border-radius: 4px 0 0 4px;

		&.selected {
			border: 1px solid $darkBlue !important;
			color: $darkBlue;
			border-bottom-right-radius: unset;
			border-top-right-radius: unset;
		}
	}

	&.second {
		border-radius: 0;
		border-right: 0;

		&.selected {
			border: 1px solid $darkBlue !important;
			color: $darkBlue;
			border-radius: unset;
		}
	}

	&.third {
		border-radius: 0 4px 4px 0;

		&.selected {
			border: 1px solid $darkBlue !important;
			color: $darkBlue;
			border-bottom-left-radius: unset;
			border-top-left-radius: unset;
		}
	}

	&.isRTL {
		&.first {
			border-left: 0;
			border-right: 1px solid $grey200;
			border-radius: 0 4px 4px 0;

			&.selected {
				border-bottom-left-radius: unset;
				border-top-left-radius: unset;
			}
		}

		&.second {
			border-left: 0;
			border-right: 1px solid $grey200;
		}

		&.third {
			border-radius: 4px 0 0 4px;

			&.selected {
				border-bottom-right-radius: unset;
				border-top-right-radius: unset;
			}
		}
	}

	&:hover {
		background-color: $grey50;
	}
}
