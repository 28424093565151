@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.dialogRootStyle {
	width: 314px;
}

.title {
	@include subtitle-reg;
	margin-left: 16px;
	text-transform: capitalize;
}

.safetyContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	padding-bottom: 10px;
}

.iconButton {
	width: 15px;
	height: 15px;
}

.pagesController {
	display: flex;
	flex-direction: row;
	align-items: center;

	.pagesNumber {
		@include body2;
	}
	.arrow {
		color: $primaryColor;
		width: 14px;
		height: 14px;
		cursor: pointer;
	}

	.arrowInactive {
		opacity: 0.5;
	}
}
