@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

@-webkit-keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.expandableContainer {
	color: $primaryColor;
	cursor: pointer;

	.topSection {
		display: flex;
		align-items: center;
		padding: 15px 0;
		background-color: $white;
		position: relative;
		z-index: 2;

		.clearButton {
			@include link($primaryColor);
			text-transform: capitalize;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			padding: 10px 16px;
			cursor: pointer;
			z-index: 3;
			text-decoration-line: underline;
			text-underline-offset: 0.17em;
			line-height: 30px;
			letter-spacing: -0.14px;

			&.right {
				right: unset;
				left: 0;
			}
		}
	}

	img {
		width: 24px;
		height: 24px;
		margin-inline-start: 14px;
		margin-inline-end: 10px;
	}

	.headerText {
		@include button-small();
		@include for-narrow-layout {
			@include body2;
		}
	}

	.children {
		flex-direction: column;
		transition: all 0.2s ease;
		background-color: $white;
		-webkit-animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		display: flex;
		flex-grow: 1;
		padding: 6px 14px;
	}
}
