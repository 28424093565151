@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.secondaryTextDialogAttention {
	color: $feedbackCriticalDark;
	margin-inline-end: 2px;
}

.secondaryTextDialog {
	@include small-regular();
	line-height: 12px;
}

.disclaimerPoint {
	margin-top: 4px;
	display: flex;
	flex-direction: row;
	gap: 2px;
	align-items: center;
}
