@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.box {
	width: 100%;
	display: grid;
	grid-template-columns: 200px 1fr auto;
	cursor: pointer;
	padding: 0 16px;
	border-radius: 8px;
	border: 1px solid $grey200;
	background: $white;

	&:hover {
		background-color: rgba($blue100, 0.5);
	}

	&.isSelected {
		border-color: $darkBlue;
		background-color: rgba($blue100, 0.5);
	}
}
