.dialog {
	width: 510px;
	max-height: 710px;
	border-radius: 8px;
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
	overflow: unset;
}

.activityMenu {
	position: absolute;
	top: 10px;
	z-index: 11;
}
