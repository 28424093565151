@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.filterCardContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $grey100;
	padding-inline-end: 8px;
	min-height: 40px;
	max-width: 100%;
	overflow: hidden;
	gap: 4px;
	padding-bottom: 4px;
	padding-top: 4px;

	&:hover {
		cursor: pointer;
		background-color: $grey50;
	}

	&.selected {
		background-color: $blue100;
	}

	.startContainer {
		display: flex;
		align-items: center;
		overflow: hidden;
		flex-shrink: 1;

		.icon {
			margin-inline-end: 8px;
		}

		.filterCardText {
			white-space: nowrap;
			@include body2($grey600);
			min-width: fit-content;

			&.overflowed {
				white-space: unset;
				@include cut-line(1);
			}
		}
	}

	.endContainer {
		display: flex;
		align-items: center;
		gap: 8px;
		flex-shrink: 0;

		.countContainer {
			display: flex;
			align-items: center;
			@include button-small($darkBlue);
		}
	}
}

.filterCardContainer:first-child {
	border-top: 1px solid $grey100;
}
