@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/text';

.IssueMetadataSection_container {
	display: flex;
	gap: 16px;
	padding: 8px 0;
	flex-direction: column;
	@include small-regular($grey400);
}

.bold {
	font-weight: 500;
	unicode-bidi: embed;
}

.item {
	display: flex;
}
