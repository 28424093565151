@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.efficiencyHeader {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;

	.efficiencyText {
		@include subtitle-semi();
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;

		&.dark {
			color: $white;
		}
	}

	.efficiencyDateRange {
		display: flex;
		flex-direction: column;

		.lastDaysText {
			@include small();
			align-self: flex-end;

			&.dark {
				color: $white;
			}
		}

		.rangeText {
			@include small();
			line-height: 15px;

			&.dark {
				color: $grey200;
			}
		}
	}
}
