@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.backForwardSection {
	@include subtitle-semi($darkBlue);
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.arrow {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 24px;
		width: 24px;
	}

	.backForwardText {
		@include body2($grey700);
	}
}
