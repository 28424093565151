@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 28px;
	height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.sliderRound {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.sliderRound:before {
	position: absolute;
	content: '';
	height: 13.7px;
	width: 13.7px;
	top: 1px;
	left: 1px;
	bottom: 1px;
	background-color: $white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.checkbox:checked + .sliderRound {
	background-color: $darkBlue;
}

.checkbox:focus + .sliderRound {
	box-shadow: 0 0 1px $darkBlue;
}

.checkbox:checked + .sliderRound:before {
	-webkit-transform: translateX(12px);
	-ms-transform: translateX(12px);
	transform: translateX(12px);
}

.checkIcon {
	position: absolute;
	right: 2px;
	top: 2px;
	user-select: none;
	filter: invert(49%) sepia(89%) saturate(72508%) hue-rotate(191deg) brightness(95%) contrast(97%);
}

/* Rounded sliders */
.sliderRound {
	border-radius: 22.8571px;
}

.sliderRound:before {
	border-radius: 22.8571px;
}
