@import '../../../styles/variables';
@import '../../../styles/mixins/media_queries';
@import '../../../styles/mixins/text';

.photoContainer {
	display: flex;
	flex-direction: column;
	background-color: $grey50;
	border-radius: 8px;
	padding: 16px 8px;
	gap: 8px;

	.sender {
		@include button-small($grey700);
	}

	.hour {
		@include small($grey700);
	}
}
