@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.rowContainer {
	display: flex;
	flex-direction: row;
	width: fit-content;
	height: 42px;
	position: sticky;
	top: 0px;
	z-index: 100;
	background-color: $white;

	& > * {
		border-inline-end: 2px solid $grey100;
		border-bottom: 2px solid $grey100;
	}
}

.title {
	@include small-regular();
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.subtitle {
	@include small-regular;
	display: flex;
	justify-content: center;
	align-items: center;
}

.twoLinesHeader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
