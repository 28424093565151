@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/_text.scss';
@import '../../../styles/mixins/media_queries';

.rightTopSection {
	display: flex;
	align-items: center;
	gap: 8px;
}

.issueEditContainer {
	height: 100%;
	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 20px 16px;

		.backButton {
			border-radius: 24px;
			background-color: $grey50;
			width: 32px;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 24px;
				height: 24px;

				&.rotateArrow {
					transform: rotate(180deg);
				}
			}
		}

		.dateText {
			@include small($grey400);
			text-transform: uppercase;
		}
	}

	.divider {
		border-bottom: 1px solid $grey200;
		width: 100%;
	}

	.infoSection {
		padding-bottom: 8px;
		border-radius: 8px;
		border-bottom: 1px solid $grey100;

		.descriptionContainer {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 16px;
			position: relative;

			.row {
				display: flex;
				flex-direction: row;

				.privateSection {
					margin-inline-start: auto;
				}
			}

			.description {
				@include subtitle-semi($darkBlue);
				width: 90%;
				padding-left: 4px;
				all: unset;
				max-height: 24px;
				flex: 1;
			}

			.completeButton {
				position: absolute;
				right: 0;

				&.rtl {
					left: 0;
					right: unset;
				}
			}
		}

		.infoDetails {
			padding: 0 16px;
			gap: 16px;
			display: flex;
			flex-direction: column;

			.professionSection {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;

				img {
					height: 16px;
					width: 16px;
					filter: brightness(0) saturate(100%) invert(36%) sepia(28%) saturate(287%) hue-rotate(174deg)
						brightness(95%) contrast(85%);
				}

				.professionText {
					@include body2($grey700);
				}
			}

			.locationItemsContainer {
				display: flex;
				flex-direction: column;
				gap: 4px;

				.locationItem {
					display: flex;
					align-items: center;
					gap: 8px;

					.floorText {
						@include body2($grey600);
					}

					img {
						height: 16px;
						width: 16px;
						margin: unset;
					}
				}
			}

			.assigneeSection {
				display: flex;
				@include body2($grey700);
				align-items: center;
				gap: 8px;

				img {
					height: 16px;
					width: 16px;
				}
			}

			.assigneeText {
				color: $grey500;
			}

			.activitySection {
				display: flex;
				gap: 8px;
				align-items: center;

				img {
					height: 16px;
					width: 16px;
				}

				.activityText {
					@include body2($grey700);
					background-color: $grey50;
					border-radius: 4px;
					padding: 8px;
				}
			}
		}
	}

	.threadsSection {
		padding: 16px 4px;
	}

	.noComments {
		@include body2($grey300);
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30%;
	}
}

.scrollable {
	overflow-y: scroll;
}

.bodyWithoutHeader {
	padding-top: 0 !important;
}
