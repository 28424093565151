@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/variables.scss';

.dialog {
	width: 512px;
	display: flex;
	flex-direction: column;
	max-height: 80vh;
	overflow: hidden;
}

.header {
	padding: 12px 12px 0 12px;

	.headerTab {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.headerInfo {
			padding: 6px 4px;
			border-radius: 2px;
			font-size: 12px;
			font-weight: 500;
		}
	}

	.infoSection {
		display: flex;
		flex-direction: column;
		gap: 18px;
		margin-top: 14px;
		margin-bottom: 18px;

		.description {
			@include h6($darkBlue);
		}

		.professionSection {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;

			img {
				height: 16px;
				width: 16px;
			}

			.professionText {
				@include body2($grey700);
			}
		}
	}
}

.issuesContainer {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: $blue100;
	padding: 16px;
	gap: 16px;

	.issuesHeader {
		@include subtitle-semi($darkBlue);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.arrow {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 24px;
			width: 24px;
		}
	}

	.issue {
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: $white;
		border-radius: 8px;
		padding: 16px;
		overflow-x: hidden;
	}
}
