@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.dialog {
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px;
	width: 332px;
	overflow: unset !important;
}

.topSection {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.headerSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 16px;
}

.titleSection {
	display: flex;
	align-items: center;
}

.title {
	font-size: 14px;
	font-weight: 600;
	color: $primaryColor;
}

.dialogContent {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 8px;
}

.iconButton {
	margin-inline-start: 4px;
	height: 15px;
	width: 15px;
}

.buttonsContainer {
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
}

.rightButtons {
	display: flex;
	flex-direction: row-reverse;
}

.leftButtons {
	display: flex;
}

.secondaryButton {
	margin-inline-end: 8px;
	img {
		filter: invert(0%) sepia(1%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(102%);
	}
}

.tertiaryButton {
	text-decoration: underline;
}

.icon {
	height: 11px;
	width: 11px;
	margin-inline-end: 4px;
}
