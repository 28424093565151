@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 350px;
	width: 100%;
}

.milestonesProgressContainer {
	display: flex;
	flex-direction: column;
	padding: 0px 32px;
	padding-top: 16px;

	.headerText {
		@include subtitle-semi();
	}
}
