@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.areaSequenceItemContainer {
	@include small-regular();
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	flex-direction: column;
	background-color: $grey50;
	padding: 8px;
	border-radius: 8px;
	position: relative;
	min-width: 50px;
	border: 1px solid $grey200;
	cursor: pointer;

	&:not(.disableHover) {
		&:hover {
			border: 1px solid $darkBlue !important;
		}
	}

	&.inProgress {
		background-color: $yellow100;
		border: 1px solid $feedbackMediumLight;
	}

	&.done {
		background-color: $green100;
		border: 1px solid $feedbackPositiveDefault;
	}

	&.unscheduled {
		background-color: $white;
	}

	&.upComing {
		background-color: $grey50;
	}

	&.readyForReview {
		background-color: $feedbackHighLight;
		border: 1px solid $feedbackHighDark;
	}

	&.overdue {
		background-color: $feedbackCriticalLight;
		border: 1px solid $feedbackCriticalDefault;
	}

	.areaText {
		@include small;
		margin-top: 9px;
		line-height: 18px;
	}

	.timeText {
		color: $grey500;
		min-height: 16px;
	}

	img {
		position: absolute;
		top: 5px;
		right: 5px;
	}
}

.forReview {
	text-align: center;
	@include small($grey600);
}
