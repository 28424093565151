@import '@shared/styles/mixins/text';

.UserDialog {
	width: 392px;
}

.topMargin {
	margin-top: 10px;
}

.warningText {
	@include small-regular;
}

.endAdornment {
	top: 8px !important;
}

.selectLanguageContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.systemLanguageText {
	@include subtitle-semi();
}
