@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.activityMatrixContainer {
	padding-inline-start: 16px;
	padding-top: 16px;
	overflow: auto;
	display: flex;
	gap: 16px;
	height: 100%;
	width: 100%;
}

.floorTocContainer {
	height: 100%;
	overflow: scroll;
}

.loaderContainer {
	&.rtl {
		transform: rotateY(180deg);
	}
}

.matrixWrapper {
	height: fit-content;
}
