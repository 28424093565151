@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.restrictedText {
	@include body2();
	margin: 0;
	margin-inline-start: 3px;
}

.professionSelectSection {
	margin-top: 13px;
}
