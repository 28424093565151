@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.floorContainer {
	width: 100%;
	padding: 24px 16px;
	margin-top: 16px;
	margin-bottom: 8px;
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 90px;

	.iconContainer {
		width: 40px;
		height: 40px;
		background: $white;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			width: 24px;
			height: 24px;
			filter: brightness(0) saturate(100%) invert(14%) sepia(60%) saturate(774%) hue-rotate(161deg)
				brightness(90%) contrast(91%);
		}
	}

	.floorInfoContainer {
		display: flex;
		align-items: center;
		gap: 16px;

		.floorInfo {
			display: flex;
			flex-direction: column;
			gap: 2px;

			.floorInfoTitle {
				@include h6;
				text-transform: capitalize;
			}

			.floorInfoSubtitle {
				@include small-regular($grey500);
			}
		}
	}
}

.statsSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	height: 100%;
}

.statTextHeader {
	@include small-regular($grey500);
	width: 100%;
	padding: 0;
}

.statText {
	@include subtitle-semi;
	text-align: center;
}

.divider {
	width: 1px;
	border: 1px solid $white;
	height: 100%;
}

.statsInnerSection {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}
