.divSeparator {
	width: 1px;
	height: 12px;
	border-left: 1px solid rgb(154, 174, 187);
	align-self: center;
}

.floorSection {
	display: flex;
	flex-direction: row;
	gap: 5px;
}
