@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.SequenceSelector_container {
	height: calc(100vh - 190px);
	overflow: auto;
	width: clamp(300px, 50%, 1000px);
}

.sequenceSection {
	cursor: pointer;
	display: flex;
	padding: 16px 8px;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-bottom: 1px solid $grey200;

	&.selected {
		background: $blue100;
	}

	&:hover {
		background: $grey50;
	}
}

.sequenceTitle {
	user-select: none;
	padding: 16px 0;
	@include subtitle-semi();
}

.description {
	@include body1;
}

.amountLinked {
	@include subtitle-semi();
}

.section {
	display: flex;
	align-items: center;
	gap: 8px;
}
