@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.activityCard {
	position: relative;
	cursor: pointer;
	width: 100%;
	border: solid 1px $grey200;
	border-radius: 4px;
	background-color: $white;

	&:hover {
		border: 1.5px solid $darkBlue;
		box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
	}

	&.selected {
		border: 1.5px solid $darkBlue;
		box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
	}

	.body {
		padding: 8px;

		.bodyHeader {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.professionContainer {
				display: flex;
				justify-content: center;
				align-items: center;

				.tradeChip {
					@include small($white);
					line-height: 14.69px;
					border-radius: 3px;
					padding: 2px 4px;
					width: max-content;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-inline-end: 5px;

					&.specialtyChip {
						border: 1px solid $grey200;
						color: $darkBlue;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.contractorText {
					@include small-regular();
					color: $primaryColor;
				}
			}
		}

		.bodySecondHeader {
			margin-top: 8px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.locationsTitleContainer {
				@include small($grey600);
				display: flex;
				align-items: center;
				gap: 2px;
				height: 16px;
				line-height: 10px;
			}

			.progressLabel {
				@include small-regular;
				display: flex;
				align-items: center;
				gap: 2px;
				height: 28px;
				padding: 2px 4px;
				background-color: $grey50;
				border-radius: 5px;
			}

			.compeletedCountContainer {
				@include small($grey600);
				display: flex;
				align-items: center;
				gap: 2px;
				line-height: 10px;
				text-transform: lowercase;
			}
		}

		.bodyContent {
			.bodyFooter {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding-top: 16px;

				.completeButtonContainer {
					display: flex;
					align-items: center;
					justify-content: center;

					.completeButton {
						&:hover {
							background-color: $grey600;
						}
					}
				}
			}
		}
	}
}

.infoContainer {
	@include button-small($grey600);
	display: flex;
	align-items: center;
	height: 24px;
	cursor: pointer;
}

.hidden {
	visibility: hidden;
}

.activityMenu {
	padding-inline-start: 10px;
	position: absolute;
	top: 10px;
	z-index: 11;
}

.bottomRight {
	display: flex;
	gap: 8px;
}
