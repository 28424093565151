@import '@shared/styles/mixins/text';

.areaSection {
	cursor: pointer;
	@include subtitle-semi();
	padding: 8px 16px;
	display: flex;
	align-items: center;
	gap: 4px;
}

.professionSection {
	margin: 0 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-bottom: 10px;
}

.emptyState {
	@include subtitle-semi();
	display: flex;
	flex-direction: column;
	margin-top: 150px;
	align-items: center;
	border-radius: 8px;
}
