@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.allCardsContainer {
	display: flex;
	gap: 24px;
	width: fit-content;
	justify-content: center;
}

.cardsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	gap: 32px;
	flex-grow: 1;
	flex-shrink: 1;

	.greyBackground {
		position: absolute;
		background-color: $black;
		width: 1000%;
		height: 1000%;
		top: 0;
		z-index: 5;
	}

	.cardsRow {
		display: flex;
		gap: 24px;
		flex-grow: 1;
		flex-shrink: 1;
		width: 100%;
	}
}

.reportCardWrapper {
	flex: 1 1 0;
}

.reportCardContainer {
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $white;
	cursor: pointer;
	min-height: 100%;
	border: 1px solid transparent;

	.header {
		background-color: $yellow;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 40px;
		border-radius: 8px 8px 0 0;
		padding: 8px 16px 8px 8px;

		.topCircle {
			height: 16px;
			width: 16px;
		}

		.title {
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;

			.titleText {
				@include subtitle-semi();
			}
		}
	}

	&.hoveredCard {
		box-shadow: 0 0 8px rgba(17, 24, 39, 0.16);
		border: 1px solid $yellow;
	}

	&.selectedCard {
		box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
		border: 1px solid $yellow;
		cursor: unset;
	}

	.inputsContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 16px;
		height: 100%;
		justify-content: space-between;

		.input {
			display: flex;
			flex-direction: column;
			gap: 8px;
			margin-bottom: 8px;
		}

		.createButton {
			align-self: end;
		}
	}

	.descriptionContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		padding: 8px 0;

		.descriptionText {
			@include small-regular();
			margin: 8px 16px;
			line-height: 20px;
			text-align: start;
		}

		.exampleText {
			@include small-regular();
			opacity: 0.5;
			line-height: 20px;
		}
	}
}
