@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.listContainer {
	width: 100%;
	min-height: 435px;
	padding: 0 0 8px;
	border-radius: 3px;
	background-color: $white;
	height: calc(100vh - 290px);
	overflow: auto;
}

.headerContainer {
	background: $white;
	display: flex;
	padding-inline-start: 24px;
	position: sticky;
	top: 0;
	z-index: 100;
	min-width: fit-content;
	width: 100%;
}

.header {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
}

.rowsContainer {
	display: flex;
	flex-direction: column;
	height: calc(100% - 42px);
	width: 100%;
	flex-wrap: nowrap;
}

.tableWrapper {
	height: calc(100% - 14px);
}

.tableContainer {
	height: 100%;
	flex-wrap: nowrap;
}

.professionCell {
	display: flex;
	justify-content: center;
	width: fit-content;
	max-width: 100%;
}

.selectedRow {
	background-color: $blue100;
}

.row {
	height: unset;
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: $grey50;
	}
}

.isCurrentSelected {
	border-inline-start: 1.5px solid $darkBlue;
}
