@import '../../styles/variables';

.loading {
	border: 4px solid #f3f3f3;
	border-top: 4px solid $primaryColor;
	border-radius: 50%;
	margin: auto;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
