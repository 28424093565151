@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.title {
	@include body2;
	margin-inline-start: 7px;
	margin-inline-end: 7px;
	line-height: 1;
	width: max-content;
	@include for-narrow-layout {
		@include small-regular();
	}
}

.columnContainer {
	display: flex;
	flex-direction: column;

	& > * {
		border: $regularBorder;
	}
}

.tableRowContainer {
	display: flex;
	align-items: center;
	width: 95%;
	margin-left: 8px;
}

.icon {
	width: 11px;
	height: 11px;
	margin-right: 6px;
}
