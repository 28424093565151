@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.notificationPageContainer {
	display: flex;
	margin: 20px 15%;
	flex-direction: column;
	align-items: center;
	width: 100%;

	.title {
		position: sticky;
		@include body2();
		font-size: 24px;
		font-weight: 700;
		align-self: flex-start;
		margin-bottom: 16px;
	}

	.content {
		overflow-y: auto;
		width: 100%;
	}
}
