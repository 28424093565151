@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.column {
	padding: 16px;
	min-width: 328px;
	max-width: 328px;
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	gap: 8px;
	overflow-y: auto;
}

.hide {
	opacity: 0;
}

.dragArea {
	width: 100%;
	justify-content: center;
	align-items: center;
	display: none;

	&.show {
		display: flex;
	}

	.dragIcon {
		transform: rotate(90deg);
	}

	&:hover {
		cursor: grab;
	}
}

.smallColumn {
	min-width: 256px;
	max-width: 256px;
	padding: 8px;
}

.issuesHeaderContainer {
	height: 24px;
	display: flex;
	align-items: center;
}

.issueCreateContainer {
	border-radius: 8px;
	border: 1px solid $darkBlue;
	background: $white;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}
