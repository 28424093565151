@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: 1px solid $grey100;
	padding: 8px;

	.description {
		@include body1();
	}

	.headerEndSection {
		display: flex;
		align-items: center;
		gap: 4px;

		.dueDateContainer {
			@include small($grey600);
			display: flex;
			align-items: center;
			gap: 4px;
			padding-inline-end: 4px;
			border-radius: 4px;
			height: 24px;
		}

		.menuIcon {
			cursor: pointer;

			&:hover {
				background-color: $grey50;
				border-radius: 8px;
			}
		}
	}
}

.greyBackground {
	background-color: $grey50;
}
