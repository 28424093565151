@import '@shared/styles/variables';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/mixins/text';

.personnelFloorEntitiesContainer {
	display: flex;
}

.chipElement {
	pointer-events: visible;
	display: flex;
	align-items: center;
}

.mixedEntitiesTooltip {
	background: $darkBlue;
	border-radius: 4px !important;
	padding: 0 !important;
	border: unset !important;
	min-width: 300px;
	max-width: 400px;
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}
