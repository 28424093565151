@import '@shared/styles/mixins/utility_classes';
@import '@shared/styles/mixins/text';

.labelContainer {
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	justify-content: space-between;
}

.text {
	text-overflow: ellipsis;
	overflow: hidden;
	@include small-regular;
	line-height: unset;
	width: 100%;
	text-transform: initial;
}

.chipRoot {
	white-space: nowrap;
	cursor: pointer;
	opacity: 1;
	justify-content: start;
}

.labelRoot {
	padding: 8px;
	overflow: unset !important;
	//new code
	width: fit-content;
	min-width: 100%;
}

.contentWrapper {
	position: relative;
	display: flex;
	gap: 8px;
}

.activityDisplayContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
}

.descriptionText {
	@include body2();
	margin: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.professionDetails {
	display: flex;
	gap: 8px;
}

.activityDetails {
	display: flex;
	gap: 10px;
	align-items: center;
}

.professionText {
	@include small-regular;
	color: $grey600;
}

.floorsText {
	@include small-regular;
	margin: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.activityMainText {
	@include small-regular;
	text-overflow: ellipsis;
	overflow: hidden;
}

.flexBox {
	display: flex;
	gap: 8px;
	justify-content: space-between;
	width: 100%;
}

.iconsWrapper {
	@include flex-center;
	gap: 8px;
	@include small-regular;
}

.activeFloorsText {
	@include flex-center;
	@include small-regular;
}

.itemWithIcon {
	@include flex-center;
	gap: 4px;
}

.icon {
	filter: brightness(0) saturate(100%) invert(11%) sepia(9%) saturate(2210%) hue-rotate(173deg) brightness(94%)
		contrast(87%);
}

.bigNumber {
	@include small;
}

.smallNumber {
	@include small-regular;
}

.menuDots {
	display: flex;
	position: relative;
	bottom: 5px;
}
