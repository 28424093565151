@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.certificateSectionTitle {
	@include small-regular;
}

.certificateName {
	width: 65%;
}

.certificateSection {
	border-radius: 3px;
	display: flex;
	flex-direction: column;

	input {
		color: #3b3b3b;
		height: 16px;
		width: 100%;
	}

	svg {
		width: 16px;
		height: 16px;
	}

	p {
		@include small;
		text-overflow: ellipsis;
		margin: unset;
	}

	.certificateSectionContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		max-height: 204px;

		&.managerMaxHeight {
			max-height: 340px;
		}

		.scrollContainer {
			width: 100%;
			overflow: auto;
		}

		.certificateRow {
			display: flex;
			align-items: center;
			gap: 8px;
			width: 100%;
			min-height: 50px;

			.checkCancelIcon {
				//margin: 0 10px
			}

			.dateSection {
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: 0 1px 0 black;

				input {
					flex: 1;
					text-align: start;
					outline: unset !important;
					margin-left: 5px;
					margin-right: 5px;
				}
			}

			.certificateNameSection {
				position: relative;
				width: 50%;

				.certificateNameError {
					position: absolute;
					font-size: 10px;
					color: $feedbackCriticalDark;

					&.errorRight {
						right: 20px;
					}

					&.errorLeft {
						left: 20px;
					}
				}
				.certificateDateError {
					font-size: 10px;
					color: $feedbackCriticalDark;
					width: 145px;

					&.errorRight {
						left: -144px;
					}

					&.errorLeft {
						right: -144px;
					}
				}
			}
		}

		.certificateDropdown {
			@include small;
			position: relative;
		}

		.certificateDatePicker {
			border: unset;
			display: flex;
		}

		.exitCertificate {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
}

.addCertificateButton {
	@include small-regular;
	border-radius: 3px;
	width: max-content;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	margin-top: 18px;
}

.addAnotherCertificate {
	@include small-regular;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	align-self: start;
	min-height: 38px;
	padding: 0 20px;
	text-decoration: underline;
}

.certificateTagRowContainer {
	height: max-content;

	.certificateDropdown {
		padding: unset !important;
	}

	.certificateRow {
		border: unset !important;
	}

	.deleteSection {
		@include small-regular;
		padding: 15px 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f5f5f5;
		border-radius: 3px;
		height: 50px;

		.deleteText {
			font-weight: 400;
		}

		.deleteCancel {
			@include small-regular;
			margin: 0 20px 0 15px;
			cursor: pointer;
			text-decoration: underline;
		}

		.deleteApprove {
			@include small($white);
			cursor: pointer;
			background: #ff7575;
			padding: 4.5px;
			border-radius: 3px;
		}
	}
}
