.SpecialFloorResources_container {
	margin: auto 0 auto 0;
	padding: 16px 0;
	line-height: 1.5;
}

.equipmentChip {
	pointer-events: visible;
	display: inline-block;
	vertical-align: middle;
}
