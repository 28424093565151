@import '@shared/styles/mixins/_text.scss';

.table {
	border-collapse: collapse;
	width: 100%;
	border-radius: 4px;
	padding-inline-start: 22px;

	th {
		text-transform: uppercase;
		@include small;
		border-top: 1px solid rgba(240, 240, 240, 1);
	}

	th:nth-child(1),
	th:nth-child(2),
	td:nth-child(1),
	td:nth-child(2) {
		text-align: start;
		padding-inline-start: 40px;
		max-width: 50px;
		min-width: 50px;
	}

	tr:nth-child(odd):not(.no-header) {
		background-color: #fafafa;
	}

	thead * {
		background-color: $white;
	}

	th,
	td {
		padding: 12px;
		text-align: center;
		max-width: 80px;
		min-width: 80px;
	}

	tr {
		@include small-regular;
		border-bottom: 1px solid rgba(240, 240, 240, 1);
	}
}

.dateCell {
	display: flex;
	align-items: center;
	gap: 4px;
}

.multipleRecordsIconContainer {
	min-width: 16px;
}
