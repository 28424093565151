@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/variables';

.headerText {
	@include small();
	@include for-narrow-layout {
		font-size: 11px;
	}
	text-transform: uppercase;

	&:hover {
		background-color: $grey50;
		cursor: pointer;
	}
}

.boldText {
	@include button-small();
	text-transform: capitalize;
}

.headerTitleWrapper {
	display: flex;
	align-items: center;
	min-height: 20px;
	text-align: center;
	justify-content: flex-start;

	&.center {
		justify-content: center;
	}
}

.bordered {
	padding: 8px 2px 8px 8px;
	border-radius: 4px;
	border: 1px solid $primaryColor;
}
.menuTitle {
	position: relative;
}

.menuContent {
	position: absolute;
	top: 100%;
	left: 0;
	width: 234px;
	background-color: $white;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
	z-index: 1;
}

.headerTextWrapper {
	margin-top: 12px;
	margin-bottom: 12px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sortArrow {
	min-width: 14px;
}
