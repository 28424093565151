@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.SpecialFloorTitleSection_container {
	padding: 16px 0;
	margin: auto 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.title {
	display: flex;
	align-items: center;
	gap: 8px;
}

.floorNickContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.iconContainer {
	display: flex;
	align-items: center;
	@include small($grey500);
	gap: 4px;
}

.floorNick {
	@include button-small();
}

.hoistInfo {
	display: flex;
	gap: 8px;
	@include small($grey500);
}

.locationAmount {
	@include small($grey500);
}

.location {
	display: flex;
	align-items: center;
	gap: 4px;
	@include small($grey500);
}
