@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.widgetHeader {
	.tabsSection {
		padding: 12px 16px;
		display: flex;
	}
}

.secondaryTabsContainer {
	display: flex;
	gap: 8px;
	align-items: center;
	flex-wrap: wrap;
	width: fit-content;

	.secondaryTabItem {
		display: flex;
		align-items: center;
		padding: 4px 8px;
		border-radius: 8px;
		cursor: pointer;

		&:hover {
			background-color: $grey50;
		}

		&.active {
			background-color: $grey100;

			.secondaryTabItemNumber {
				margin-inline-start: 2px;
				@include h6();
			}

			.secondaryTabItemLabel {
				@include small(600);
				line-height: 12px;
			}
		}

		.secondaryTabItemNumber {
			margin-inline-start: 2px;
			@include h6($grey500);
		}

		.secondaryTabItemLabel {
			margin-inline-start: 4px;
			@include small-regular($grey500);
			line-height: 12px;
		}
	}
}
