@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.row {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $grey100;
}

.rowText {
	@include small($grey500);
	min-width: 110px;
	padding: 8px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.bar {
	display: flex;
	width: 100%;
}

.barCompleted {
	background: $blue100;
}

.secondaryBar {
	background: $blue300;
}

.rowValue {
	@include small;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 150px;
}

.secondaryText {
	display: flex;
	align-items: center;
	direction: ltr;
	@include button-small($blue300);
	padding: 8px;
}

.popUp {
	transition:
		transform 0.3s ease,
		opacity 0.3s ease,
		box-shadow 0.3s ease;

	&:hover {
		opacity: 0.7;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	}
}
