@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	background: $darkBlue;
	border-radius: 4px;
}

.title {
	@include button-small($white);
	width: 100%;
	background: $grey700;
	padding: 8px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.chips {
	display: flex;
	padding: 16px 0px;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 8px;
}

.bottomDivider {
	border-bottom: 1px solid $grey400;
}

.tagsContainer {
	padding-top: 8px;
}

.utilitiesHeader {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
}

.tags {
	padding-top: 10px;
	display: grid;
	column-gap: 10px;
	grid-template-columns: 1fr auto;
}

.isOnlyOneTag {
	grid-template-columns: 1fr;
}

.tag {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px 0;
}

.tagInitialPart {
	display: flex;
	align-items: center;
	gap: 4px;
}

.tagNick {
	@include small($white);
}

.tagName {
	@include body2($white);
}

.content {
	padding: 0 8px;
	display: flex;
	flex-direction: column;
}
