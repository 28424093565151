@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.divider {
	border-left: 1px solid $primaryColor;
}

.selectedItem {
	background-color: #f3f8ff;
	border-bottom: 2px solid #2b679e;
	box-sizing: border-box;
	padding-top: 2px;
}

.tabContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	min-height: inherit;
	gap: 12px;
	box-sizing: border-box;
	cursor: pointer;
}

.tabContainer:not(.selectedItem):hover {
	background-color: $grey50;
}

.item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.iconValueContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	@include h6($grey600);
}

.valueText {
	padding-inline-end: 4px;
	margin: 0;
}

.tabLabel {
	margin: 0;
	@include body2();
}

.selectedItemText {
	color: $primaryColor;
}

.itemsContainer {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
}
