@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/_text.scss';
@import '../../../styles/mixins/media_queries';

.itemHovered {
	background-color: $grey50;
	cursor: pointer;
}

.completeButton {
	margin-inline-start: auto;
	height: 32px;
}
