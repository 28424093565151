@import '../../styles/mixins/text';

.textStyle {
	@include body2;
	font-size: 13px;
	color: $grey600;
	line-height: 20px;
	unicode-bidi: plaintext;
}

.strongerText {
	@include body2;
	line-height: 20px;
	font-weight: 600;
}
