@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.professionsManagersContainer {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	background-color: $white;
}

.entitiesWrapper {
	display: flex;
	flex-direction: column;
	max-width: 48%;
	min-width: 48%;
	overflow-y: hidden;
}

.equipmentWrapper {
	flex: 1;
	margin-top: 4px;
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
