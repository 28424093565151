.header {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}

.dataSection {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 8px;
}

.chipText {
	font-size: 12px;
}
