@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/_variables.scss';

.timesheetContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: $white;
	border-radius: 4px;
}
