.menuContainer {
	background-color: white;
	flex-grow: 1;
	max-width: 350px;

	& > * {
		min-height: 140px;
		padding: 16px;
	}
}
