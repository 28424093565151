@import '@shared/styles/mixins/text';

.colorPicker {
	margin-top: 20px;
}

.title {
	@include small($grey500);
	margin-bottom: 8px;
}

.colorContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
}

.colorOption {
	display: flex;
	height: 30px;
	width: 30px;
	border-radius: 3px;
	cursor: pointer;
}

.checkIcon {
	margin: auto;
	width: 20px;
	height: 20px;
}
