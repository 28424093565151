@import '@shared/styles/_variables.scss';

.dateYellowBox {
	padding: 8px 16px;
	display: flex;
	gap: 20px;
}

.datePickerClass {
	width: 90%;
}
