@import '@shared/styles/mixins/text';
@import '@shared/styles/_variables.scss';

$CERTIFICATE__NAME_CELL_WIDTH: 225px;
$EXPIRATION_DATE_CELL_WIDTH: 75px;
$CELL_HEIGHT: 30px;

.table {
	margin: 16px;
	margin-inline-end: 8px;

	.tableHeadersRow {
		@include small-regular($white);
		display: flex;
		flex-direction: row;

		.certificateNameHeader {
			width: $CERTIFICATE__NAME_CELL_WIDTH;
		}

		.expirationDateHeader {
			width: $EXPIRATION_DATE_CELL_WIDTH;
		}
	}

	.tableBodyRow {
		display: flex;
		flex-direction: column;

		.tableRow {
			@include small-regular($white);
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 16px;

			.certificateNameCell {
				display: flex;
				flex-direction: row;
				min-width: $CERTIFICATE__NAME_CELL_WIDTH;

				.statusIcon {
					margin-inline-end: 10px;
				}
			}

			.expiredExpirationDateCell {
				width: $EXPIRATION_DATE_CELL_WIDTH;
				color: rgba($darkBlue, 0.7);
			}

			.expirationDateCell {
				width: $EXPIRATION_DATE_CELL_WIDTH;
			}
		}
	}
}
