@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.pageContainer {
	flex-wrap: unset;
	margin: 16px;
}

.titleContainer {
	margin-bottom: 8px;
}

.pageTitleBox {
	width: 100%;
	justify-content: center;
	padding: 20px !important;
}

.title {
	@include h6;
	width: 100%;

	@include for-narrow-layout {
		font-size: 16px !important;
	}
}

.pageContent {
	display: flex;
	max-height: calc(100% - 70px);

	@include for-narrow-layout {
		max-height: calc(100% - 70px);
	}
}

.pageDashboardContainer {
	min-width: 15%;
	max-width: 15%;
	overflow: auto;
	flex-wrap: nowrap;
}

.pageTableContainer {
	min-width: 98%;
}
