@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.floorChipsContainer {
	position: absolute;
	display: flex;
	overflow: hidden;
	justify-content: flex-start;
	align-items: center;
	pointer-events: none;
	z-index: 2;
}
