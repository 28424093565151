/*Phone Number Input global classes*/
@import '../mixins/text';
@import '../variables';

.react-tel-input {
	direction: ltr;
}

.react-tel-input .form-control {
	@include body1();
	width: 100%;
	position: relative;
	height: 48px;
	border-radius: 4px;
	border: $inputBorder;
	gap: 5px;
	align-items: center;

	&:hover {
		border: $inputHoverBorder;
	}

	&:focus-within {
		border: $inputFocusBorder;
	}
}

.react-tel-input .country-list {
	width: 520px;
	border-radius: 3px;
	margin: 2px 0 10px 0px;
}

.react-tel-input .country-list .country {
	padding: 10px 10px;
	margin-top: 0;
}

.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown.invalid-number,
.react-tel-input .flag-dropdown.open {
	background-color: transparent;
	border: none;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
	background-color: initial;
}
