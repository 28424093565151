@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	padding: 0 8px;
}

.part {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 5px;
}

.projectManagerHeader {
	@include small();
	text-transform: uppercase;

	&.dark {
		color: $grey200;
	}
}

.projectManagerName {
	@include subtitle-semi();

	&.dark {
		color: $white;
	}
}

.iconWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	cursor: pointer;

	img {
		width: 13px;
		height: 13px;
		filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(14deg) brightness(102%) contrast(101%);
	}

	&.dark {
		background: $grey600;
	}
}
