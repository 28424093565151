@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.page {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: $white;
}

.content {
	width: 100%;
	padding: 0 150px;
	@include for-narrow-layout {
		padding: 0 20px;
	}
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.bottomContent {
	border-top: 1px solid $grey200;
	height: calc(100% - 60px);
	width: 100%;
	display: flex;
	gap: 8px;
}
