@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.minimalSafetyRowContainer {
	display: flex;
	border-bottom: 1px solid $grey100;
	min-height: 72px;
	align-items: center;

	.iconClass {
		width: 12px;
		height: 12px;
		margin-inline-end: 8px;
	}

	.safetyEventText {
		@include small-regular();
		line-height: 16px;
		flex: 1;
	}
}
