@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.rowContainer {
	padding: 0 10px;
	min-height: 72px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid $grey100;

	.professionDescriptionContainer {
		display: flex;

		.profession {
			@include small($white);
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 18px;
			border-radius: 3px;
			padding: 0 4px;
			width: max-content;
			height: max-content;
			align-self: center;
			white-space: nowrap;
		}

		.description {
			@include body2($primaryColor);
			line-height: 18px;
			margin-inline-start: 12px;
			text-transform: capitalize;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.contractorSpecialtyContainer {
		display: flex;
		@include small($grey500);
		line-height: 18px;

		span {
			margin: 0 4px;
			font-weight: 400;
		}

		.contractor {
			font-weight: 500;
		}

		.specialty {
			font-weight: 400;
		}
	}
}
