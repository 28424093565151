@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.milestoneSelector {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	width: 120px;
	padding: 16px 0px;
	cursor: pointer;
	height: 100%;

	&:hover {
		border-bottom: 2px solid $grey200;
		padding-bottom: 14px;
	}

	&.selected {
		background-color: $blue100;
		border-bottom: 2px solid $darkBlue;
		padding-bottom: 14px;
	}

	.milestoneTitle {
		@include small();
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 110px;
	}

	.completedSection {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 4px;
		height: 16px;
		padding-bottom: 3px;

		.completedText {
			@include small($grey600);
		}
	}

	.progressSection {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 4px;
		height: 16px;

		.percentage {
			@include small($grey600);
		}

		.progressBar {
			width: 44px;
			height: 6px;
			border-radius: 32px;
			background-color: $grey50;
			border: 1px solid $white;

			.percentSection {
				height: 100%;
				background-color: $blue300;
				border-radius: 32px;
			}
		}
	}

	.timeline {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-bottom: 8px;

		.line {
			height: 9px;
			background-color: $grey100;
			position: absolute;
			width: 100%;

			&.first {
				width: 50%;
				right: 0;

				&.rtl {
					width: 50%;
					left: 0;
					right: auto;
				}
			}

			&.last {
				width: 50%;
				left: 0;

				&.rtl {
					width: 50%;
					right: 0;
					left: auto;
				}
			}

			&.dark {
				background-color: $grey200;
			}
		}

		.indexContainer {
			width: 32px;
			height: 32px;
			background-color: $grey300;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: rotate(45deg);
			@include small($white);
			border-radius: 4px;

			&.selected {
				background-color: $darkBlue;
			}

			&.notStarted {
				background-color: $white;
				border: 1px solid $grey100;
				@include small($black);
			}

			.indexText {
				transform: rotate(-45deg);
			}
		}

		.todayMarker {
			position: absolute;
			z-index: 5;
			.todayText {
				@include small($grey700);
				font-size: 9px !important;
				position: absolute;
				bottom: 7px;
				background-color: transparent;
				transform: translateX(-50%);
				width: max-content;

				&.rtl {
					transform: translateX(50%);
				}
			}

			.todayLine {
				position: absolute;
				height: 14px;
				bottom: -7px;
				width: 2px;
				background-color: $grey300;
			}
		}
	}

	.dateFlagContainer {
		padding-inline-end: 2px;
		border-radius: 2px;
		background-color: $feedbackCriticalLight;
	}

	.healthIndicatorContainer {
		display: flex;
		align-items: center;
		gap: 4px;

		.healthIndicator {
			width: 6px;
			height: 6px;
			border-radius: 50%;
		}

		.healthScoreText {
			text-transform: capitalize;
			@include small($grey700);
		}
	}
}
