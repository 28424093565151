@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.avatar {
	border-radius: 50%;
	box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.45);
	background-color: $white;
	position: absolute;
	height: 15px;
	width: 15px;
	top: -8px;
	left: 16px;
	z-index: 12;
	display: flex;
	align-items: center;
	justify-content: center;
}

.eqIconText {
	@include small();
	color: #3b3b3b;
	text-align: center;
}

.icon {
	width: 20px;
	height: 20px;
}

.iconContainer {
	margin-left: 4px;
	margin-right: 4px;
}

.iconAvatarWrapper {
	position: relative;
}
