@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.sectionRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.textIconSection {
	display: flex;
	gap: 9px;
	width: 60%;
	@include small-regular();
}

.label {
	width: 100%;
}
