@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.menuContainer {
	position: absolute;
	left: 50px;
	bottom: 20px;
	background-color: $white;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(17, 24, 39, 0.06), 4px 0 6px 0 rgba(17, 24, 39, 0.1);
	width: 288px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	&.rtl {
		left: unset;
		right: 50px;
	}
	& > section {
		padding: 10px 5px;
	}
	.profileIcon {
		margin: 0 8px;
		border: 1px solid $darkBlue;
		border-radius: 50%;
	}

	.icon {
		margin: 0 8px;
	}

	.menuItem {
		@include small-regular();
		display: flex;
		align-items: center;
		justify-content: flex-start;
		border-top: 1px solid $grey100;
	}

	.userDetails {
		display: flex;
		align-items: center;

		.userTexts {
			@include small-regular($primaryColor);
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			.userRoleName {
				display: flex;

				.name {
					font-weight: 600;
					margin-inline-end: 8px;
					display: flex;
					align-items: center;
				}

				.admin {
					border-radius: 4px;
					background-color: $grey200;
					padding: 3px;
					margin-inline-start: 8px;
					font-size: 10px;
				}
			}

			.email {
				display: flex;
				color: $grey600;
			}
		}
	}
}

.languagesDropdown {
	position: absolute;
	left: unset;
	right: 0;
	bottom: 100%;
	width: 123px;

	&.rtl {
		right: unset;
		left: 0;
	}
}

.languagesMenuItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	position: relative;
}

.selectedLanguage {
	@include button-small;
	margin-inline-end: 6px;
}

.selectLanguageContainer {
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 4px;
	background-color: $grey50;
	padding: 8px;
}
