@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.container {
	display: flex;
	align-items: center;
	gap: 8px;
	height: 24px;
	width: 100%;

	&.narrowContainer {
		flex-direction: column-reverse;
		align-items: flex-start;
		height: unset;
	}
}

.professionContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;

	.tradeChip {
		border-radius: 4px;
		padding-inline-start: 2px;
		padding-inline-end: 6px;
		min-height: 20px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2px;
		@include small($white);
		height: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.chipText {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.contractorText {
	@include body2();
	color: $primaryColor;
	overflow: hidden;
	white-space: nowrap;
	max-width: 100%;

	&.overflowed {
		white-space: unset;
		@include cut-line(2);
		max-width: 100%;
	}
}

.noTradeIcon {
	padding-inline-start: 6px !important;
}
