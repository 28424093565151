@import '@shared/styles/mixins/_text.scss';

.dialogContent {
	@include small-regular();
	white-space: break-spaces;
}

.rootDialog {
	width: 337px;
}
