@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.professionColor {
	cursor: pointer;
	width: 20px;
	height: 8px;
	border-radius: 32px;
	position: absolute;
	bottom: 8px;
	left: 4px;
}

.tooltipContainer {
	@include body2($white);
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.bottomPart {
	display: flex;
	gap: 4px;
}

.bottomItem {
	font-size: 11px;
	display: flex;
	padding: 2px 8px;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
}
