@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.listContainer {
	width: 100%;
	min-height: 350px;
	padding: 0 0 8px;
	border-radius: 3px;
	background-color: $white;
}

.headerContainer {
	display: flex;
	padding-inline-start: 24px;
	position: sticky;
	top: 0;
	z-index: 1;
	height: 44px;
	min-width: fit-content;
	width: 100%;
	border-top: 1px solid $grey100;
	background-color: $white;
}

.header {
	display: flex;
	flex-wrap: nowrap;
}

.rowsContainer {
	display: flex;
	flex-direction: column;
	height: calc(100% - 42px);
	width: 100%;
	flex-wrap: nowrap;
}

.tableWrapper {
	height: calc(100% - 14px) !important;
	overflow: hidden;
}

.searchAndButtonContainer {
	height: 48px;
	width: 100%;
	margin-bottom: 8px !important;
}

.arrow {
	width: 14px;
	height: 14px;
}

.tableContainer {
	height: 100%;
	flex-wrap: nowrap;
}

.placeholderText {
	text-align: center;
	font-size: 20px;
	line-height: 29.38px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.5;
}

.titleContainer {
	margin-bottom: 8px;
}

.pageTitleBox {
	width: 100%;
	justify-content: center;
	padding: 20px !important;
}

.title {
	@include h6;
	width: 100%;

	@include for-narrow-layout {
		font-size: 16px !important;
	}
}

.nameCell {
	@include body2;
	display: flex;
	gap: 12px;
	align-items: center;
	padding-inline-end: 8px;

	.filledIcon {
		:first-child {
			fill: inherit;
		}
	}

	.hideIcon {
		display: none;
	}

	.description {
		@include cut-line(3);
	}
}

.professionCell {
	display: flex;
	justify-content: center;
	width: fit-content;
	max-width: 100%;

	.contractorText {
		@include button-small;
	}
}

.overallProgressCell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;

	.stats {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 16px;

		.percentage {
			@include button-small($darkBlue);
		}

		.fraction {
			@include small-regular($grey600);
		}
	}
}

.averageWeeklyProgressRateCellContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 8px;

	.averageWeeklyProgressRateCell {
		@include button-large;
	}
}

.averageWeeklyProgressRateEmptyState {
	@include button-small($grey500);
	align-self: center;
}

.trendCell {
	@include small($white);
	text-align: center;
	padding: 4px 2px;
	border-radius: 2px;
}

.positiveTrend {
	background-color: $feedbackPositiveLight;
	color: $feedbackPositiveDark;
}

.negativeTrend {
	background-color: $feedbackCriticalLight;
	color: $feedbackCriticalDark;
}

.neutralTrend {
	background-color: $grey600;
}

.etaCell {
	padding-inline-start: 34px;

	.weeksAndIndicator {
		display: flex;
		align-items: center;
		gap: 4px;

		.riskIndicator {
			width: 6px;
			height: 6px;
			border-radius: 50%;
		}
	}

	.weeks {
		@include button-small($darkBlue);
	}

	.completed {
		color: $feedbackPositiveDark;
	}

	.month {
		@include small-regular($grey500);
	}
}

.etaEmptyState {
	@include button-small($grey500);
}

.tbd {
	@include small($grey500);
}
