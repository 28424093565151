@import '@shared/styles/_variables';
@import '@shared/styles/mixins/_text';
@import '@shared/styles/mixins/_media_queries';

.title {
	@include body2($white);
	margin: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.contractor {
	@include body2($grey600);
	margin: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 300;
	font-size: 12px;
}

.mainText {
	@include body2;
	margin: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;

	@include for-narrow-layout {
		@include small-regular();
	}
}

.specialty {
	@include body2;
	margin: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@include for-narrow-layout {
		@include small-regular();
	}
}

.headerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	border: $regularBorder;
}

.titleContainer {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
}
