@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.notificationSnackbar {
	background-color: $darkBlue;
	width: 280px;
	border-radius: 4px;
	height: fit-content;
	display: flex;
	box-shadow: 0px 20px 25px 0px rgba(28, 28, 34, 0.15);

	&.isBanner {
		background: $blue200;
		width: 340px;
		border-radius: 16px;
	}
}

.notificationContainer {
	display: flex;
	gap: 10px;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	width: 100%;
}

.notificationContent {
	display: flex;
	align-items: center;
	gap: 10px;
}

.iconContainer {
	cursor: pointer;

	&.isBanner {
		align-self: start;
	}
}
