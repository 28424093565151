@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.dialog {
	border-radius: 16px !important;
	z-index: 1000;
	max-width: initial !important;
	width: 700px;
	min-height: 700px;

	@include for-narrow-height-layout {
		width: 600px;
		min-height: 600px;
	}

	@include for-small-height-layout {
		width: 500px;
		min-height: 500px;
	}
	padding: 16px;
	overflow: hidden;
	position: relative;
}

.closeIcon {
	--position: 12px;
	position: absolute;
	top: var(--position);
	right: var(--position);

	&.isRtl {
		right: initial;
		left: var(--position);
	}
}
