@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.circle {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 1px solid #313131;
	background-color: $white;

	min-height: 20px;
	min-width: 20px;

	&.chip {
		min-height: 14px;
		min-width: 14px;
	}
}
