@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.locationInfoSection {
	display: flex;
	flex-direction: column;
	@include small-regular();
}

.dataSection {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.header {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}
