@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

@keyframes donut-chart-fill {
	to {
		stroke-dasharray: 0 50 100;
	}
}

.svgItem {
	font-size: 12px;
	display: flex;
	justify-content: center;
}

.donutSegment {
	animation: donut-chart-fill 1s reverse ease-in;
	transform-origin: center;
}

.donutRing {
	stroke: #ebebeb;
}

.donutText {
	@include small-regular;
}

.donutPercent {
	font-size: 0.7em;
	line-height: 1;
	transform: translateY(0.5em);
}

.fragemnt {
	font-size: 1em;
	font-weight: 500;
}

.maxNumber {
	font-weight: lighter;
}
