@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.container {
	user-select: unset;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: $grey50;
	border-radius: 8px;
	gap: 16px;
	padding: 4px;

	.zoomContainer {
		gap: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.zoomNumber {
			user-select: unset;
			@include button-small;
		}
	}
}
