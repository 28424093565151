@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.statsSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	height: 100%;
}

.statTextHeader {
	@include small-regular($grey500);
	width: 100%;
	padding: 0;
}

.statText {
	@include subtitle-semi;
	text-align: center;
}

.divider {
	width: 1px;
	border: 1px solid $white;
	height: 100%;
}

.statsInnerSection {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}
