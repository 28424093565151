@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.executivePageContainer {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	&.dark {
		background-color: $blue900;
	}

	.topBar {
		height: 72px;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 9999;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.dark {
			background-color: $grey800;
		}

		.trusstorLogoContainer {
			display: flex;
			margin-inline-start: 24px;

			.trusstorLogo {
				width: 24px;
			}

			.trusstorLogoText {
				width: 102px;
			}
		}

		.companyLogoContainer {
			background-color: $grey700;
			height: $executivePageTopBarHeight;

			.companyLogo {
				height: $executivePageTopBarHeight;
			}
		}
	}

	.contentContainer {
		width: 100%;
		padding: 0 240px 36px 240px;
		margin-top: $executivePageTopBarHeight;

		@include for-narrow-layout {
			padding: 10px 40px;
		}

		.title {
			@include h4;
			margin-bottom: 24px;
			padding-top: 32px;

			&.dark {
				color: $grey100;
			}
		}

		.content {
			width: fit-content;
			.contentTitle {
				@include h5;
				border-bottom: 1px solid $grey500;
				padding-bottom: 8px;

				&.dark {
					color: $grey200;
				}
			}

			.cardsSection {
				display: flex;
				justify-content: center;
				gap: 16px;
				flex-wrap: wrap;
				margin-top: 32px;
			}
		}
	}
}
