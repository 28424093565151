@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.MilestoneInnerPageHeader_container {
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 16px 0;
}

.back {
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: pointer;
	@include button-small($grey600);
}

.middle {
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 700px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.texts {
	width: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
}

.orderIndex {
	user-select: none;
	@include small($grey400);
}

.end {
	position: relative;
	display: flex;
	align-items: center;
	gap: 16px;
}

.dropdownContainer {
	top: 30px;
	right: 0px;

	&.isRtl {
		right: unset;
		left: 0px;
	}

	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px;
	z-index: 100000;
}

.menuContainer {
	cursor: pointer;
	border-radius: 8px;

	&:hover {
		background: $grey50;
	}

	&.showDropdown {
		background: $grey50;
	}
}

.dialogContent {
	@include small-regular();
	white-space: break-spaces;
}

.rootDialog {
	width: 337px;
}

.titleDescriptionSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;

	.titleSection {
		user-select: none;
		@include subtitle-semi();
		cursor: text;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;

		.title {
			word-break: break-word;
			text-align: center;

			&:hover {
				text-decoration: underline;
			}

			&:focus {
				outline: none;
				text-decoration: underline;
			}
		}
	}
}
