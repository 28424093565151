@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.equipmentCardContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 8px;
	gap: 8px;

	width: 100%;
	height: 40px;
	margin-top: 8px;

	border: 1px solid $grey100;
	border-radius: 4px;

	.circle {
		width: 20px;
		height: 20px;
		background: $white;
		border-radius: 32px;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			width: 12px;
			height: 12px;
		}
	}

	.textContainer {
		display: flex;
		flex-direction: column;

		.label {
			@include small-regular(#646464);
		}

		.subtitle {
			@include small-regular;
		}
	}
}
