@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.downloadModeOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $white;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.8;
}

.headerContainer {
	background-color: $white;
	width: 100%;
	padding-top: 25px;
	padding-bottom: 10px;

	.headerContent {
		width: 100%;
		max-width: 1370px;
		margin: auto;
		padding: 0 10px;
		@include for-narrow-layout {
			max-width: 1224px;
		}
		display: flex;
		justify-content: space-between;

		.titleDescriptionSection {
			flex: 1;
			img {
				// arrow
				width: 24px;
				height: 24px;
				cursor: pointer;

				&.transform {
					transform: rotate(180deg);
				}
			}

			.titleSection {
				@include h5;
				display: flex;
				align-items: center;

				.title {
					&:focus {
						outline: none;
						text-decoration: underline;
					}
				}

				img {
					// edit icon
					margin: 0 5px;
					cursor: pointer;
				}
			}

			.descriptionSection {
				@include body2($grey500);
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				max-width: 90%;

				&:focus {
					outline: none;
					text-decoration: underline;
				}
			}
		}

		.actionTimeSection {
			display: flex;
			flex-direction: column;
			justify-content: flex-end; // on enable download change to space-between
			flex: 1;

			.downloadButton {
				align-self: flex-end;
				margin-bottom: 15px;
				position: relative;
			}

			.exportMenuWrapper {
				position: absolute;
			}

			.exportMenuWrapper {
				position: absolute;
			}

			.dateSection {
				@include small-regular();
				display: flex;
				align-items: center;
				justify-content: flex-end;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				img {
					// clock icon
					width: 16px;
					height: 16px;
					margin: 0 8px;
				}
			}
		}
	}
}
