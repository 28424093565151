@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
.TotalProgressGraph_container {
}

.title {
	@include button-small;
}

.tooltip {
	background: $darkBlue;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	padding: 8px 16px;
	border-radius: 4px;

	& .topText {
		@include button-small($blue300);
		direction: ltr;
	}

	& .bottomText {
		@include small-regular($grey200);
	}
}
