@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.locationPersonnelSection {
	@include small-regular();
	overflow-y: auto;
}

.tagDetails {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.personnelOnTheAreaHeader {
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
}
