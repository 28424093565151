@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.overallContainer {
	min-height: 55px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 30px;

	.overallText {
		@include small();

		&.dark {
			color: $grey200;
		}
	}
}
