@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.matrixContainer {
	background: $white;
	scroll-behavior: smooth;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	width: 100%;

	// disable text selection
	-moz-user-select: none; /* firefox */
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE*/
	user-select: none; /* Standard syntax */
}

.colHeader {
	border: 1px solid $grey200;
	margin-top: 3px;
	border-inline-start: none;
	position: sticky;
	top: 0;
	padding: 0;
	background-color: $white;

	z-index: 10000;

	&:first-child {
		border-inline-start: 1px solid $grey200;
	}
}

.matrixTableContainer {
	border-inline-end: 1px solid $grey200;
	width: fit-content;
}

.headersContainer {
	background: $grey50;
	display: flex;
	position: sticky;
	inset-inline-start: 0;
	top: -3px;
	margin-top: -3px;
	z-index: 40000;

	.floorsTitle {
		display: flex;
		justify-content: center;
		align-items: center;
		position: sticky;
		top: -3px;
		inset-inline-start: 0;
		z-index: 20000;
		img {
			margin: 0 4px;
		}
		.floorsText {
			@include body2();
			font-size: 14px;
			font-weight: 600;
		}
	}
}

.matrix {
	position: relative;
	border-spacing: 0;
	overflow: scroll;
	width: 100%;
	border-inline-end: 1px solid $grey200;
}

.loaderContainer {
	max-width: 100vw;
	&.rtl {
		transform: rotateY(180deg);
	}
}
