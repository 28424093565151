@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/_variables.scss';

.workerCard {
	display: flex;
	gap: 8px;
	padding: 16px 8px;
	padding-inline-start: 16px;
	padding-inline-end: 8px;

	border-top: 1px solid rgba(240, 240, 240, 1);
	border-bottom: 1px solid rgba(240, 240, 240, 1);
	cursor: pointer;

	&.selected {
		background: $blue100;
	}
}

.tagNick {
	@include small;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.tagName {
	@include small-regular;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
