@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.milestonesProgressSummaryContainer {
	display: flex;
	padding: 2px;
	border-radius: 12px;
	border: 2px solid $blue300;
	margin-top: 16px;

	.secondaryContainer {
		display: flex;
		align-items: center;
		border-radius: 8px;
		border: 2px solid $blue300;
		padding: 16px;
		gap: 32px;
		width: 100%;
		background-color: $blue50;

		.summaryContainer {
			display: flex;
			align-items: center;
			gap: 14px;

			.image {
				width: 32px;
			}

			.summaryText {
				@include subtitle-semi();
			}
		}

		.textsContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.summaryHeaderText {
				@include body2($grey600);

				.summaryHeaderTextBold {
					@include body2($grey600);
					font-weight: 600 !important;
				}
			}
		}

		.milestonesProgressSummaryText {
			@include body2($grey600);
		}

		.milestonesProgressSummaryText:before {
			content: '•';
			margin-inline-end: 8px;
			font-size: 14px;
		}
	}
}
