@import '@shared/styles/variables.scss';

.dateYellowBox {
	height: 62px;
	border-top: $regularBorder;
	border-bottom: $regularBorder;
}

.datePickerRoot {
	width: 90%;
}
