@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.SequenceSelectionItem_container {
	@include body2();
	display: flex;
	height: 40px;
	padding: 8px 8px;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
}

.hide {
	position: absolute;
	opacity: 0;
}

.input {
	min-width: 40px;
	max-width: 100px;
	all: unset;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: text;
}

.span {
	min-width: 40px;
	max-width: 150px;
	width: auto;
}

.newButton {
	cursor: pointer;
	@include body2();
	display: flex;
	align-items: center;
}
