@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.cellContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.topRowContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;

	@include for-small-layout {
		gap: 2px !important;
	}
}

.cellDayText {
	font-size: 12px !important;
	font-weight: 300 !important;
}

.cellDateNumber {
	@include body1;
}

.lockIcon {
	height: 12px;

	@include for-small-layout {
		height: 10px !important;
	}
}
