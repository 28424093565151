@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '@shared/styles/_variables.scss';

.timesheetTablesSection {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	overflow: auto;
	max-height: calc(100vh - 190px);
}
