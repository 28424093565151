@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/mixins/_text.scss';

.wholePageContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.bodyContent {
	overflow: auto;
	height: calc(100% - $contractorAnalysisHeader);
	padding: 0 176px;
	max-width: 2000px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: 1700px) {
		padding: 0 64px;
	}
}
