@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.reportTagsLocationInfo {
	background-color: $white;
	padding: 16px;
	width: 600px;
	border-radius: 8px;
}

.bottomBorder {
	border-bottom: 1px solid $grey100;
	padding-bottom: 18px;
}
