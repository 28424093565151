@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.progressCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $white;
	border-radius: 8px;
	border: 1px solid transparent;
	justify-content: space-between;
	min-width: 200px;

	@include for-small-layout {
		min-width: 150px;
	}

	&:hover {
		border: 1px solid $darkBlue;
		cursor: pointer;
		box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
	}

	.topSection {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 16px 0 16px;

		.newLabel {
			@include small($white);
			background-color: $blue300;
			border-radius: 4px;
			width: 49px;
			text-align: center;
			height: 16px;
			margin-top: 16px;

			@include for-small-layout {
				margin-top: 8px;
			}
		}

		.image {
			width: 51.8px;
			height: 36.4px;
			flex-shrink: 0;
			margin-top: 60px;

			@include for-small-layout {
				margin-top: 25px;
			}
		}

		.reportTitle {
			@include button-small($darkBlue);
			text-align: center;
			margin-top: 33px;

			@include for-small-layout {
				margin-top: 25px;
			}
		}

		.reportDescription {
			@include small-regular($darkBlue);
			text-align: center;
			margin-top: 24px;
		}
	}

	.triangleSection {
		width: 100%;
		height: 100px;
		position: relative;
		overflow: hidden;
		background-color: $darkBlue;
		clip-path: polygon(0 49%, 100% 0, 100% 100%, 0% 100%);
		border-radius: 0 0 8px 8px;

		display: flex;
		flex-direction: column-reverse;
		align-items: end;
	}

	.rtlTriangle {
		clip-path: polygon(0 0, 100% 49%, 100% 100%, 0% 100%);
	}

	.arrowIcon {
		margin-bottom: 8px;
		margin-inline-end: 8px;
	}
}
