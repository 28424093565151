@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.graphsContainerWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: 1300px) {
		flex-direction: column;
	}
}

.graphsContainer {
	position: relative;
	top: 100px;
	flex-wrap: wrap;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, minmax(600px, 1fr));
	@media (max-width: 1400px) {
		grid-template-columns: 1fr;
	}
	justify-items: center;
	align-items: center;
	gap: 24px;
}

.ContractorAnalysisGraphCard_container {
	padding: 16px 24px;
	border-radius: 16px;
	width: 100%;
	height: 380px;
	border: 1px solid $grey100;

	@media (max-width: 1400px) {
		min-width: 578px;
		max-width: 700px;
	}
}
