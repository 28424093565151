@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.popperStyle {
	&:global(.MuiAutocomplete-popper) {
		width: 300px !important;
	}
}

.groupContainer {
	margin-inline-start: 8px;
}

.optionStyle {
	&:global(.MuiAutocomplete-option) {
		padding-top: 0;
		padding-bottom: 0;
		padding-inline-start: 0;
	}
}

.loading {
	&:global(.MuiAutocomplete-loading) {
		@include small-regular(#7b7b7b);
	}
}

.optionContainer {
	padding-inline-start: 8px;
	display: flex;
	align-items: center;
}

.groupUl {
	width: 96%;
	border-bottom: solid 1px #dfdada;
	margin: 10px auto 10px;
	text-align: center;
	line-height: 0.1 !important;

	span {
		@include small;
	}
}

.groupLabel {
	@include small;
	line-height: 0.1 !important;
	border-radius: 3px;
	background: $white;
	padding: 0 10px;
	text-align: center;
	margin-top: 8px;
	margin-bottom: 8px;
}

.root {
	input {
		@include small;
	}

	& .text {
		@include body2($black);
		background-color: $white !important;
		border-bottom: 1px solid $black;
	}
}

.autocompleteContainer {
	width: 100%;
	@include small-regular;
}

.errorWrapper {
	height: 15px;
	margin-bottom: 4px;
	margin-top: 4px;
}

.tagsContainer {
	min-width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	align-self: flex-end;
}

.selectAllOption {
	gap: 4px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	padding-inline-start: 8px;
	align-items: center;
	width: 100%;
	@include small-regular();
}

.selectAllOptionText {
	display: flex;
	align-items: center;
}

.secondGroupClass {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 100%;
	@include small-regular();
}

.secondGroupClassText {
	font-weight: 500;
	display: flex;
}
