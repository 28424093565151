@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.switcherContainer {
	@include small-regular($white);
	background-color: $grey100;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2px;
	border-radius: 20px;
	width: 192px;
	cursor: pointer;
	text-transform: capitalize;

	&.responsive {
		@include for-narrow-layout {
			font-size: 11px;
			width: 162px;
		}
	}

	.active {
		border-radius: 15px;
		background-color: $white;
		color: $primaryColor;
		padding: 8px 8px 6px 8px;
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 500;
	}

	.inActive {
		display: flex;
		justify-content: center;
		align-items: center;
		color: $black;
		width: 50%;
		border-radius: 15px;
		padding: 8px 8px 6px 8px;
	}
}
