@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.issuesContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	min-height: 48px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
	background-color: white;
	z-index: 1;
	border-bottom: 1px solid $grey100;

	.actionButtonsSection {
		width: 33%;
		display: flex;
		justify-content: flex-end;
		gap: 8px;

		.button {
			color: $grey600;
		}
	}

	.headerText {
		width: 33%;
		@include subtitle-semi();
	}
}

.issuesBody {
	display: flex;
	height: calc(100% - 60px);

	.filterSection {
		border-inline-end: 1px solid $grey100;
	}

	.issuesBoard {
		display: flex;
		background-color: $blue100;
		width: calc(100% - $sideMainComponentWidth);
		overflow-x: auto;
	}
}
