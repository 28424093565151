@import '@shared/styles/mixins/text';
@import '@shared/styles/_variables.scss';

.tableText {
	@include small-regular($white);
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tooltipDisplayComponent {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	max-width: 200px;
}
