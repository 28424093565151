@import '@shared/styles/styles';

.mainPageIssuesList {
	background-color: $grey50;
	padding: 0 16px 16px 16px;
}

.groupList {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-bottom: 1px;
}
