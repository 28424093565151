@import '../../../styles/_variables.scss';
@import '../../../styles/mixins/text';

.title {
	@include body2;
}

.description {
	@include small-regular($grey600);
	width: 100%;
	background-color: $grey50;
	padding: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.titleDarkTheme {
	color: $white;
}

.descriptionSnackbar {
	background-color: inherit;
	padding: 0px;
	color: $grey200;
	white-space: normal;
}

.icon {
	@include body2($grey50);
}
