@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.item {
	height: 20px;
	display: flex;
	align-items: center;
	gap: 8px;

	.itemText {
		@include body2();
	}

	img {
		height: 16px;
		width: 16px;
	}
}

.AsiReviewDialog_container {
	flex-direction: column;
	display: flex;
	gap: 12px;
}

.buttons {
	display: flex;
	justify-content: space-between;
	gap: 12px;
	margin-top: 16px;
}

.issueOpenText {
	padding: 16px 0;
	@include small();
}

.issueCard {
	border: 1px solid $grey200;
	border-radius: 4px;
}
