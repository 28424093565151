.photoContainer {
	display: flex;
	justify-content: center;

	.photo {
		width: 100%;
		border-radius: 16px;
		cursor: pointer;
	}
}
