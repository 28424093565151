@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/_variables.scss';

.subtext {
	@include small-regular($primaryColor);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.cellContainer {
	display: flex;
	overflow-y: auto;
	border-radius: 1px;
	padding-inline-start: 16px;
	padding-inline-end: 4px;
	background-color: $white;
	overflow-x: hidden;
}

.secondRowContainer {
	display: flex;
	gap: 4px;
	align-items: center;
	flex-wrap: wrap;
}

.textContainer {
	width: 98%;
	padding: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.professionDisplayContainer {
	display: flex;
	align-items: center;
	justify-content: start;
	overflow-x: hidden;
	width: 100%;
}
.headerText {
	@include small();
}

.bigHeaderText {
	@include subtitle-semi();
	display: flex;
	justify-content: center;
}

.bottomContainer {
	height: 33%;
}

.color {
	height: 100%;
	width: 2px;
}

.activitiesItem {
	width: 86%;

	&:first-child {
		margin: 8px;
	}
}

.stripes {
	background-image: url('../../../assets/workplanIcons/nullBackground.svg');
}

.blueStripes {
	background-image: url('../../../assets/workplanIcons/blueNullBackground.svg');
}

.blue {
	background-color: $blue100;
}
